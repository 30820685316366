/*eslint camelcase: ["error", {allow: ["created_at",]}]*/

import React, { useEffect, useState, useRef } from 'react';
import { useAxios } from '../../hooks/useAxios';
import { navigateToError } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { LoadingView } from '../../components/LoadingView';
import { Timeline, Text } from '@mantine/core';
import PropTypes from 'prop-types';
import { formatTimestamp } from '../../helpers/utils';

const OrderStatusHistory = ({ id, createdAt }) => {
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const navigate = useNavigate();
  const [statusHistory, setStatusHistory] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const observer = useRef(null);
  const scrollContainerRef = useRef(null);
  const lastItemRef = useRef(null);

  const loadOrderData = async (pageUrl = `order/${id}/status-history/`) => {
    try {
      const { data } = await axios.get(pageUrl);
      setStatusHistory((prev) => [...prev, ...data.results]);
      setNextPage(data.links.next);
    } catch (e) {
      navigateToError(e, navigate);
    }
  };

  const initialLoad = async () => {
    setIsLoading(true);
    await loadOrderData();
    setIsLoading(false);
  };

  useEffect(() => {
    initialLoad().catch(console.error);
    return abortRequest;
  }, []);

  useEffect(() => {
    if (!nextPage) return;

    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        loadOrderData(nextPage);
      }
    };

    observer.current = new IntersectionObserver(handleObserver, {
      root: scrollContainerRef.current,
      rootMargin: '0px',
      threshold: 1.0
    });

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current && lastItemRef.current) {
        observer.current.unobserve(lastItemRef.current);
      }
    };
  }, [nextPage]);

  const timelineItems = statusHistory.map((item, index) => (
    <Timeline.Item key={index} bullet={''} title={item?.status} lineVariant={'dashed'}>
      <Text color="dimmed" size="sm">
        {item?.description}
      </Text>
      <Text size="xs" mt={4}>
        {formatTimestamp(item?.created_at)}
      </Text>
    </Timeline.Item>
  ));

  const newOrderItem = (
    <Timeline.Item bullet={''} title="New Order" lineVariant="solid" color="blue">
      <Text size="xs" mt={4}>
        {formatTimestamp(createdAt)}
      </Text>
    </Timeline.Item>
  );

  return (
    <>
      {isLoading ? (
        <div className="py-3 animate__animated animate__fadeIn">
          <LoadingView width={1} />
        </div>
      ) : (
        <div ref={scrollContainerRef} className="status-history-scroll-container">
          <Timeline active={1} bulletSize={24} lineWidth={2}>
            {timelineItems}
            {newOrderItem}
            {nextPage && <div ref={lastItemRef}></div>}
          </Timeline>
        </div>
      )}
    </>
  );
};

OrderStatusHistory.propTypes = {
  id: PropTypes.number,
  createdAt: PropTypes.string
};

export default OrderStatusHistory;
