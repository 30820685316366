import { useState } from 'react';
import { createAxios } from '../services/axios';

export const useAxios = () => {
  const controller = new AbortController();
  const [isLoading, setIsLoading] = useState(true);

  return {
    controller,
    isLoading,
    setIsLoading,
    axios: createAxios(controller.signal),
    signal: controller.signal,
    abortRequest: () => {
      setIsLoading(false);
      controller.abort();
    }
  };
};
