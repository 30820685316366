import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import { capitalizeFirstLetter } from '../../utils';

export const MaskedTextField = ({
  label,
  type,
  labelComponent,
  required = false,
  helpText = null,
  maskInput,
  autoComplete,
  maxLength,
  ...props
}) => {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  const capitalizedLabel = capitalizeFirstLetter(label);
  const fieldId = `id_${field.name}`;
  return (
    <div className="mb-2">
      <div className="d-flex align-items-center">
        {label && (
          <label htmlFor={field.name} className="text-capitalize">
            {capitalizedLabel} {required && <RequiredMarker />}
          </label>
        )}
        {labelComponent ? labelComponent : null}
      </div>

      <input
        id={fieldId}
        maxLength={maxLength}
        className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
        required={required}
        placeholder={label + '...'}
        type={type}
        value={value || ''}
        autoComplete={autoComplete ? autoComplete : 'new-password'}
        {...field}
        {...props}
        onChange={(e) => (maskInput ? setValue(maskInput(e.target.value)) : setValue(e.target.value))}
      />

      <div className="d-flex justify-content-between">
        <FormikError name={field.name} meta={meta} />
        {helpText ? <small className="text-info">{helpText}</small> : null}
      </div>
    </div>
  );
};

MaskedTextField.propTypes = {
  maskInput: PropTypes.func,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  helpText: PropTypes.any,
  type: PropTypes.string,
  labelComponent: PropTypes.any,
  required: PropTypes.bool,
  autoComplete: PropTypes.string
};

export default MaskedTextField;
