import React from 'react';
import { Col, Row } from 'react-bootstrap';
import meta from '../../last-commit.json';

const Footer = () => (
  <footer className="footer">
    <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">Thank you for using Bottle Nexus {new Date().getFullYear()} &copy; </p>
      </Col>
      <Col sm="auto">
        <p style={{ display: 'none' }} className="mb-0 text-600">
          v{meta.lastCommit}
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
