import {
  USER_PERMISSION_ACCOUNT,
  USER_PERMISSION_BRAND,
  USER_PERMISSION_CUSTOMER,
  USER_PERMISSION_DASHBOARD,
  USER_PERMISSION_DISCOUNT,
  USER_PERMISSION_EXPORT,
  USER_PERMISSION_GIFT_CARD,
  USER_PERMISSION_IMPORT,
  USER_PERMISSION_ORDER,
  USER_PERMISSION_PRODUCT,
  USER_PERMISSION_REPORT,
  USER_PERMISSION_SETTING,
  USER_PERMISSION_SUBSCRIPTION,
  USER_TYPE_AFFILIATE
} from '../services/constants';
import { USER_TYPE_ADMIN, USER_TYPE_BRAND } from '../services/utils';

export default [
  {
    name: 'Dashboard',
    permission: USER_PERMISSION_DASHBOARD,
    icon: 'chart-pie',
    to: '/',
    exact: true,
    active: true,
    rootRouter: true // set this to true then we avoid on startsWith check on routes
  },
  {
    name: 'Brands',
    permission: USER_PERMISSION_BRAND,
    active: true,
    icon: 'globe',
    to: '/brand',
    exact: true
  },
  {
    label: 'Products',
    permission: USER_PERMISSION_PRODUCT,
    name: 'Products',
    active: true,
    icon: 'tags',
    to: '/product',
    exact: true
  },
  {
    label: 'Customers',
    permission: USER_PERMISSION_CUSTOMER,
    name: 'Customers',
    active: true,
    icon: 'users',
    to: '/customer',
    exact: true
  },
  {
    label: 'Orders',
    permission: USER_PERMISSION_ORDER,
    name: 'Orders',
    active: true,
    icon: 'list',
    to: '/order',
    exact: true,
    children: [
      {
        permission: USER_PERMISSION_ORDER,
        name: 'Abandoned Checkouts',
        active: true,
        to: '/abandoned-checkout',
        exact: true
      }
    ]
  },
  {
    permission: USER_PERMISSION_SUBSCRIPTION,
    label: 'Subscriptions',
    icon: 'undo-alt',
    name: 'Subscriptions',
    active: true,
    to: '/subscription',
    exact: true
  },
  {
    label: 'Discounts',
    name: 'Discounts',
    permission: USER_PERMISSION_DISCOUNT,
    icon: 'percentage',
    active: true,
    to: '/discount',
    exact: true
  },
  {
    label: 'Gift Cards',
    name: 'Gift Cards',
    permission: USER_PERMISSION_GIFT_CARD,
    icon: 'gift',
    active: true,
    to: '/gift-card',
    exact: true
  },
  {
    name: 'Reports',
    permission: USER_PERMISSION_REPORT,
    icon: 'chart-line',
    active: true,
    to: '/reports',
    exact: true
  },
  {
    label: 'Buy Button',
    permission: (profile) => {
      return (
        profile?.permissions?.includes(USER_PERMISSION_PRODUCT) &&
        [USER_TYPE_BRAND, USER_TYPE_ADMIN, USER_TYPE_AFFILIATE].includes(profile.type)
      );
    },
    name: 'Buy Button',
    active: true,
    icon: 'shopping-cart',
    to: '/buy-button',
    exact: true
  },
  {
    label: 'Settings',
    permission: (profile) => {
      return profile.permissions.find((perm) =>
        [USER_PERMISSION_SETTING, USER_PERMISSION_ACCOUNT, USER_PERMISSION_EXPORT, USER_PERMISSION_IMPORT].includes(
          perm
        )
      );
    },
    name: 'Settings',
    active: true,
    icon: 'cog',
    children: [
      {
        name: 'Users',
        permission: USER_PERMISSION_ACCOUNT,
        active: true,
        to: 'settings/user',
        exact: true
      },
      {
        name: 'Exports',
        permission: USER_PERMISSION_EXPORT,
        active: true,
        to: '/export',
        exact: true
      },
      {
        name: 'Imports',
        permission: USER_PERMISSION_IMPORT,
        active: true,
        to: '/import',
        exact: true
      },
      {
        name: 'Portfolios',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: 'settings/portfolio',
        exact: true
      },
      {
        name: 'Retailers',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: '/settings/retailer',
        exact: true
      },

      {
        name: 'Shipping Types',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: '/settings/shipping-type',
        exact: true
      },
      {
        name: 'Webhooks',
        permission: USER_PERMISSION_BRAND,
        active: true,
        to: '/settings/webhook',
        exact: true
      },
      {
        name: 'Category',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: '/settings/category',
        exact: true
      },
      {
        name: 'Subcategory',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: '/settings/subcategory',
        exact: true
      },
      {
        name: 'Stage',
        permission: USER_PERMISSION_SETTING,
        active: true,
        to: '/settings/stage',
        exact: true
      },
      {
        name: 'Email Alert',
        permission: (profile) => {
          if (profile?.type !== USER_TYPE_ADMIN) {
            return false;
          }
          return profile?.permissions?.includes(USER_PERMISSION_SETTING);
        },
        active: true,
        to: '/settings/email-setting',
        exact: true
      }
    ]
  }
];
