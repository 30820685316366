/* eslint-disable react/prop-types */
import React from 'react';
import { Pagination } from '@mantine/core';

export const AdvanceTablePagination = ({ pageCount, onPaginate, currentPage }) => {
  return (
    <div className="d-flex justify-content-center">
      <Pagination total={pageCount} siblings={4} page={currentPage} onChange={(page) => onPaginate(page)} withEdges />
    </div>
  );
};

export default AdvanceTablePagination;
