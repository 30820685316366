import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { Form as FormBootstrap } from 'react-bootstrap';
import { FormikError } from './FormikError';
import classNames from 'classnames';
import { RequiredMarker } from './RequiredMarker';

export const CheckboxField = ({ label, children, disabled, className, ...props }) => {
  const [field] = useField(props);
  const elementId = `id_${field?.name}_${props?.value}`;
  return (
    <FormBootstrap.Check
      id={elementId}
      className={`${className ? className : ''} mb-0 cursor-pointer d-flex align-items-center`}
    >
      <FormBootstrap.Check.Input
        disabled={disabled}
        type="checkbox"
        checked={!!field?.value}
        className="cursor-pointer mb-0 pb-0 mt-0 mb-0"
        htmlFor={elementId}
        {...field}
      />
      <FormBootstrap.Check.Label className="mb-0 text-700 text-capitalize cursor-pointer ms-1 w-100 text-nowrap">
        {label || children}
      </FormBootstrap.Check.Label>
    </FormBootstrap.Check>
  );
};

CheckboxField.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.any
};

export const RadioBoxField = ({ label, value, children, className, disabled, hideError, ...props }) => {
  const [{ name, ...field }, meta] = useField(props);
  const elementId = `id_${name}_${value}`;
  return (
    <div className="p-3">
      <FormBootstrap.Check
        id={elementId}
        className={`${className ? className : ''} mb-0 cursor-pointer d-flex align-items-center`}
      >
        <FormBootstrap.Check.Input
          name={name}
          disabled={disabled}
          id={elementId}
          type="radio"
          value={value} // could be something else for output?
          checked={field?.value === value}
          onChange={field?.onChange}
          onBlur={field?.onBlur}
          className={'radio-button mb-0 mt-0'}
          {...props}
        />

        <FormBootstrap.Check.Label className="mb-0 text-700 text-capitalize cursor-pointer ms-1 w-100 text-nowrap">
          {label || children}
        </FormBootstrap.Check.Label>
      </FormBootstrap.Check>
      {!hideError && <FormikError name={name} meta={meta} />}
    </div>
  );
};

RadioBoxField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  className: PropTypes.any,
  hideError: PropTypes.bool
};

export const SwitchInput = ({
  elementId,
  checked,
  onBlur,
  onChange,
  name,
  label,
  className,
  prefix = null,
  disabled = false,
  required = false
}) => {
  const handleChange = (event) => {
    const updatedValue = event.target.checked;
    onChange({
      target: {
        name,
        value: updatedValue
      }
    });
  };
  return (
    <div className={classNames(`${className ? className : ''}`)}>
      {prefix && (
        <label className={`form-check-label cursor-pointer me-1 mb-0`} htmlFor={elementId}>
          {prefix}
        </label>
      )}
      <div className="form-check form-switch cursor-pointer mb-0 d-flex">
        <input
          value={''}
          className="form-check-input cursor-pointer mb-0 me-1"
          type="checkbox"
          role="switch"
          checked={checked}
          id={elementId}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleChange}
          name={name}
          required={required}
        />
        {label && (
          <>
            {required && <RequiredMarker />}
            <label className={`form-check-label cursor-pointer mb-0 ${required ? 'ms-1' : ''}`} htmlFor={elementId}>
              {label}
            </label>
          </>
        )}
      </div>
    </div>
  );
};

SwitchInput.propTypes = {
  elementId: PropTypes.any,
  checked: PropTypes.any,
  className: PropTypes.any,
  onBlur: PropTypes.any,
  onChange: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.any,
  prefix: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export const SwitchField = ({
  label,
  disabled,
  required = false,
  onFiledChange,
  className,
  prefix = null,
  ...props
}) => {
  const [{ value, onBlur, onChange, name }] = useField(props);
  const elementId = `id_${name}_${props?.value}`;
  return (
    <SwitchInput
      className={className}
      prefix={prefix}
      elementId={elementId}
      checked={value !== undefined ? value : false}
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);
        onFiledChange ? onFiledChange(e) : null;
      }}
      name={name}
      label={label}
      disabled={disabled}
      required={required}
    />
  );
};

SwitchField.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.any,
  prefix: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onFiledChange: PropTypes.any
};

export default CheckboxField;
