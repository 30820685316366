import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { sentryCapture } from '../../helpers/utils';

const TableBulkActions = (props) => {
  const { actions, selectedRowIds: selectedRowIndexes, page, toggleAllRowsSelected } = props;
  if (actions?.length === 0) {
    return null;
  }
  const filterOnlySelected = (row, rowIndex) => {
    const listOfIndexes = Object.keys(selectedRowIndexes).map((rowIndex) => parseInt(rowIndex));
    return listOfIndexes.includes(rowIndex);
  };
  const selectedIds = page.filter(filterOnlySelected).map((row) => row?.original?.id);
  return Object.keys(selectedRowIndexes).length > 0 ? (
    <>
      <Dropdown autoClose="outside">
        <Dropdown.Toggle variant="outline-secondary" className="btn-sm text-nowrap">
          Bulk actions
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-2">
          {actions?.map((action, index) => (
            <Dropdown.Item
              key={index}
              onClick={async () => {
                try {
                  const { fn, forceResetSelection } = action;

                  await fn(selectedIds, action.name);

                  if (forceResetSelection) toggleAllRowsSelected(false);
                } catch (e) {
                  sentryCapture(e);
                }
              }}
            >
              {action.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  ) : (
    <></>
  );
};

TableBulkActions.propTypes = {
  actions: PropTypes.array,
  selectedRowIds: PropTypes.any,
  page: PropTypes.array,
  toggleAllRowsSelected: PropTypes.func
};

export default TableBulkActions;
