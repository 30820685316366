import SoftBadge from './common/SoftBadge';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const OrderStatusIndicator = ({ status, substatus = null }) => {
  return (
    <SoftBadge
      pill
      bg={classNames({
        success:
          status === 'Confirmed' ||
          status === 'Printed' ||
          status === 'Packed' ||
          status === 'Pre Transit' ||
          status === 'In Transit' ||
          status === 'Completed',
        primary: status === 'New Order' || status === 'Processing',
        warning: status === 'Action Required',
        danger: status === 'Problems'
      })}
    >
      {substatus ? `${status} - ${substatus}` : status}
    </SoftBadge>
  );
};
OrderStatusIndicator.propTypes = {
  status: PropTypes.string,
  substatus: PropTypes.string
};
