/*eslint camelcase: ["error", {allow: ["user_id", "order_id", "num_pages", "is_public"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import avatar from '../../../assets/img/team/avatar.png';
import { formatDatetime, USER_TYPE_ADMIN } from '../../../services/utils';
import { CanceledError } from 'axios';
import { useAxios } from '../../../hooks/useAxios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { chunkString, preventDefault } from '../../../utils';
import AppContext from '../../../context/Context';
import { ALLOWED_TAGS, USER_PERMISSION_ACCOUNT, USER_PERMISSION_BRAND_EDIT } from '../../../services/constants';
import * as DOMPurify from 'dompurify';

import { Button, Dropdown, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '../../../components/common/Avatar';
import { LoadingView } from '../../../components/LoadingView';
import ToggleCard from '../../../components/form/ToggleCard';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FalconCloseButton from '../../../components/common/FalconCloseButton';
import TextField from '../../../components/form/TextField';
import { QuillEditorField } from '../../../components/form/QuillEditorField';

export const SecretSection = ({ brand }) => {
  const { profile } = useContext(AppContext);
  const [selectedSecret, setSelectedSecret] = useState(null);
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const isReadOnly = brand?.id && !profile?.permissions?.includes(USER_PERMISSION_BRAND_EDIT);

  const [response, setResponse] = useState([]);
  const [revealSecret, setRevealSecret] = useState(null);
  const [opened, setOpened] = useState(false);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/brand/${brand.id}/secrets/?page_size=1000`);
      setResponse(data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (opened) {
      loadData().catch(console.error);
    }
    return abortRequest;
  }, [brand, opened]);

  const closeModal = () => {
    setSelectedSecret(null);
  };

  const closeRevealModal = () => {
    setRevealSecret(null);
    setSelectedSecret(null);
  };

  if (!brand || profile?.type !== USER_TYPE_ADMIN) return null;

  return (
    <>
      {selectedSecret || revealSecret ? (
        <div style={{ opacity: 0.5 }} className="modal-backdrop w-100 h-100"></div>
      ) : null}
      <div className="card mb-3">
        <ToggleCard title="Secrets" open={false} onOpen={() => setOpened(true)} onClose={() => setOpened(false)}>
          <div>
            <Modal show={revealSecret || false} onHide={closeRevealModal}>
              <Modal.Header>
                <Modal.Title>Reveal Secret</Modal.Title>
                <FalconCloseButton onClick={closeRevealModal} />
              </Modal.Header>

              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={{ note: '', password: '' }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  try {
                    const { data } = await axios.post(`brand/${brand.id}/secrets/${revealSecret.id}/description/`, {
                      ...values,
                      action: selectedSecret ? 'edit' : 'show'
                    });
                    setResponse((currentResponse) => {
                      return {
                        ...currentResponse,
                        results: response?.results?.map((item) => {
                          if (item.id === revealSecret.id) {
                            return {
                              ...item,
                              description: data?.description
                            };
                          }
                          return item;
                        })
                      };
                    });
                    if (selectedSecret && revealSecret.id === selectedSecret.id) {
                      setSelectedSecret({ ...selectedSecret, description: data?.description });
                    }
                    setRevealSecret(null);

                    setSubmitting(false);
                  } catch ({ response }) {
                    setSubmitting(false);
                    if (response?.data?.message) {
                      toast.error(response?.data?.message);
                    }
                    setErrors(response?.data);
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                      <TextField
                        required={true}
                        name="note"
                        type="textarea"
                        label="Reason"
                        data-lpignore="true"
                        autoComplete="new-password"
                        disabled={isSubmitting}
                      />
                      <TextField
                        required={true}
                        name="password"
                        type="password"
                        label="Password"
                        data-lpignore="true"
                        autoComplete="new-password"
                        disabled={isSubmitting}
                      />

                      <div>
                        <small className="text-warning">
                          To see the description you have to explain why you need this information and provide the
                          secret password.
                        </small>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <Button variant="default" onClick={closeRevealModal} disabled={isSubmitting}>
                        Close
                      </Button>
                      <Button
                        className="ms-3"
                        type="button"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={() => handleSubmit()}
                      >
                        Show
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>

            <Modal show={selectedSecret && !revealSecret} onHide={closeModal}>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={Yup.object({
                  title: Yup.string().required().label('Title'),
                  description: Yup.string()
                    .nullable()
                    .label('Description')
                    .test('is-non-empty', 'Description must contain at least 1 character', (value) => {
                      const sanitizedDescription = DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }).trim();
                      return sanitizedDescription !== '';
                    })
                })}
                initialValues={selectedSecret?.id ? selectedSecret : { description: '', title: '' }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  try {
                    const payload = { ...values, brand: brand.id };
                    await axios.post(
                      selectedSecret?.id
                        ? `/brand/${brand.id}/secrets/${selectedSecret.id}/`
                        : `/brand/${brand.id}/secrets/`,
                      payload
                    );
                    toast.success(selectedSecret?.id ? 'Secret updated successfully' : 'Secret created successfully');
                    await loadData();
                    setSelectedSecret(null);
                    setSubmitting(false);
                  } catch ({ response }) {
                    setSubmitting(false);
                    if (response?.data?.message) {
                      toast.error(response?.data?.message);
                    }
                    setErrors(response?.data);
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Header>
                      <Modal.Title>{selectedSecret?.id ? 'Change Secret' : 'Add Secret'}</Modal.Title>
                      <FalconCloseButton onClick={closeModal} />
                    </Modal.Header>

                    <Modal.Body>
                      <TextField
                        label="Title"
                        name="title"
                        disabled={isSubmitting || isReadOnly}
                        required={true}
                        data-lpignore="true"
                        autoComplete="new-password"
                      />
                      <QuillEditorField name="description" label="Description" disabled={isSubmitting || isReadOnly} />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <Button variant="default" onClick={closeModal} disabled={isSubmitting}>
                        Close
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        className="ms-3"
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>
            {isLoading ? (
              <div className="card-body">
                <LoadingView />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '250px' }}>User</th>
                        <th style={{ minWidth: '250px' }}>Title</th>
                        <th style={{ minWidth: '250px' }}>Description</th>
                        <th style={{ minWidth: '200px' }}>Created At</th>
                        <th style={{ minWidth: '100px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {response?.results?.length === 0 ? (
                        <tr className="text-muted text-center mb-3">
                          <td className="text-center" colSpan={5}>
                            No secrets found
                          </td>
                        </tr>
                      ) : null}
                      {response?.results?.map((secret, index) => {
                        return (
                          <tr key={`contact-${index}`}>
                            <td className="text-nowrap">
                              <div className="ms-3 d-flex align-items-center justify-content-between">
                                <div style={{ minHeight: 25 }} className="d-flex align-items-center">
                                  {profile?.permissions.includes(USER_PERMISSION_ACCOUNT) && secret.user ? (
                                    <Link
                                      className="mb-0 fw-normal d-flex align-items-center"
                                      target="_blank"
                                      to={`/settings/user/${secret?.user?.id}`}
                                    >
                                      <Avatar
                                        size={'2xl'}
                                        style={{ height: 28, width: 28 }}
                                        className="me-2"
                                        src={secret.user?.photo ?? avatar}
                                      />
                                      {secret.user?.fullname}
                                    </Link>
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        size={'2xl'}
                                        style={{ height: 28, width: 28 }}
                                        className="me-2"
                                        src={avatar}
                                      />
                                      <span className="fs--1 mb-0 fw-normal">{secret?.user?.fullname}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>{secret.title}</td>
                            <td>
                              {secret.description ? (
                                <div
                                  className="flex-fill"
                                  dangerouslySetInnerHTML={{
                                    __html: chunkString(
                                      DOMPurify.sanitize(secret.description, {
                                        ALLOWED_TAGS,
                                        ADD_CLASSES: [
                                          'ql-indent-1',
                                          'ql-indent-2',
                                          'ql-indent-3',
                                          'ql-indent-4',
                                          'ql-indent-5',
                                          'ql-indent-6',
                                          'ql-indent-7',
                                          'ql-indent-8'
                                        ],
                                        ADD_ATTR: ['class']
                                      })
                                    )
                                  }}
                                ></div>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    preventDefault(e);
                                    setRevealSecret(secret);
                                  }}
                                >
                                  Show Description
                                </button>
                              )}
                            </td>
                            <td>{formatDatetime(secret.created_at)}</td>

                            {/*<td className="text-nowrap">{contact?.email ? contact.email : '-'}</td>*/}
                            {/*<td className="text-nowrap">{contact?.phone_number ? contact.phone_number : '-'}</td>*/}
                            {/*<td>{contact?.position_title ? contact.position_title : '-'}</td>*/}
                            <td>
                              <div className="d-flex flex-justify-end">
                                <Dropdown className="ms-auto">
                                  <Dropdown.Toggle
                                    bsPrefix="toggle"
                                    as={Link}
                                    to="#"
                                    className="nav-link d-flex align-items-center text-muted"
                                  >
                                    <FontAwesomeIcon icon="ellipsis-v" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
                                    <div className="bg-white rounded-2 dark__bg-1000">
                                      <Dropdown.Item
                                        as={'button'}
                                        type={'button'}
                                        className="d-flex align-items-center py-2"
                                        onClick={() => {
                                          if (!secret?.description) {
                                            setRevealSecret(secret);
                                          }
                                          setSelectedSecret(secret);
                                          // setAction('edit');
                                        }}
                                      >
                                        <FontAwesomeIcon icon="pencil-alt" className="me-2" />
                                        <span style={{ lineHeight: 1 }}>Edit</span>
                                      </Dropdown.Item>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <div className="card-body">
                  {!isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      disabled={isReadOnly}
                      onClick={() => {
                        setSelectedSecret({});
                      }}
                    >
                      Add Secret
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </ToggleCard>
      </div>
    </>
  );
};

SecretSection.propTypes = {
  endpoint: PropTypes.string,
  disabled: PropTypes.bool,
  lastUpdate: PropTypes.any,
  brand: PropTypes.any
};
