import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';

export const AddAnotherForm = ({ onSubmit: handleSubmit, type, showForm, setShowForm }) => {
  const [formData, setFormData] = useState({});
  const inputRef = useRef(null);

  useEffect(() => {
    if (showForm) {
      inputRef.current.focus();
    }
  }, [showForm]);

  return (
    <>
      {showForm && (
        <div
          className={classNames('rounded-3 transition-none', {
            'bg-100 p-card': type === 'list',
            'p-3 border bg-white mt-3': type === 'card'
          })}
        >
          <Form>
            <Form.Control
              as="textarea"
              rows={2}
              className="mb-2"
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent default newline behavior
                  handleSubmit(formData);
                }
              }}
              onChange={({ target }) => setFormData({ ...formData, title: target.value })}
              placeholder={type === 'list' ? 'Provider Name...' : 'Brand Name...'}
            />
            <div className="row gx-2">
              <div className="col">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return handleSubmit(formData);
                  }}
                  variant="primary"
                  size="sm"
                  className="d-block w-100"
                  type="button"
                >
                  Add
                </Button>
              </div>
              <div className="col">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="d-block w-100 border-400"
                  type="button"
                  onClick={() => setShowForm(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

AddAnotherForm.propTypes = {
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.func
};
