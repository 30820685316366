/*eslint camelcase: ["error", {allow: ["stage_position","stage_id"]}]*/

import React, { useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { USER_TYPE_ADMIN } from '../../services/constants';
import { useAxios } from '../../hooks/useAxios';
import { CanceledError } from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { preventDefault } from '../../utils';
import { useResize } from '../../hooks/useResize';

export const StageItemsUpdate = ({ brand, setBrand }) => {
  const windowSize = useResize();
  const { axios, abortRequest } = useAxios();
  const { profile } = useContext(AppContext);
  const [stages, setStages] = useState([]);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;

  const paginateStates = useCallback(
    (states, selected) => {
      let DELTA = 3;
      if (windowSize?.width > 1200) {
        DELTA = 4;
      } else if (windowSize?.width < 700) {
        DELTA = 2;
      }
      const paginationIndex = states?.findIndex((item) => item.id === selected);
      let startIndex = paginationIndex - DELTA;
      let endIndex = Math.min(paginationIndex + DELTA, states.length - 1);
      startIndex = Math.max(Math.min(startIndex, endIndex - 2 * DELTA), 0);
      endIndex = Math.max(endIndex, startIndex + 2 * DELTA);
      return states.filter((item, index) => index >= startIndex && index <= endIndex);
    },
    [windowSize]
  );
  const loadStates = async () => {
    try {
      const response = await axios.get(`/dropdown/stage/?active=true`);
      setStages(response?.data?.results);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    loadStates().catch(console.error);
    return abortRequest;
  }, []);

  if (!isAdmin || !brand || !stages?.length) {
    return null;
  }
  const items = paginateStates(stages, brand?.stage);

  return (
    <div className="p-3 bg-light theme-wizard">
      <ul className="nav nav-pills nav-fill">
        {items?.map((item, index) => {
          const isActive = brand?.stage === item?.id;
          return (
            <li className="nav-item" key={index}>
              <Nav.Link
                style={{ minHeight: 46 /* size of an item with image */ }}
                className={classNames('fw-semi-bold px-2', {
                  'text-primary': items.findIndex((item) => brand?.stage === item?.id) > index,
                  active: isActive
                })}
                onClick={async (e) => {
                  preventDefault(e);
                  if (brand?.stage !== item?.id) {
                    try {
                      const { data } = await axios.post(`/kanban/brand/${brand?.id}/`, {
                        stage: item?.id
                      });

                      setBrand({ ...brand, ...data });

                      toast.success(
                        <>
                          Brand <Link to={`/brand/${data?.id}/`}>{data?.name}</Link> was updated successfully
                        </>
                      );
                    } catch (data) {
                      if (data?.message) {
                        toast.error(data?.message);
                      }
                    }
                  }
                }}
              >
                {!item?.font_awesome_logo ? (
                  <>
                    {item?.logo ? (
                      <img src={item?.logo} alt={`Preview of State Logo`} width={'30'} height={'30'} className="me-2" />
                    ) : null}
                  </>
                ) : (
                  <>
                    {item?.font_awesome_logo ? (
                      <FontAwesomeIcon icon={item?.font_awesome_logo} className="me-2" />
                    ) : null}
                  </>
                )}
                <span className="fs--1">{item?.name}</span>
              </Nav.Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

StageItemsUpdate.propTypes = {
  brand: PropTypes.any,
  setBrand: PropTypes.any
};
