import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormikError } from './FormikError';
import CurrencyField from './CurrencyField';
import IntegerField from './IntegerField';
import { PositiveIntegerField } from './PositiveNumberField';

const InputWrapper = ({ name, label, type }) => {
  const wrapperProps = {
    showLabel: false,
    required: false,
    disabled: false,
    label: label,
    name: name
  };

  return (
    <>
      {type === 'currency' && <CurrencyField placeholder={label} {...wrapperProps} className="w-100 mb-0" />}
      {type === 'integer' && <IntegerField {...wrapperProps} className="w-100 mb-0" />}
      {type === 'positiveinteger' && <PositiveIntegerField {...wrapperProps} className="w-100 mb-0" />}
    </>
  );
};

InputWrapper.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
};

export const MinMaxField = ({ label, type, ...props }) => {
  const { name } = props;
  const [meta] = useField(props);

  return (
    <div>
      <label>{label}</label>
      <div className="d-flex" style={{ gap: 10 }}>
        <InputWrapper name={`${name}_min`} label={'Min'} type={type} />
        <InputWrapper name={`${name}_max`} label={'Max'} type={type} />
      </div>
      <FormikError name={name} meta={meta} />{' '}
    </div>
  );
};

MinMaxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string
};

export default MinMaxField;
