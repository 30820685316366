import { Button } from 'react-bootstrap';
import { React } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const LoadingButton = ({
  type,
  color,
  className,
  isLoading,
  label,
  children,
  disabled,
  size,
  variant = 'primary',
  ...props
}) => {
  return (
    <Button
      size={size}
      type={type}
      color={color}
      variant={variant}
      className={classNames(className, { 'text-capitalize': true })}
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        minHeight: size === 'sm' ? 29 : 36
      }}
      disabled={disabled || isLoading}
      {...props}
    >
      {!isLoading ? (
        <>{children ? children : label}</>
      ) : (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      )}
    </Button>
  );
};

LoadingButton.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.any,
  size: PropTypes.any
};

export default LoadingButton;
