import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { formatDatetime, isImage, USER_TYPE_BRAND } from '../../services/utils';
import avatar from 'assets/img/team/avatar.png';
import Avatar, { Thumbnail } from '../../components/common/Avatar';
import AppContext from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import { USER_PERMISSION_ACCOUNT, USER_PERMISSION_BRAND_EDIT } from '../../services/constants';
import { useAxios } from '../../hooks/useAxios';
import { Pagination } from '@mantine/core';
import { CanceledError } from 'axios';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const basename = (path) => {
  return path.split('/').reverse()[0];
};

const LinkUser = ({ href, children, ...props }) => {
  if (!href) {
    return <span {...props}>{children}</span>;
  }
  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};
LinkUser.propTypes = {
  href: PropTypes.any,
  children: PropTypes.any
};

export const MultipleFileField = ({ brandId, disabled }) => {
  const { profile } = useContext(AppContext);
  const [documents, setDocuments] = useState(null);
  const [response, setResponse] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(null);
  const { isSubmitting } = useFormikContext();
  const { axios, isLoading, setIsLoading, abortRequest } = useAxios();
  // const { onPaginate, filters } = useFilters();
  const [filters, setFilters] = useState({});

  const setVisibility = async (documentId, visibility) => {
    try {
      setIsLoading(true);
      await axios.post(`brand/${brandId}/documents/${documentId}/${visibility}/`);
      await loadData();
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        toast.error('Failed to set visibility of Document');
        setIsLoading(false);
      }
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`brand/${brandId}/documents/`, { params: { ...filters } });
      setDocuments(response?.data?.results);
      setResponse(response?.data);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsUploading(true);
    const data = new FormData();
    acceptedFiles.map((file) => {
      data.append('files', file);
    });
    try {
      await axios.post(`brand/${brandId}/documents/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        }
      });

      await loadData();
      setProgress(null);
    } catch (e) {
      toast.error('The server upload of specific files has failed');
    }

    setIsUploading(false);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: disabled || isUploading || isLoading,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.gif', '.svg'],
      'application/pdf': ['.pdf'],
      'text/csv': ['.csv'],
      'text/txt': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/rtf': ['.rtf']
    }
  });

  const deleteDocument = async (id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, the data cannot be recovered',
      showCancelButton: true,
      showConfirmButton: true
    });
    if (isConfirmed) {
      try {
        await axios.delete(`brand/${brandId}/documents/${id}/`);
      } catch (e) {
        toast.error('An error occurred while attempting to delete the file');
      }
    }

    await loadData();
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, [filters]);

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone-area py-6 mb-3' })}>
        <input {...getInputProps()} />
        <div className="d-flex justify-content-center align-items-center">
          {isUploading ? (
            <Spinner animation="border" style={{ borderWidth: 1 }} size="sm" className="me-2" />
          ) : (
            <FontAwesomeIcon icon="cloud-upload-alt" size="lg" className={'me-2'} />
          )}
          <p className={'fs-0 mb-0'}>
            {isUploading ? <>Uploading files {progress ? `${progress}%` : '...'}</> : 'Drop your files here'}
          </p>
        </div>
      </div>

      <div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-muted text-uppercase">
                  <small>File</small>
                </th>
                {/*<th className="text-muted text-uppercase text-right">*/}
                {/*  <small>Visibility</small>*/}
                {/*</th>*/}
                <th className="text-muted text-uppercase">
                  <small>Author</small>
                </th>
                <th className="text-muted text-uppercase text-right">
                  <small>Created At</small>
                </th>
                {profile.permissions.includes(USER_PERMISSION_BRAND_EDIT) ? <th></th> : null}
              </tr>
            </thead>
            <tbody>
              {documents?.length === 0 ? (
                <tr>
                  <td colSpan={5}>
                    <div className="text-muted text-center">No documents found</div>
                  </td>
                </tr>
              ) : null}
              {documents?.map((documentItem, index) => {
                let fileUrl;
                let fileName;
                if (documentItem?.file instanceof File) {
                  fileUrl = URL.createObjectURL(documentItem?.file);
                  fileName = documentItem?.file.name;
                } else {
                  fileUrl = documentItem?.file;
                  fileName = basename(documentItem?.file);
                }

                const userLinkProps = {};

                if (documentItem?.user?.id && profile.permissions.includes(USER_PERMISSION_ACCOUNT)) {
                  userLinkProps['href'] = `/settings/user/${documentItem?.user?.id}`;
                }

                return (
                  <tr key={index} className="border-bottom mb-3 pb-2">
                    <td style={{ minWidth: 300 }}>
                      <div className="d-flex align-items-center">
                        {isImage(fileUrl) ? (
                          <Thumbnail src={fileUrl} className="me-3 border" />
                        ) : (
                          <div
                            className={
                              'invisible-thumbnail d-flex align-items-center justify-content-center me-3 border'
                            }
                          >
                            <FontAwesomeIcon icon="file" size="2x" className="text-muted" />
                          </div>
                        )}
                        <div>
                          <a href={fileUrl} download={fileUrl || 'download'} target="_blank" rel="noreferrer">
                            {fileName}
                          </a>
                          {documentItem?.is_public && profile.type !== USER_TYPE_BRAND ? (
                            <div>
                              <small>
                                <Badge bg="warning">Public</Badge>
                              </small>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </td>
                    {/*<td>*/}
                    {/*  <SwitchInput label="Public" className="ms-2" />*/}
                    {/*</td>*/}
                    <td style={{ minWidth: 200 }}>
                      <LinkUser
                        {...userLinkProps}
                        target="_blank"
                        className="d-flex align-items-center"
                        rel="noreferrer"
                        style={{ minHeight: 65 }}
                      >
                        <Avatar src={documentItem?.user?.photo ? documentItem?.user?.photo : avatar} className="me-2" />
                        <div>{documentItem?.user?.first_name}</div>
                      </LinkUser>
                    </td>
                    <td style={{ minWidth: 200 }}>
                      <div style={{ minHeight: 65 }} className="d-flex align-items-center">
                        {formatDatetime(documentItem?.created_at)}
                      </div>
                    </td>
                    {profile.permissions.includes(USER_PERMISSION_BRAND_EDIT) ? (
                      <td>
                        <div style={{ minHeight: 65 }} className="d-flex align-items-center">
                          {/*<Button*/}
                          {/*  disabled={isSubmitting}*/}
                          {/*  variant="link"*/}
                          {/*  onClick={() => deleteDocument(documentItem.id)}*/}
                          {/*>*/}
                          {/*  <FontAwesomeIcon icon="trash" className="text-danger" />*/}
                          {/*</Button>*/}
                          {
                            // This condition checks if the user is not a brand user or if they are the owner of the document.
                            (profile?.type !== USER_TYPE_BRAND || documentItem?.user?.id === profile?.id) && (
                              <Dropdown className="ms-auto">
                                <Dropdown.Toggle
                                  bsPrefix="toggle"
                                  as={Link}
                                  to="#"
                                  disabled={isSubmitting}
                                  className="nav-link d-flex align-items-center text-muted"
                                >
                                  <FontAwesomeIcon icon="ellipsis-v" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
                                  {profile?.type !== USER_TYPE_BRAND && (
                                    <div className="bg-white rounded-2 dark__bg-1000">
                                      <Dropdown.Item
                                        as={'button'}
                                        type={'button'}
                                        onClick={() => {
                                          setVisibility(
                                            documentItem?.id,
                                            documentItem?.is_public ? 'private' : 'public'
                                          );
                                        }}
                                        className="py-2"
                                      >
                                        Set as {documentItem?.is_public ? 'private' : 'public'}
                                      </Dropdown.Item>
                                    </div>
                                  )}
                                  <div className="bg-white rounded-2 dark__bg-1000">
                                    <Dropdown.Item
                                      as={'button'}
                                      type={'button'}
                                      className="d-flex align-items-center py-2"
                                      onClick={() => deleteDocument(documentItem.id)}
                                    >
                                      <FontAwesomeIcon icon="trash" className="text-danger me-2" />
                                      <span style={{ lineHeight: 1 }}>Delete</span>
                                    </Dropdown.Item>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            )
                          }
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex justify-content-center">
            <Pagination
              page={response?.page}
              total={response?.num_pages}
              withEdges
              onChange={(page) => setFilters({ page })}
              disabled={response?.num_pages <= 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MultipleFileField.propTypes = {
  brandId: PropTypes.any,
  disabled: PropTypes.any
};
