import { Button, Card } from 'react-bootstrap';
import FalconCardHeader from './common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDatetime } from '../services/utils';
import { preventDefault } from '../utils';
import classNames from 'classnames';
import { LoadingView } from './LoadingView';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export const LogListView = ({ log, isLoading, backUrl, title }) => {
  const [visibleDetails, setVisibleDetails] = useState([]);
  const navigate = useNavigate();
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).catch(console.error);
    toast.success('Copied to clipboard', { theme: 'colored' });
  };
  return (
    <>
      {!isLoading ? (
        <div className="g-3">
          <div className="animate__animated animate__fadeIn animate__delay-1">
            {/* this card is only shown when we are creating a new order since user should not update status, retailer, etc..*/}
            {log && (
              <Card className="mb-3 animate__animated animate__fadeIn">
                <FalconCardHeader title={title} />
                <Card.Body className="bg-light p-0">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Event Time</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {log?.events?.length === 0 ? (
                          <tr>
                            <td colSpan={4} className="text-center text-muted">
                              No logs found
                            </td>
                          </tr>
                        ) : null}
                        {log?.events?.map((event, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td style={{ width: 150 }}>
                                  <span className="d-flex align-items-center">
                                    {event?.type === 'sms' ? (
                                      <FontAwesomeIcon icon="sms" className="me-1" />
                                    ) : (
                                      <FontAwesomeIcon icon="plug" className="me-1" />
                                    )}
                                    {event?.type ? event.type.toUpperCase() : 'webhook'.toUpperCase()}
                                  </span>
                                </td>
                                <td>
                                  {event.status === 200 ||
                                    (`${event.status}`.toLowerCase() === 'success' && (
                                      <>
                                        <FontAwesomeIcon icon="circle" className="text-success me-1" />
                                        {`${event.status}`.toUpperCase()}
                                      </>
                                    ))}
                                  {event.status !== 200 && `${event.status}`.toLowerCase() === 'fail' && (
                                    <>
                                      <FontAwesomeIcon icon="circle" className="text-danger me-1" />
                                      {`${event.status}`.toUpperCase()}
                                    </>
                                  )}
                                  {event.status !== 200 && `${event.status}`.toLowerCase() === 'retry' && (
                                    <>
                                      <FontAwesomeIcon icon="circle" className="text-warning me-1" />
                                      {`${event.status}`.toUpperCase()}
                                    </>
                                  )}
                                </td>
                                <td>{formatDatetime(event.created_at)}</td>
                                <td style={{ width: 170 }}>
                                  <a
                                    href="#show-hide"
                                    onClick={(e) => {
                                      preventDefault(e);
                                      const payload = [...visibleDetails];
                                      if (visibleDetails.includes(index)) {
                                        payload.pop(index);
                                      } else {
                                        payload.push(index);
                                      }
                                      setVisibleDetails(payload);
                                    }}
                                  >
                                    {!visibleDetails.includes(index) ? 'Show Details' : 'Hide Details'}
                                  </a>
                                </td>
                              </tr>
                              <tr
                                className={classNames('', {
                                  'd-none': !visibleDetails.includes(index)
                                })}
                              >
                                <td colSpan={4} className="p-0">
                                  <pre onClick={() => copyToClipboard(JSON.stringify(event))} className="pre-code p-1">
                                    {JSON.stringify(event, null, 4)}
                                  </pre>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
          <Card className="mt-3 mb-3 animate__animated animate__fadeIn animate__delay-1">
            <Card.Body className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="d-flex">
                <Button variant="default" onClick={() => navigate(backUrl)}>
                  Back
                </Button>
              </div>
              <div></div>
            </Card.Body>
          </Card>
        </div>
      ) : null}
      {isLoading && (
        <div className="py-3 animate__animated animate__fadeIn">
          <LoadingView width={1} />
        </div>
      )}
    </>
  );
};

LogListView.propTypes = {
  log: PropTypes.any,
  isLoading: PropTypes.any,
  title: PropTypes.any,
  backUrl: PropTypes.any
};
