import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

export const FormikError = ({ name }) => {
  return (
    <>
      <small className="text-danger">
        <ErrorMessage name={name} />
      </small>
    </>
  );
};

FormikError.propTypes = {
  name: PropTypes.any
};
