import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { useAxios } from './useAxios';

export const useVisibleTableFields = (name, availableFields) => {
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const initialVisible = {};
  for (const field of availableFields) {
    initialVisible[field.name] = true;
  }
  const [isPreferencesLoading, setIsPreferencesLoading] = useState(true);
  const [visibleFields, setVisibleFields] = useState(initialVisible);

  const debounceSavePreferences = useCallback(
    debounce(async (data) => {
      try {
        setIsLoading(true);
        const payload = {};
        payload[name] = Object.keys(data).filter((field) => !data[field]);
        const {
          data: { message }
        } = await axios.post('user-preferences/?name=column', payload);
        setIsLoading(false);
        toast.success(message, { theme: 'colored' });
      } catch ({ data: { message } }) {
        toast.error(message, {
          theme: 'colored'
        });
      }
    }, 700),
    [isLoading]
  );

  const setVisibleField = (fieldName, visible) => {
    const payload = {};
    payload[fieldName] = visible;
    const update = {
      ...visibleFields,
      ...payload
    };
    setVisibleFields(update);
    debounceSavePreferences(update);
  };

  const loadPreferences = async () => {
    try {
      const { data } = await axios.get('user-preferences/?name=column');

      if (data && name in data) {
        const fields = {};
        for (const fieldName of data[name]) {
          fields[fieldName] = false;
        }
        setVisibleFields({
          ...visibleFields,
          ...fields
        });
      }
    } finally {
      setIsPreferencesLoading(false);
    }
  };

  useEffect(() => {
    loadPreferences().catch(console.error);
    return abortRequest;
  }, []);
  return { isPreferencesLoading, visibleFields, setVisibleField };
};
