/*eslint camelcase: ["error", {allow: ["gift_card","password_confirm"]}]*/

import React, { useEffect } from 'react';
import Flex from 'components/common/Flex';
import shape1 from 'assets/img/illustrations/shape-1.png';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import TextField from '../../components/form/TextField';
import LoadingButton from '../../components/common/LoadingButton';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import { toast } from 'react-toastify';

const SignUp = () => {
  useStaticTitle('Sign Up');
  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
    password_confirm: Yup.string().required('Password is required')
  });

  const navigate = useNavigate();
  const { axios, abortRequest } = useAxios();

  useEffect(() => abortRequest, []);

  return (
    <>
      <div className="container">
        <img className="bg-auth-circle-shape-2" src={shape1} alt="" width="100" />
        <Flex justifyContent="center" alignItems="center" className="mb-2 w-100">
          <h5>Log in</h5>
        </Flex>

        <Formik
          validateOnMount={true}
          initialValues={{
            email: '',
            password: '',
            password_confirm: ''
          }}
          validationSchema={validate}
          onSubmit={async (values) => {
            try {
              await axios.post('account/auth/signup/', values);
              toast.success(<>User created successfully, your user will be activated by our customer service soon.</>);
              navigate('/');
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <div>
                <Form>
                  <TextField label="Email" name="email" type="email" disabled={isSubmitting} autoComplete={'on'} />
                  <TextField
                    label="password"
                    name="password"
                    type="password"
                    disabled={isSubmitting}
                    autoComplete={'on'}
                    minLength={6}
                  />
                  <TextField
                    label="password"
                    name="password_confirm"
                    type="password"
                    disabled={isSubmitting}
                    autoComplete={'on'}
                    minLength={6}
                  />
                  <Row className="justify-content-between align-items-center">
                    <Col xs="auto" />
                    <Col xs="auto">
                      <Link className="fs--1 mb-0" to={`/forgot-password`}>
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>

                  <LoadingButton disabled={!isValid} className="mt-3 w-100" isLoading={isSubmitting} type="submit">
                    Sign Up
                  </LoadingButton>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default SignUp;
