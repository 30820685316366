import React from 'react';
import { LoadingView } from '../components/LoadingView';
import NavbarVertical from '../components/navbar/vertical/NavbarVertical';
import { NavbarTop } from '../components/NavbarTop';
import Footer from '../components/footer/Footer';

const LoadingScreen = () => {
  return (
    <div className={'container-fluid'}>
      <NavbarVertical />
      <div className="content">
        <NavbarTop />
        <div className="pt-5">
          <LoadingView width={1} />
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default LoadingScreen;
