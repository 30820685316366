import React from 'react';

export const SentryTest = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column" style={{ minHeight: 400 }}>
      <div>Sentry test page, nothing to see here...</div>
      <div>
        <button
          className="btn btn-primary mt-3"
          onClick={() => {
            throw new Error('This is your first error!');
          }}
        >
          Sent an error to sentry...
        </button>
      </div>
    </div>
  );
};
