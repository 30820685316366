import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Button, ButtonGroup, Form as BootstrapForm } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequiredMarker } from './RequiredMarker';

const basename = (url) => {
  return url.substring(url.lastIndexOf('/') + 1, url.length);
};

export const FileField = ({
  label,
  clearable = true,
  helpText = null,
  disabled = false,
  required = true,
  accept = 'image/*',
  clazz = 'mb-3',
  ...props
}) => {
  const [{ value, name, id }, meta, { setValue }] = useField(props);
  const controlId = id || name;
  const ref = useRef();

  const handleOnChange = ({ target: { files } }) => {
    const file = files?.length > 0 ? files[0] : null;
    setValue(file);
  };

  return (
    <BootstrapForm.Group className={clazz}>
      <div className="mt-1">
        <div>
          <BootstrapForm.Label htmlFor={controlId}>
            {label} {required && <RequiredMarker />}
          </BootstrapForm.Label>
        </div>
        <div>{value ? (value instanceof File ? value?.name : basename(value)) : ''}</div>
        <input
          id={`id_${name}`}
          accept={accept}
          disabled={disabled}
          type="file"
          onChange={handleOnChange}
          ref={ref}
          required={required}
          className="d-none"
        />
        {clearable ? (
          <>
            <ButtonGroup size="sm" className="me-2 mb-1">
              <Button
                id={`id_${name}_change`}
                type="button"
                className="text-nowrap"
                disabled={disabled}
                variant="secondary"
                onClick={() => ref.current?.click()}
              >
                Choose file
              </Button>
              {value && (
                <Button id={`id_${name}_remove`} disabled={disabled} variant="light" onClick={() => setValue('')}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              )}
            </ButtonGroup>
          </>
        ) : (
          <button
            id={`id_${name}_change`}
            type="button"
            disabled={disabled}
            className="btn btn-success text-nowrap"
            onClick={() => ref.current?.click()}
          >
            Choose file
          </button>
        )}
      </div>
      {!meta.error && helpText && <BootstrapForm.Text className="text-muted">{helpText}</BootstrapForm.Text>}
      {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
    </BootstrapForm.Group>
  );
};

FileField.propTypes = {
  label: PropTypes.string,
  clazz: PropTypes.string,
  accept: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  defaultSrc: PropTypes.any,
  imageClass: PropTypes.any
};
