import { useLocation, useSearchParams } from 'react-router-dom';
import { normalizeBooleanFromSearchParams } from '../services/utils';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const encodeSortField = (sort) => {
  if (!sort) {
    return null;
  }
  return Object.keys(sort)
    .map((field) => {
      const prefix = sort[field] ? '-' : '';
      return `${prefix}${field}`;
    })
    .join(',');
};

const parseSortField = (ordering) => {
  if (ordering) {
    const sort = {};
    ordering.split(',').map((sorted) => {
      const desc = sorted.startsWith('-');
      if (desc) {
        sorted = sorted.substring(1, sorted.length);
      }
      sort[sorted] = desc;
    });
    return sort;
  }
  return {};
};

const normalizeInitialData = (qs) => {
  const { page, q, ordering, ...rest } = qs;
  const sort = parseSortField(ordering);
  let initialData = { ...rest };
  if (page) {
    initialData = { ...initialData, page };
  }
  if (q) {
    initialData = { ...initialData, q };
  }
  if (sort) {
    initialData = { ...initialData, sort };
  }
  return initialData;
};

export const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSearchParams = normalizeBooleanFromSearchParams(searchParams);
  const [data, setData] = useState(normalizeInitialData(currentSearchParams));
  const { hash } = useLocation();

  const onPaginate = (page) => {
    setData({ ...data, page: page });
  };
  const onSortBy = (orderList) => {
    const sort = {};
    orderList?.map((field) => {
      sort[field?.id] = field.desc;
    });
    setData({ ...data, sort });
  };
  const clearAllFilter = () => {
    const payload = { ...data };
    const ignoreFields = ['q', 'sort', 'column'];
    for (const key in payload) {
      if (!ignoreFields.includes(key)) {
        delete payload[key];
      }
    }
    setData({ ...payload });
  };
  const clearSpecificFilter = (filterName) => {
    const payload = { ...data };
    if (filterName in payload) {
      delete payload[filterName];
    }
    setData({ ...payload });
    toast.warn('Filter Removed', {
      theme: 'colored'
    });
  };
  const onSearch = (term) => {
    if ('page' in data) {
      delete data['page'];
    }
    setData({ ...data, q: term });
  };

  const onFilter = (filters) => {
    if ('page' in filters) {
      delete filters['page'];
    }
    // setData({ ...data, ...filters });
    setData(filters);
  };

  const sortBy = useMemo(() => {
    return Object.keys(data?.sort || {}).map((field) => ({ id: field, desc: data?.sort[field] }));
  }, [data]);

  const search = useMemo(() => {
    return data?.q;
  }, [data]);
  const filters = useMemo(() => {
    const { sort, q, page, ...rest } = data;
    const normalizedFilters = { ...rest };
    if (sort && Object.keys(sort).length > 0) {
      normalizedFilters['ordering'] = encodeSortField(sort);
    }
    if (q) {
      normalizedFilters['q'] = q;
    }
    if (page) {
      normalizedFilters['page'] = page;
    }
    return normalizedFilters;
  }, [data]);

  const page = useMemo(() => {
    return data?.page;
  }, [data]);

  useEffect(() => {
    const { sort, page, ...rest } = data;
    const ordering = encodeSortField(sort);
    const payload = { ...rest };

    if (ordering) {
      payload['ordering'] = ordering;
    }
    if (page) {
      payload['page'] = page;
    }
    setSearchParams(payload);
    window.location.hash = hash;
  }, [data]);

  return {
    filters,
    search,
    sortBy,
    page,
    onFilter,
    onSearch,
    onSortBy,
    onPaginate,
    clearFilter: clearAllFilter,
    clearSpecificFilter: clearSpecificFilter
  };
};
