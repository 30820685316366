/*eslint camelcase: ["error", {allow: ["status_display","recurrence_name","recovery_checkout_url","recovery_status","shipping_country","recovery_status_display","checkout_id","shipping_email","order_number","risk_score","item_sum","retailer_name","brand_name","status_name","tracking_number", "tracking_url","tracking_company","tracking_status", "ids_list", "created_at","customer_email", "items_count","total_range_value","shipping_state",]}]*/
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import { cellAsDatetime, STATE_LIST, SUBSCRIPTION_STATUS_CHOICES, USER_TYPE_ADMIN } from '../../services/utils';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import CurrencyFormat from 'react-currency-format';
import { useFilters } from '../../hooks/useFilters';
import { FilterRow } from '../../components/FilterRow';
import { CanceledError } from 'axios';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import MinMaxField from '../../components/form/MinMaxField';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../context/Context';

const SubscriptionList = () => {
  useStaticTitle('Subscriptions');

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();

  const asRoute = (rowData) => {
    const { id } = rowData.row.original;
    return `/subscription/${id}/`;
  };

  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'code',
      Header: 'Subscription',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { code } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal font-monospace" to={asRoute(rowData)}>
              {code}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (rowData) => {
        const { status_display } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            {status_display}
          </Link>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      Cell: (rowData) => {
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            {cellAsDatetime(rowData, 'created_at')}
          </Link>
        );
      }
    },
    {
      accessor: 'brand',
      Header: 'Brand',
      Cell: (rowData) => {
        const { brand_name } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal" to={asRoute(rowData)}>
              {brand_name}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'customer_email',
      Header: 'Placed By',
      Cell: (rowData) => {
        const { customer_email } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            {customer_email}
          </Link>
        );
      }
    },
    {
      accessor: 'recurrence_name',
      Header: 'Recurrence',
      Cell: (rowData) => {
        const { recurrence_name } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal text-decoration-none" to={asRoute(rowData)}>
            <FontAwesomeIcon icon={'clock'} className="me-1" />
            {recurrence_name}
          </Link>
        );
      }
    },
    {
      accessor: 'total',
      Header: 'Total',
      Cell: (rowData) => {
        const { total } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={total || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'shipping_state',
      Header: 'Shipping State',
      Cell: (rowData) => {
        const { shipping_state } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            {shipping_state ? shipping_state : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'retailer',
      Header: 'Retailer',
      Cell: (rowData) => {
        const { retailer_name } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={asRoute(rowData)}>
            {retailer_name}
          </Link>
        );
      }
    }
  ];
  const filterComponent = (
    <>
      <FilterRow name="recurrence">
        <AsyncSelectField label="Recurrence" name="recurrence" urlEndpoint={'/dropdown/recurrence/'} className="mb-0" />
      </FilterRow>
      <FilterRow name="brand">
        <AsyncSelectField label="Brand" name="brand" urlEndpoint={'/dropdown/brand/'} className="mb-0" />
      </FilterRow>
      <FilterRow name="status">
        <SimpleSelectField options={SUBSCRIPTION_STATUS_CHOICES} label="Status" name="status" className="mb-0" />
      </FilterRow>
      <FilterRow name="shipping_state">
        <SimpleSelectField options={STATE_LIST} label="State" name="shipping_state" className="mb-0" />
      </FilterRow>
      <FilterRow name="retailer">
        <AsyncSelectField label="Retailer" name="retailer" urlEndpoint={'/dropdown/retailer/'} className="mb-0" />
      </FilterRow>
      <FilterRow name={['total_range_min', 'total_range_max']}>
        <MinMaxField label="Total" name="total_range" type="currency" min={0} />
      </FilterRow>
    </>
  );
  const rowOptions = [
    {
      label: 'Copy Stripe URL',
      icon: 'copy',
      onClick: async (obj) => {
        await navigator.clipboard.writeText(obj?.stripe_url);
        toast.success('Stripe URL link copied to clipboard');
      },
      hasPermission: () => {
        return profile?.type === USER_TYPE_ADMIN;
      }
    }
  ];

  const loadData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('subscription/', { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      // only hide loading if the error isn't a cancel to a new request
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, [filters]);
  // const userHasAddPermission = () => true;
  const actions = [].filter((action) => action.hasPermission());

  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Subscriptions'}
        name="subscription"
        filterComponent={filterComponent}
        actions={actions}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
      />
    </>
  );
};
export default SubscriptionList;
