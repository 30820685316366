import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '../../components/form/TextField';
import LoadingButton from '../../components/common/LoadingButton';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';

const validate = Yup.object({
  email: Yup.string().email('Email is invalid').required('Email is required')
});
const ForgetPassword = () => {
  useStaticTitle('Forgot Password');

  const navigate = useNavigate();
  const { axios, abortRequest } = useAxios();
  useEffect(() => abortRequest, []);

  return (
    <>
      <div>
        <div className="text-center">
          <h5 className="mb-0 text-capitalize">Forgot your password?</h5>
          <small>Enter your email and we'll send you a reset link.</small>
        </div>

        <Formik
          validateOnMount={true}
          initialValues={{
            email: ''
          }}
          validationSchema={validate}
          onSubmit={async ({ email }) => {
            try {
              const {
                data: { message }
              } = await axios.post('account/auth/reset-password/', { email: email });
              toast.success(message, { theme: 'colored' });
              navigate('/confirm-email');
            } catch ({ response: { data } }) {
              toast.error(data.message, {
                theme: 'colored'
              });
            }
          }}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                name="email"
                data-lpignore="true"
                autoComplete="new-password"
                type="email"
                disabled={isSubmitting}
              />
              <LoadingButton
                type="submit"
                color="primary"
                disabled={!isValid}
                className="mt-3 w-100 text-capitalize"
                isLoading={isSubmitting}
              >
                Send reset link
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgetPassword;
