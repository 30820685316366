import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BooleanIndicator = ({ isActive }) => (
  <FontAwesomeIcon
    icon={isActive ? 'fa-check-circle' : 'fa-times-circle'}
    className={classNames('text-success fs-0', {
      'text-success': isActive,
      'text-danger': !isActive
    })}
  />
);

BooleanIndicator.propTypes = {
  isActive: PropTypes.bool
};
