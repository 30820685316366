import { Badge, Card } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text, Timeline } from '@mantine/core';
import { formatDatetime } from '../../../services/utils';
import CurrencyFormat from 'react-currency-format';
import { USER_TYPE_ADMIN } from '../../../services/constants';
import AppContext from '../../../context/Context';
export const ChargesWidget = ({ order }) => {
  const { profile } = useContext(AppContext);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;

  if (!order || !isAdmin) return null;

  const scrollContainerRef = useRef(null);
  const mostRecentChargeIndex = 0;

  const timelineItems = (order?.charges || []).map((charge, index) => {
    return (
      <Timeline.Item key={index} bullet={''} lineVariant={'dashed'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size="sm" weight={500}>
            {charge?.event_type}
          </Text>
          {charge?.status === 'Canceled' && (
            <small>
              <Badge className="ms-2" bg="warning" style={{ marginRight: '8px' }}>
                {charge?.status}
              </Badge>
            </small>
          )}
        </div>
        <Text size="xs" mt={4}>
          Amount: {charge?.amount_refunded}
        </Text>
        <Text size="xs" mt={4}>
          Date: {formatDatetime(charge?.created_at)}
        </Text>
        {/* Only display receipt_url for the most recent charge */}
        {index === mostRecentChargeIndex && order?.receipt_url && (
          <a href={order?.receipt_url} target="_blank" rel="noopener noreferrer">
            <small>View Receipt</small>
          </a>
        )}
      </Timeline.Item>
    );
  });
  const paymentSucceededItem = (
    <Timeline.Item bullet={''} lineVariant={'dashed'}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Text size="sm" weight={500}>
          Payment Succeeded
        </Text>
      </div>
      <Text size="xs" mt={4}>
        Amount:
        <CurrencyFormat
          decimalScale={2}
          fixedDecimalScale={true}
          value={order?.total || 0}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
        />
      </Text>
      <Text size="xs" mt={4}>
        Date: {formatDatetime(order?.created_at)}
      </Text>
    </Timeline.Item>
  );
  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-5">
      <ToggleCard title="Charges">
        <Card.Body className="bg-light">
          <div
            ref={scrollContainerRef}
            className="payment-charges-scroll-container"
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          >
            <div className="mb-4">
              <ul className="list-unstyled">
                <li>
                  <h6>Summary:</h6>
                  <ul>
                    <li>
                      <h6>
                        Total Charged Amount:{' '}
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={order?.total || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Total Refunded Amount:
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={order?.total_refund_amount || 0}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                        />
                      </h6>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <Timeline active={1} bulletSize={24} lineWidth={2}>
              {timelineItems}
              {paymentSucceededItem}
            </Timeline>
          </div>
        </Card.Body>
      </ToggleCard>
    </Card>
  );
};

ChargesWidget.propTypes = {
  order: PropTypes.object.isRequired
};
