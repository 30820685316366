/*eslint camelcase: ["error", {allow: ["account_manager_permission","dashboard_permission","ids_list","url_endpoint","change_password","is_active",url_endpoint"user_id","first_name","last_name","phone_number","confirm_password","brand_list","brand_permission","brand_edit_permission","product_permission","product_edit_permission","order_permission","order_edit_permission","discount_permission","discount_edit_permission","account_permission","account_edit_permission","setting_permission","setting_edit_permission","gift_card_permission","gift_card_edit_permission","customer_permission","report_permission"]}]*/
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import FalconCardHeader from '../../components/common/FalconCardHeader';

import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { SubmitFooter } from '../../components/form/SubmitFooter';
import AsyncSelectField from '../../components/form/SelectField';
import { useAxios } from '../../hooks/useAxios';
import { useDocumentAction, useDocumentTitle } from '../../hooks/usePageTitle';
import AppContext from '../../context/Context';
import { USER_PERMISSION_IMPORT_EDIT } from '../../services/constants';
import { FileField } from '../../components/form/FileField';
import { asFormData } from '../../services/utils';
import { SwitchField } from '../../components/form/CheckboxField';

const TEMPLATE_URL =
  'https://s3.us-west-2.amazonaws.com/files.sandboxbottlenexus.com/import-documentation-template.zip';

export const ImportCreate = () => {
  const { id } = useParams();

  const { axios } = useAxios();
  const { profile } = useContext(AppContext);

  const isReadOnly = id && !profile.permissions.includes(USER_PERMISSION_IMPORT_EDIT);

  useDocumentTitle([['Import Task', useDocumentAction()]]);

  const navigate = useNavigate();
  const validate = Yup.object({});
  console.log('lorem');
  return (
    <div className="mt-3 animate animate__fadeIn">
      <Formik
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={validate}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            setSubmitting(true);
            const payload = asFormData({ ...values });

            const { data } = await axios.post('import/', payload);

            toast.success(
              <>
                Import Task <Link to={`import/`}>{data.name}</Link>{' '}
                {id ? 'was updated successfully' : 'was created successfully'}
              </>
            );
            navigate(`/import/`);
          } catch ({ response }) {
            setErrors(response?.data);

            if (response?.data?.message) {
              toast.error(response?.data?.message);
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form>
                <Card>
                  <FalconCardHeader
                    title="Create Import Task"
                    endEl={
                      <>
                        <a
                          href={TEMPLATE_URL}
                          download="import-documentation-template.zip"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download
                        </a>
                      </>
                    }
                  />
                  <Card.Body className="bg-light">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <AsyncSelectField
                          label="Brand"
                          name="brand"
                          required={!isReadOnly}
                          disabled={isSubmitting || isReadOnly}
                          urlEndpoint="/dropdown/brand/"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <FileField
                          accept=".csv"
                          name="file"
                          label="CSV File"
                          required={!isReadOnly}
                          disabled={isSubmitting || isReadOnly}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <SwitchField
                          name="allow_no_items"
                          label="Allow Orders Without Items"
                          disabled={isSubmitting || isReadOnly}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                {!isReadOnly && <SubmitFooter backUrl={'/import/'} isSubmitting={isSubmitting} />}
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ImportCreate;
