import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import 'assets/scss/custom.scss';
import 'animate.css';
import ReactGA from 'react-ga4';
import { ENVIRONMENT_TYPE, SENTRY_DSN } from './utils';
import * as Sentry from '@sentry/react';

if (ENVIRONMENT_TYPE === 'production') {
  ReactGA.initialize('G-89LT8P6HLE');
}
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: []
  });
  console.log(' -> Initialized sentry: ', SENTRY_DSN);
}

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,

  document.getElementById('main')
);
