import ReactGA from 'react-ga4';
import { ENVIRONMENT_TYPE } from './index';

if (ENVIRONMENT_TYPE === 'production') {
  ReactGA.initialize('G-89LT8P6HLE');
}

export const setParameter = (userId, type, email) => {
  if (ENVIRONMENT_TYPE === 'production') {
    ReactGA.set({ userId, type, email });
  }
};
