import _ from 'lodash';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function arrayToString(labels, sep = ' - ') {
  return labels
    .map((item) => (_.isArray(item) ? arrayToString(item, ' ') : item?.toString()))
    .filter((label) => !_.isEmpty(label))
    .join(sep);
}

export const usePageTitle = (labelFunc, deps) => {
  useEffect(() => {
    const title = `${labelFunc()} | Bottle Nexus`;
    if (document.title !== title) document.title = title;
  }, deps);
};

export const useDocumentTitle = (labels) => {
  usePageTitle(() => arrayToString(labels), labels);
};

export const useStaticTitle = (title) => {
  usePageTitle(() => title);
};

export const useDocumentAction = (idParam = 'id') => {
  const param = useParams()[idParam];
  return param ? 'Edit' : 'Create';
};
