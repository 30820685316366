import React, { useContext } from 'react';
import classNames from 'classnames';
import { Nav, Navbar } from 'react-bootstrap';
import { navbarBreakPoint } from 'config';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import { NavbarVerticalMenu } from './NavbarVerticalMenu';
import routes from 'routes/routes';
import _ from 'lodash';

const NavbarVertical = () => {
  const { profile } = useContext(AppContext);
  const {
    config: { showBurgerMenu }
  } = useContext(AppContext);
  const checkPermission = (routePermission) => {
    if (Array.isArray(routePermission)) {
      return _.intersection(profile?.permissions, routePermission).length > 0;
    }
    return _.isFunction(routePermission) ? routePermission(profile) : profile?.permissions?.includes(routePermission);
  };

  const filterChildRoutes = (children) => {
    return children.filter((child) => checkPermission(child.permission));
  };
  return (
    <Navbar expand={navbarBreakPoint} className={classNames('navbar-vertical', {})} variant="light">
      <div className="d-flex align-items-center ps-2">
        <div className={'d-flex justify-content-center align-content-center spacing-logo-helper'}>
          <Logo at="navbar-vertical" />
        </div>
      </div>
      <Navbar.Collapse in={showBurgerMenu}>
        <div className="navbar-vertical-content scrollbar ps-2">
          <Nav className="flex-column" as="ul">
            <NavbarVerticalMenu
              routes={routes?.reduce((acc, route) => {
                const hasPermission = checkPermission(route?.permission);
                const isValidType = route?.types?.includes(profile?.type);
                if (hasPermission || isValidType) {
                  let newRoute = { ...route };
                  if (route?.children) {
                    newRoute.children = filterChildRoutes(route.children, profile?.permissions);
                  }
                  acc.push(newRoute);
                }
                return acc;
              }, [])}
            />
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
