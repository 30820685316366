/*eslint camelcase: ["error", {allow: ["stage", "brands_count", "sort_position"]}]*/
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BrandCard } from './BrandCard';
import { AddAnotherForm } from './AddAnotherForm';
import { Droppable } from 'react-beautiful-dnd';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useAxios } from '../../../hooks/useAxios';
import { Card, Dropdown, Placeholder } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KanbanContext } from '../../../context/Context';
import { withColumnId } from '../../../helpers/utils';
import { preventDefault } from '../../../utils';

export const KanbanColumn = ({ type, column, filters, setIsLoading, onLoadMore }) => {
  const { axios } = useAxios();

  let { id, name, sort_position } = column;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);
  const { setColumns, setBrands, setSelectedTask } = useContext(KanbanContext);
  const [requestSent, setRequestSent] = useState(false);

  const handleRemoveColumn = async () => {
    try {
      const { isConfirmed } = await SweetAlert.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      if (isConfirmed) {
        setIsLoading(true);
        await axios.delete(`provider/${id}/`);

        toast.success('Provider was deleted');

        setBrands((allBrands) => {
          return allBrands.map(withColumnId(type)).map((brand) => {
            if (brand.columnId === id) {
              if (type === 'stage') {
                brand.stage = null;
              } else {
                brand.provider = null;
              }
              return brand;
            }
            return brand;
          });
        });

        setColumns((prevItems) => {
          const updatedItems = [...prevItems];
          const index = updatedItems.findIndex((element) => element.id === id);
          if (index !== -1) {
            updatedItems.splice(index, 1);
          }
          return updatedItems;
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      toast.error(`We found some problems when deleting task data.`);
    }
  };
  const handleEditColumn = async (newName) => {
    try {
      await axios.post(`${type}/${id}/`, { name: newName });
      toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} name was updated`);
    } catch (e) {
      console.error(e);
      toast.error(`We found some problems when updating the provider name.`);
    }
  };

  const handleSubmit = async (cardData) => {
    if (!Object.keys(cardData).length) {
      setShowForm(false);
      return;
    }

    try {
      let payload = { name: cardData.title };
      payload = { ...payload };

      if (type !== 'stage') {
        payload = { ...payload, provider: id ? id : null };
      } else {
        payload = { ...payload, provider: filters?.provider, stage: id ? id : null };
      }
      const { data } = await axios.post(`kanban/brand/`, payload);
      setBrands((syncBrands) => [...syncBrands, data]);
    } catch ({ response: { data } }) {
      if (data?.name) toast.error(data?.name[0]);
      else toast.error(data?.message);
    }
    setShowForm(false);
  };

  const throttleHandleScroll = useCallback((event) => {
    const target = event.target;
    const scrollPosition = target.scrollTop;
    const scrollHeight = target.scrollHeight - target.clientHeight;
    const scrollPercentage = (scrollPosition / scrollHeight) * 100;

    if (!requestSent && scrollPercentage > 80) {
      onLoadMore(setRequestSent, column?.id);
    }
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);
    return clearTimeout(timeout);
  }, [showForm]);

  return (
    <div className={classNames('kanban-column', { 'form-added': showForm })}>
      <div className="kanban-column-header">
        <div className="fs-0 mb-0 p-0 d-flex">
          <div
            className=" "
            contentEditable={id > 0}
            suppressContentEditableWarning={true} // Suppress the contenteditable warning
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                e.currentTarget.blur(); // Trigger blur event
                e.preventDefault();
              } else if (e.key === 'Enter') {
                e.preventDefault();
                e.currentTarget.blur(); // Trigger blur event
              }
            }}
            onBlur={(e) => {
              const newName = e.target.innerText.trim();
              if (newName !== name) {
                handleEditColumn(newName);
              }
            }}
          >
            {name}
          </div>
          <div className={'ms-2'}>{column?.count ? <span className="text-500">({column?.count})</span> : null}</div>
        </div>

        {sort_position >= 0 && type !== 'stage' && (
          <Dropdown align="end" className="font-sans-serif btn-reveal-trigger">
            <Dropdown.Toggle variant="reveal" size="sm" className="py-0 px-2">
              <FontAwesomeIcon icon="ellipsis-h" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-0">
              <Dropdown.Item
                onClick={(e) => {
                  preventDefault(e);
                  handleRemoveColumn();
                }}
                className="text-danger"
              >
                Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      <Droppable droppableId={`${column?.id}`} type="KANBAN">
        {(provided) => (
          <>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`container-${id}`}
              className="kanban-items-container scrollbar"
              onScroll={throttleHandleScroll}
            >
              {column?.brands?.map((brand, index) => {
                const key = `${brand?.provider}-${brand?.stage}-${brand?.sort_position}-${brand?.id}`;
                return (
                  <BrandCard key={key} brand={brand} index={index} setSelectedTask={setSelectedTask} type={type} />
                );
              })}
              {requestSent && (
                <div className="kanban-item">
                  <Card className="kanban-item-card hover-actions-trigger">
                    <Card.Body>
                      <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} />
                      </Placeholder>
                    </Card.Body>
                  </Card>
                </div>
              )}
              <AddAnotherForm onSubmit={handleSubmit} type="card" showForm={showForm} setShowForm={setShowForm} />

              {provided.placeholder}

              <div ref={formViewRef}></div>
            </div>

            {!showForm && (
              <div className="kanban-column-footer">
                <IconButton
                  size="sm"
                  variant="link"
                  className="d-block w-100 btn-add-card text-decoration-none text-600"
                  icon="plus"
                  iconClassName="me-2"
                  onClick={() => setShowForm(true)}
                >
                  Add Brand
                </IconButton>
              </div>
            )}
          </>
        )}
      </Droppable>
    </div>
  );
};

KanbanColumn.propTypes = {
  column: PropTypes.any,
  filters: PropTypes.any,
  type: PropTypes.any,
  setIsLoading: PropTypes.func,
  onLoadMore: PropTypes.func
};
