/*eslint camelcase: ["error", {allow: ["order_count","created_at","order_revenue","average_order_value","last_order_placed_at",]}]*/

import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import CurrencyFormat from 'react-currency-format';
import { useFilters } from '../../hooks/useFilters';
import { CanceledError } from 'axios';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import { FilterRow } from '../../components/FilterRow';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import { formatDatetime, STATE_LIST } from '../../services/utils';
import { ADMIN_OR_BRAND } from '../../services/constants';
import AppContext from '../../context/Context';
import YesNoEmptyField from '../../components/form/YesNoEmptyField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const CustomerList = () => {
  useStaticTitle('Customers');
  const navigate = useNavigate();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();
  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'name',
      Header: 'Customer Name',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { name, id } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal font-monospace" to={`/customer/${id}/`}>
              {name}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'is_blacklisted',
      Header: 'Blacklisted',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="fs--1 mb-0 text-dark fw-normal" to={`/customer/${id}`}>
            <FontAwesomeIcon
              icon="fa-user"
              className={classNames('text-success fs-0', {
                'text-success': !rowData.row.original.is_blacklisted,
                'text-danger': rowData.row.original.is_blacklisted
              })}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'order_count',
      Header: 'Orders',
      Cell: (rowData) => {
        const { order_count, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal text-decoration-none" to={`/customer/${id}/`}>
            {order_count}
          </Link>
        );
      }
    },
    {
      accessor: 'order_revenue',
      Header: 'Amount Spent',
      Cell: (rowData) => {
        const { order_revenue, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/customer/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={order_revenue || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'order_revenue_average',
      Header: 'Average Order Value',
      Cell: (rowData) => {
        const { order_revenue_average, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/customer/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={order_revenue_average || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'order_first_created_at',
      Header: 'First Order Placed at',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { order_first_created_at, id } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal font-monospace" to={`/customer/${id}/`}>
              {formatDatetime(order_first_created_at)}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'order_last_created_at',
      Header: 'Last Order Placed At',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { order_last_created_at, id } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal font-monospace" to={`/customer/${id}/`}>
              {formatDatetime(order_last_created_at)}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'location',
      disableSortBy: true,
      Header: 'Location',
      Cell: (rowData) => {
        const { location, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/customer/${id}/`}>
            {location}
          </Link>
        );
      }
    }
  ];
  const filterComponent = (
    <>
      {ADMIN_OR_BRAND.includes(profile?.type) && (
        <FilterRow name="portfolios">
          <AsyncSelectField
            isMulti={true}
            label="Portfolios"
            name="portfolios"
            urlEndpoint={'/dropdown/portfolio/'}
            className="mb-0"
          />
        </FilterRow>
      )}
      <FilterRow name="brand">
        <AsyncSelectField label="Brand" name="brand" urlEndpoint={'/dropdown/brand/'} className="mb-0" />
      </FilterRow>
      <FilterRow name="state">
        <SimpleSelectField options={STATE_LIST} label="State" name="state" className="mb-0" />
      </FilterRow>
      <YesNoEmptyField
        yesLabel="Blacklisted"
        noLabel="Not Blacklisted"
        label="Blacklisted"
        name="is_blacklisted"
        className="mb-0 mt-3"
      />
    </>
  );
  const rowOptions = [
    {
      label: 'View',
      icon: 'eye',
      onClick: (obj) => navigate(`/customer/${obj.id}/`),
      hasPermission: () => {
        return true;
      }
    }
  ];

  const loadCustomers = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`customer/`, { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      // only hide loading if the error isn't a cancel to a new request
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadCustomers().catch(console.error);
    return abortRequest;
  }, [filters]);
  const actions = [].filter((action) => action.hasPermission());

  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Customers'}
        name="customer"
        filterComponent={filterComponent}
        actions={actions}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
        module="customer"
      />
    </>
  );
};
export default CustomerList;
