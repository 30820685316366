/*eslint camelcase: ["error", {allow: ["utm_source","utm_id","utm_term","utm_campaign","utm_content","utm_medium"]}]*/
const UTM_FIELDS = {
  utm_source: 'UTM Source',
  utm_id: 'UTM ID',
  utm_term: 'UTM Term',
  utm_medium: 'UTM Medium',
  utm_campaign: 'UTM Campaign',
  utm_content: 'UTM Content'
};
export const getUTMLabel = (utmField) => {
  if (utmField in UTM_FIELDS) {
    return UTM_FIELDS[utmField];
  }
  return utmField;
};
