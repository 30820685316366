import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const RemoveFilterButton = ({ names = [], onClick, className }) => {
  const { setFieldValue } = useFormikContext();
  const normalizedNames = _.isArray(names) ? names : [names];

  const handleClick = () => {
    normalizedNames.forEach((element) => setFieldValue(element, undefined));
    onClick && onClick();
  };

  return (
    <button
      type="button"
      style={{ height: 36, width: 36 }}
      className={`btn-close ms-2 p-0 ${className} flex-shrink-0`}
      aria-label="Close"
      onClick={handleClick}
    ></button>
  );
};

RemoveFilterButton.propTypes = {
  name: PropTypes.any,
  names: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default RemoveFilterButton;
