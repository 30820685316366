import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatDatetime } from '../../../services/utils';

export const MarketingWidget = ({ order }) => {
  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-5">
      <ToggleCard title="Marketing" open={true}>
        <Card.Body className="bg-light">
          <div className="d-flex align-items-center">
            {order?.allow_send_email ? (
              <span className="fa-layers fa-fw me-1">
                <FontAwesomeIcon icon={faCircle} className="text-success" />
                <FontAwesomeIcon icon={faCheck} inverse transform="shrink-6" />
              </span>
            ) : (
              <span className="fa-layers fa-fw me-1">
                <FontAwesomeIcon icon={faCircle} className="text-danger" />
                <FontAwesomeIcon icon={faTimes} inverse transform="shrink-6" />
              </span>
            )}
            {order?.allow_send_email ? 'Email Subscribed' : 'Email not subscribed'}
          </div>
          {order?.allow_send_email_updated_at ? (
            <div className="mt-2 ms-1">
              <strong className="fw-bold">
                <small>Last Marketing Status Update</small>
              </strong>
              <div>{formatDatetime(order.allow_send_email_updated_at)}</div>
            </div>
          ) : null}
        </Card.Body>
      </ToggleCard>
    </Card>
  );
};

MarketingWidget.propTypes = {
  order: PropTypes.any
};
