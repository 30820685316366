import { formatDatetime, RECOVERY_STATUS_RECOVERED } from '../../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';

export const AbandonedCheckoutWidget = ({ order }) => {
  if (order?.recovery_status !== RECOVERY_STATUS_RECOVERED) {
    return null;
  }
  return (
    <div className="alert alert-success d-flex align-items-start">
      <FontAwesomeIcon icon="check-circle" className="text-success fs-2 me-3" />
      <div className="flex-1">
        <h5 className="mb-3">Abandoned Checkout Recovered</h5>
        {order?.recovery_at ? (
          <p>This order was created from a recovered abandoned checkout on {formatDatetime(order?.recovery_at)}.</p>
        ) : null}
      </div>
    </div>
  );
};

AbandonedCheckoutWidget.propTypes = {
  order: PropTypes.any
};
