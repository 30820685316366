/*eslint camelcase: ["error", {allow: ["shipping_country","customer_birthdate","action_req","engraving_lines","checkout_url","new_order","customer_email","brand_list","first_name", "tracking_url", "item_id", "order_id","order_number","status","customer_name","customer_phone_number","shipping_address1","shipping_city","shipping_zip","shipping_state","source_meta_data","tracking_number_url","shipping_cost","tax_rate","tax_amount","internal_tracking_number","internal_tracking_url",]}]*/
import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { FieldArray, Form, Formik } from 'formik';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import TextField from '../../components/form/TextField';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { asFormData, formatPhoneNumber, moneyFormat, TRACKING_COMPANIES } from '../../services/utils';
import { SubmitFooter } from '../../components/form/SubmitFooter';
import { Thumbnail } from '../../components/common/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import itemNotFound from '../../assets/img/item-not-found.jpg';
import ToggleCard from '../../components/form/ToggleCard';
import classNames from 'classnames';
import { LoadingView } from '../../components/LoadingView';
import { useAxios } from '../../hooks/useAxios';
import { useDocumentAction, useDocumentTitle } from '../../hooks/usePageTitle';
import _ from 'lodash';
import { InternalNote } from '../../components/InternalNote';
import PhoneField from '../../components/form/PhoneField';
import { COUNTRY_LIST, preventDefault } from '../../utils';
import IconButton from '../../components/common/IconButton';
import AppContext from '../../context/Context';
import { CustomEmailModal } from './CustomEmailModal';
import {
  USER_PERMISSION_BRAND,
  USER_PERMISSION_ORDER_EDIT,
  USER_PERMISSION_PRODUCT,
  USER_TYPE_ADMIN,
  USER_TYPE_AFFILIATE,
  USER_TYPE_CUSTOMER_SERVICE
} from '../../services/constants';
import { PaymentMethodWidget } from './widgets/PaymentMethodWidget';
import { AbandonedCheckoutWidget } from './widgets/AbandonedCheckoutWidget';
import { TotalWidget } from './widgets/TotalWidget';
import { MetadataWidget } from './widgets/MetadataWidget';
import { MarketingWidget } from './widgets/MarketingWidget';
import { GiftMessageWidget } from './widgets/GiftMessageWidget';
import DatePickerField from '../../components/form/DatePickerField';
import moment from 'moment/moment';
import IntegerField from '../../components/form/IntegerField';
import { RiskInsightsWidget } from '../../components/RiskInsightWidget';
import { OrderStatusIndicator } from '../../components/OrderStatusIndicator';
import OrderStatusHistory from './OrderStatusHistory';
import { GAEventWidget } from './widgets/GAEventWidget';
import { InternalEventWidget } from './widgets/InternalEventWidget';
import { ChargesWidget } from './widgets/ChargesWidget';

const OrderCreateUpdate = () => {
  const { id } = useParams();

  const { profile } = useContext(AppContext);
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [order, setOrder] = useState(null);

  const [lastUpdate, setLastUpdate] = useState(null);
  const [isShowingEmail, setIsShowingEmail] = useState(false);
  const [showCustomerEmailModal, setShowCustomerEmailModal] = useState(false);
  const [editing, setEditing] = useState({});

  const isEditing = (index) => {
    index = parseInt(index);

    return index in editing ? editing[index] : false;
  };

  const setIsEditing = (index, value) => {
    const payload = {};
    payload[index] = value;
    setEditing({
      ...editing,
      ...payload
    });
  };
  const loadOrderData = async () => {
    try {
      const { data } = await axios.get(`order/${id}/`);
      setOrder(data);
    } catch (e) {
      console.error(e);
    }
  };
  const initialLoad = async () => {
    setIsLoading(true);
    if (id) {
      await loadOrderData();
    }
    setIsLoading(false);
  };
  const statusHistoryPopover = (
    <Popover id="order-status-popover">
      <Popover.Header as="h3">Order Status History</Popover.Header>
      <Popover.Body>
        <OrderStatusHistory id={order?.id} createdAt={order?.created_at} />
      </Popover.Body>
    </Popover>
  );
  useDocumentTitle([['Order', useDocumentAction()], order?.order_number]);

  useEffect(() => {
    initialLoad().catch(console.error);
    return abortRequest;
  }, []);

  const navigate = useNavigate();
  const engravingLinesValidator = Yup.string()
    .nullable()
    .test(
      'max_3_lines',
      () => 'Engraving lines must have a maximum of 3 lines',
      async (value) => {
        if (value && value.trim() !== '') {
          return value.split('\n').length <= 3;
        }
        return true;
      }
    )
    .test(
      'max_100_per_line',
      () => 'Each line has a limit of 20 characters',
      async (value) => {
        if (value && value.trim() !== '') {
          return !value.split('\n').find((line) => line.length > 20);
        }
        return true;
      }
    );
  const itemValidate = {
    quantity: Yup.number().required('Quantity is required').positive('The value must be positive'),
    product: Yup.number().label('Product').required(),
    engraving_lines: engravingLinesValidator
  };

  const validate = {
    customer_name: Yup.string().required().label('Shipping Name'),
    customer_phone_number: Yup.string().required().label('Customer Phone Number'),
    customer_birthdate: Yup.string()
      .label('Customer Birth Date')
      .test('customer_birthdate', function (value) {
        if (!value) {
          return true;
        }
        const { shipping_country } = this.parent;
        const ageLimit = shipping_country === 'US' ? 21 : 18;

        const diff = moment(value, 'YYYY-MM-DD').diff(moment(), 'years');
        if (diff > -ageLimit) {
          return this.createError({
            message: `Must be at least ${ageLimit} years old`,
            path: 'customer_birthdate'
          });
        }
        return true;
      }),
    shipping_address1: Yup.string().required().label('Shipping Address 1'),
    shipping_city: Yup.string().required().label('Shipping City'),
    shipping_zip_code: Yup.string()
      .label('Shipping Zip Code')
      .required()
      .test('conditional-zip-length', 'Invalid Zip Code based on state', function (value) {
        const { shipping_country } = this.parent;
        if (shipping_country === 'US') {
          return value?.length <= 5;
        }
        return true;
      }),
    shipping_state: Yup.string()
      .label('Shipping State')
      .nullable()
      .when('shipping_country', {
        is: 'US', // when shipping_country is 'US' apply the required fun
        then: Yup.string().required('Shipping State is required for US'), // make it required
        otherwise: Yup.string().nullable() // otherwise, just return a nullable string type
      }),
    shipping: Yup.number().label('Shipping').required().nullable(),
    items: Yup.array()
      .of(Yup.object().shape(itemValidate))
      .min(1, 'You must provide at least one product to create/update an order')
  };
  const emailValidation = Yup.string().email().label('Email').nullable();
  const getOrCreateCheckoutURL = async () => {
    const { data } = await axios.post(`order/${id}/get-checkout-url/`);
    toast.success('Checkout URL copied to clipboard', { theme: 'colored' });
    await navigator.clipboard.writeText(data?.checkout_url);
    setOrder({ ...order, checkout_url: data?.checkout_url });
  };

  const forceCreateOrder = async (orderId) => {
    try {
      const response = await axios.post(`order/${orderId}/retailer/force-create-order/`, {
        order_id: orderId
      });
      await initialLoad();
      toast.success(response?.data?.message, { theme: 'colored' });
    } catch ({ response }) {
      toast.error(response?.data?.message, {
        theme: 'colored'
      });
    }
  };

  const forceUpdateFromRetailer = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(`order/${id}/retailer/force-update-from-retailer/`);
      setIsLoading(false);
      toast.success(response?.data?.message, { theme: 'colored' });
    } catch ({ response }) {
      toast.error(response?.data?.message, { theme: 'colored' });
    } finally {
      await loadOrderData();
      setLastUpdate(new Date().getTime());
    }
  };

  const initialValues = id
    ? { ...order }
    : {
        brand: null,
        retailer: null,
        items: [],
        shipping_state: null,
        shipping_country: 'US'
      };

  const isReadOnly =
    (id && !['new_order', 'action_req'].includes(order?.status)) ||
    !profile.permissions.includes(USER_PERMISSION_ORDER_EDIT);
  const hideEmail = id && !isShowingEmail;
  const validationSchema = { ...validate };
  if (!hideEmail) {
    validationSchema['customer_email'] = emailValidation;
  }

  const actions = [
    {
      label: (
        <>
          <FontAwesomeIcon icon="fa-regular fa-copy" className="me-1" />
          Copy Checkout URL
        </>
      ),
      hasPermission: () => profile?.type === USER_TYPE_ADMIN,
      onClick: () => getOrCreateCheckoutURL()
    },
    {
      label: (
        <>
          <FontAwesomeIcon icon="fa-solid fa-share" className="me-1" />
          Synchronize From Retailer
        </>
      ),
      hasPermission: () => profile?.type === USER_TYPE_ADMIN && order?.financial_status !== 'pending' && !isReadOnly,
      onClick: () => forceUpdateFromRetailer()
    },
    {
      label: <>Logs</>,
      hasPermission: () => order?.financial_status === 'paid' && profile?.type !== USER_TYPE_AFFILIATE,
      onClick: () => navigate(`/order/${id}/logs`)
    }
  ].filter((action) => action.hasPermission());

  return (
    <>
      {/* The following block is rendered when we have a saved order, and it only shows basic data about it*/}
      {order && !isLoading && (
        <>
          <div className="card mb-3 animate__animated animate__fadeIn">
            <CustomEmailModal
              params={{ order: id }}
              show={showCustomerEmailModal}
              onHide={() => setShowCustomerEmailModal()}
              onSuccess={(data) => {
                setShowCustomerEmailModal(false);
                setOrder({ ...order, customer_email: data?.customer_email });
                setLastUpdate(new Date().getTime());
                setIsShowingEmail(true);
              }}
            />
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap">
                <h5 className="mb-0">
                  Order Number: &nbsp;
                  <a
                    href={`#copy-code-${order.order_number}`}
                    className="text-decoration-none text-600"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      await navigator.clipboard.writeText(order.order_number);
                      toast.success('Order number copied to clipboard', { theme: 'colored' });
                    }}
                  >
                    <span className="order-number">{order.order_number}</span> &nbsp;
                    <FontAwesomeIcon icon="copy" size="sm" />
                  </a>
                </h5>

                {order && (
                  <div className="d-flex align-items-center">
                    {(profile?.type === USER_TYPE_ADMIN || profile?.type === USER_TYPE_CUSTOMER_SERVICE) &&
                      !order?.is_imported && (
                        <>
                          {!order?.retailer_order_id && order?.financial_status === 'paid' && (
                            <IconButton
                              iconClassName="fs--2 me-1"
                              variant="falcon-default"
                              size="sm"
                              icon="sync"
                              onClick={() => forceCreateOrder(id)}
                            >
                              Force send order to Retailer
                            </IconButton>
                          )}
                          {!order?.is_imported && order?.retailer_order_id && order?.financial_status !== 'pending' && (
                            <a
                              target="_blank"
                              href={order?.retailer_order_url}
                              rel="noreferrer"
                              className="open-order-on-3jms"
                            >
                              <small>Open order on Retailer</small>
                            </a>
                          )}
                        </>
                      )}
                    {!order?.is_imported && actions?.length > 0 ? (
                      <Dropdown align="start" autoClose={true} className="ms-3">
                        <Dropdown.Toggle size="sm" variant="link" data-boundary="window" />
                        <Dropdown.Menu>
                          {actions.map((action, index) => (
                            <Dropdown.Item key={`action-${index}`} onClick={action?.onClick}>
                              {action?.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center">
                <strong className="me-2">Status: </strong>
                {!['canceled'].includes(order.status) && !order?.is_imported && profile?.type === USER_TYPE_ADMIN ? (
                  <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={statusHistoryPopover}>
                    <Button variant="link" className="p-0">
                      <OrderStatusIndicator substatus={order?.substatus_label} status={order?.status_label} />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <Button variant="link" className="p-0">
                    <OrderStatusIndicator substatus={order?.substatus_label} status={order?.status_label} />
                  </Button>
                )}
              </div>
              <hr />
              <div className="row">
                <div className="col-12 col-sm-4 mb-3">
                  <strong>Brand:</strong>
                  {profile?.permissions?.includes(USER_PERMISSION_BRAND) ? (
                    <Link to={`/brand/${order.brand}/`} target="_blank">
                      <div>{order.brand_name}</div>
                    </Link>
                  ) : (
                    <div>{order.brand_name}</div>
                  )}
                </div>
                <div className="col-12 col-sm-4 mb-3">
                  <strong>Retailer:</strong>
                  <div>{order.retailer_name}</div>
                </div>
                <div className="col-12 col-sm-4 mb-3">
                  <strong>Tracking Number:</strong>
                  <div>
                    {order?.internal_tracking_url ? (
                      <a href={order?.internal_tracking_url} target={'_blank'} rel="noreferrer">
                        {order?.internal_tracking_number ? order?.internal_tracking_number : '-'}
                      </a>
                    ) : (
                      <>{order?.internal_tracking_number ? order?.internal_tracking_number : '-'}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Formik
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchema)}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const payload = asFormData({
            ...values,
            brand: values?.brand,
            retailer: values?.retailer,
            items: values?.items?.map((item) => item)
          });
          // that does not solve at all, we need some kind of flag to validate email on API and here...
          let removeEmail = hideEmail;
          if (id && order?.customer_email !== values?.customer_email) {
            removeEmail = false;
          }
          if (removeEmail) {
            payload.delete('customer_email');
          }
          payload.delete('internal_note');
          try {
            const { data } = await axios.post(id ? `/order/${id}/` : '/order/', payload, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            toast.success(
              <>
                Order <Link to={`/order/${data.id}`}>{data?.order_number}</Link> was {id ? 'updated' : 'created'}{' '}
                successfully
              </>
            );
            navigate(`/order/`);
          } catch ({ response }) {
            if (response?.data?.items) {
              // that means we have errors on items, so we should open them all. Reduce just to create an index=true
              // dict
              setEditing(
                response?.data?.items.reduce((prev, _, index) => {
                  prev[index] = true;
                  return prev;
                }, {})
              );
            }
            setErrors(response?.data);
            if (response?.data?.message) {
              toast.error(response?.data?.message, {
                theme: 'colored'
              });
            }
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, setTouched, setFieldValue, isSubmitting }) => {
          useEffect(() => {
            if (values?.customer_phone_number) {
              setFieldValue(
                'customer_phone_number',
                formatPhoneNumber(values?.customer_phone_number, values?.allow_gb ? 'GB' : 'US')
              );
            }
          }, [values?.customer_phone_number]);
          return (
            <>
              <Form
                className={classNames('', {
                  'd-none': isLoading || (id && !order)
                })}
              >
                <div className="g-3">
                  <div className="animate__animated animate__fadeIn animate__delay-1">
                    {/* this card is only shown when we are creating a new order since user should not update status, retailer, etc..*/}
                    {!order && (
                      <Card className="mb-3 animate__animated animate__fadeIn">
                        <FalconCardHeader title="Create Order" />
                        <Card.Body className="bg-light">
                          <div className="row">
                            <div className="col-sm-12">
                              <Row className="mb-3 g-3">
                                <Col lg="6">
                                  <AsyncSelectField
                                    label="Brand"
                                    name="brand"
                                    required={!isReadOnly}
                                    disabled={isSubmitting || isReadOnly}
                                    onChangeValue={() => {
                                      setFieldValue('retailer', null);
                                      setFieldValue('items', []);
                                      setFieldValue('shipping_state', null);
                                      setFieldValue('shipping', null);
                                    }}
                                    urlEndpoint="/dropdown/brand/"
                                  />
                                  {values?.brand === null && (
                                    <div className="text-warning">
                                      <small>You must select one brand to see the retailers list.</small>
                                    </div>
                                  )}
                                </Col>
                                <Col lg="6">
                                  {values?.brand !== null && (
                                    <AsyncSelectField
                                      params={{ brand: values?.brand }}
                                      label="Retailer"
                                      name="retailer"
                                      required={!isReadOnly}
                                      onChangeValue={() => {
                                        setFieldValue('shipping_state', null);
                                        setFieldValue('shipping', null);
                                      }}
                                      disabled={isSubmitting || isReadOnly || !values?.brand}
                                      urlEndpoint={'/dropdown/retailer/'}
                                      isMulti={false}
                                    />
                                  )}
                                </Col>
                              </Row>
                              {id && values?.tracking_company && (
                                <Row className="mb-3 g-3">
                                  <Col lg="5">
                                    <SimpleSelectField
                                      label="Tracking Company"
                                      name="tracking_company"
                                      options={TRACKING_COMPANIES}
                                      disabled={isSubmitting || isReadOnly}
                                      placeholder="Select..."
                                    />
                                  </Col>
                                  <Col lg="3">
                                    <TextField
                                      label="Tracking Number"
                                      name="tracking_number"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </Col>
                                  <Col lg="4">
                                    <TextField
                                      label="Tracking Url"
                                      name="tracking_url"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </Col>
                                </Row>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    )}
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="card mb-3 animate__animated animate__fadeIn animate__delay-1">
                          <ToggleCard title="Customer Information" open={true}>
                            <Card.Body className="bg-light">
                              <div className="d-flex w-100">
                                <div className="row">
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Shipping Name"
                                      name="customer_name"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <div className="mb-3">
                                      <TextField
                                        label="Email"
                                        name="customer_email"
                                        data-lpignore="true"
                                        autoComplete="new-password"
                                        required={
                                          (!isReadOnly && !hideEmail) ||
                                          order?.customer_email !== values?.customer_email
                                        }
                                        disabled={isSubmitting || isReadOnly}
                                      />
                                      {hideEmail && values?.customer_email ? (
                                        <button
                                          type="button"
                                          className="ms-auto btn btn-outline-primary btn-sm"
                                          onClick={(e) => {
                                            preventDefault(e);
                                            setShowCustomerEmailModal(true);
                                          }}
                                        >
                                          Show Email
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <DatePickerField
                                      showTimeSelect={false}
                                      dateFormat={'MM/dd/yyyy'}
                                      inputFormat="YYYY-MM-DD"
                                      label="Birth Date"
                                      required={!order?.is_imported}
                                      name="customer_birthdate"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <PhoneField
                                      label="Phone Number"
                                      country={values?.shipping_country}
                                      name="customer_phone_number"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Shipping Address 1"
                                      name="shipping_address1"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Shipping Address 2"
                                      name="shipping_address2"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Shipping City"
                                      name="shipping_city"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <AsyncSelectField
                                      label="Shipping State"
                                      name="shipping_state"
                                      params={{
                                        retailer: values?.retailer,
                                        country: values?.shipping_country
                                      }}
                                      onChangeValue={() => {
                                        setFieldValue('shipping', null);
                                      }}
                                      required={!isReadOnly && values?.shipping_country === 'US'}
                                      disabled={
                                        isSubmitting ||
                                        isReadOnly ||
                                        !values?.retailer ||
                                        values?.shipping_country === 'GB'
                                      }
                                      placeholder="Select..."
                                      urlEndpoint="/dropdown/state/"
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Shipping Zip Code"
                                      name="shipping_zip_code"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField label="Company" name="company" disabled={isSubmitting || isReadOnly} />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <AsyncSelectField
                                      label="Shipping"
                                      name="shipping"
                                      params={{
                                        state: values?.shipping_state,
                                        retailer: values?.retailer,
                                        order: values?.id,
                                        country: values?.shipping_country
                                      }}
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly}
                                      placeholder="Select..."
                                      urlEndpoint={'/dropdown/shipping/'}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <SimpleSelectField
                                      options={COUNTRY_LIST}
                                      label="Shipping Country"
                                      name="shipping_country"
                                      required={!isReadOnly}
                                      disabled={isSubmitting || isReadOnly || !!order?.id}
                                      onChangeValue={() => {
                                        setFieldValue('shipping_state', null);
                                        setFieldValue('shipping', undefined);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </ToggleCard>
                        </div>
                        <Card className="mb-3 animate__animated animate__fadeIn animate__delay-2">
                          <ToggleCard
                            title="Billing Information"
                            open={
                              !!(
                                values?.billing_name ||
                                values?.billing_address1 ||
                                values?.billing_address2 ||
                                values?.billing_company ||
                                values?.billing_city ||
                                values?.billing_zip ||
                                values?.billing_state
                              )
                            }
                          >
                            <Card.Body className="bg-light">
                              <div className="d-flex w-100">
                                <div className="row">
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Billing Name"
                                      name="billing_name"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Billing Address 1"
                                      name="billing_address1"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Billing Address 2"
                                      name="billing_address2"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Billing City"
                                      name="billing_city"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  {values?.billing_country !== 'GB' ? (
                                    <div className="col-12 col-sm-6 col-xxl-4">
                                      <AsyncSelectField
                                        label="Billing State"
                                        name="billing_state"
                                        params={{
                                          retailer: values?.retailer,
                                          country: values?.billing_country
                                        }}
                                        required={!isReadOnly && values?.billing_country === 'US'}
                                        disabled={
                                          isSubmitting ||
                                          isReadOnly ||
                                          !values?.retailer ||
                                          values?.billing_country === 'GB'
                                        }
                                        placeholder="Select..."
                                        urlEndpoint="/dropdown/state/"
                                      />
                                    </div>
                                  ) : null}
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <SimpleSelectField
                                      label="Billing Country"
                                      options={[
                                        {
                                          value: null,
                                          label: 'Select Billing Country'
                                        },
                                        ...COUNTRY_LIST
                                      ]}
                                      name="billing_country"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-xxl-4">
                                    <TextField
                                      label="Billing Zipcode"
                                      name="billing_zip_code"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </ToggleCard>
                        </Card>
                        {values?.retailer !== null && (
                          <Card className="mb-3 animate__animated animate__fadeIn animate__delay-3">
                            <ToggleCard title="Items" open={true}>
                              <Card.Body className="bg-light">
                                <FieldArray
                                  name="items"
                                  label={'Order Items'}
                                  render={({ remove, push }) => {
                                    return (
                                      <>
                                        {_.isString(errors?.items) ? (
                                          <div className="mb-3">
                                            <small className="text-danger">{errors?.items}</small>
                                          </div>
                                        ) : null}
                                        {errors?.items?.non_field_errors ? (
                                          <div className="mb-3">
                                            <small className="text-danger">{errors?.items?.non_field_errors}</small>
                                          </div>
                                        ) : null}
                                        <div>
                                          {values?.items?.map((item, index) => {
                                            return (
                                              <div key={`item-${index}`} className="order-item pb-3 mb-3">
                                                {/* view screen */}
                                                {!isEditing(index) && (
                                                  <div className={'row'}>
                                                    <div className="col-12 col-sm-8 d-flex">
                                                      {/* thumbnail */}
                                                      <div className="pe-4">
                                                        <div className="position-relative d-table">
                                                          <Thumbnail
                                                            className="mt-1 text-center"
                                                            src={item?.image ? item?.image : itemNotFound}
                                                          />
                                                          <span
                                                            className={
                                                              'position-absolute top-0 start-100 translate-middle' +
                                                              ' badge rounded-pill bg-secondary'
                                                            }
                                                          >
                                                            {item.quantity}
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div className="flex-fill">
                                                        {profile.permissions.includes(USER_PERMISSION_PRODUCT) ? (
                                                          <Link
                                                            style={{ height: 48, width: 48 }}
                                                            target="_blank"
                                                            to={`/product/${item.product}`}
                                                          >
                                                            {item.name}
                                                          </Link>
                                                        ) : (
                                                          item.name
                                                        )}
                                                        <div>
                                                          <small>
                                                            <strong>SKU: </strong>
                                                            {item.sku}
                                                          </small>
                                                        </div>
                                                        {item.engraving_lines ? (
                                                          <div
                                                            style={{
                                                              wordBreak: 'break-all'
                                                            }}
                                                          >
                                                            <small>
                                                              <strong>Engraving Lines: </strong>
                                                              {item.engraving_lines.split('\n').map((line, index) => (
                                                                <div key={`engraving-${index}`}>{line}</div>
                                                              ))}
                                                            </small>
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className={
                                                        'col-12 col-sm-4 justify-content-end flex-column d-flex'
                                                      }
                                                    >
                                                      <div
                                                        className={
                                                          'd-flex mt-3 mt-sm-0 justify-content-end ' +
                                                          'justify-content-sm-evenly'
                                                        }
                                                      >
                                                        <>
                                                          <div className="col-auto col-sm-6 text-end">
                                                            <span>
                                                              {moneyFormat(item.price)} &times; {item.quantity}
                                                            </span>
                                                          </div>
                                                          <div className="col-auto text-end col-sm-6">
                                                            <span>
                                                              {item.subtotal
                                                                ? moneyFormat(item.subtotal)
                                                                : moneyFormat(item.price * item.quantity)}
                                                            </span>
                                                            {item.discount && item.discount !== 0 ? (
                                                              <div className="text-end">
                                                                <small className="text-danger">
                                                                  -{moneyFormat(item.discount)}
                                                                </small>
                                                              </div>
                                                            ) : null}
                                                          </div>
                                                        </>
                                                      </div>
                                                      <div className="mt-3 ms-auto">
                                                        {!isReadOnly && (
                                                          <>
                                                            <button
                                                              disabled={isSubmitting || isReadOnly}
                                                              className="btn btn-outline-secondary btn-sm me-1"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setIsEditing(index, true);
                                                              }}
                                                            >
                                                              <a href={`#edit-${index}`}>
                                                                <FontAwesomeIcon icon="pencil-alt" />
                                                              </a>
                                                            </button>
                                                            <button
                                                              className="btn btn-outline-danger btn-sm"
                                                              disabled={isSubmitting || isReadOnly}
                                                              onClick={() => remove(index)}
                                                              type="button"
                                                            >
                                                              <FontAwesomeIcon icon="trash" />
                                                            </button>
                                                          </>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                                {/* edit screen */}
                                                <div
                                                  className={classNames('d-flex', {
                                                    'd-none': !isEditing(index)
                                                  })}
                                                >
                                                  <div className="col-auto pe-3">
                                                    <Thumbnail
                                                      className="mt-1 text-center"
                                                      src={item?.image ? item?.image : itemNotFound}
                                                    />
                                                  </div>
                                                  <div className="flex-fill pe-3">
                                                    <AsyncSelectField
                                                      onChangeValue={(e) => {
                                                        setFieldValue(`items[${index}]`, {
                                                          ...item,
                                                          ...e,
                                                          product: e.value
                                                        });
                                                      }}
                                                      key={values?.retailer}
                                                      params={{
                                                        brand: values?.brand,
                                                        retailer: values?.retailer
                                                      }}
                                                      label="Product"
                                                      name={`items[${index}].product`}
                                                      required={!isReadOnly}
                                                      disabled={isSubmitting || isReadOnly}
                                                      placeholder="Select..."
                                                      urlEndpoint={'/dropdown/product-by-brand-and-retailer/'}
                                                    />
                                                    <IntegerField
                                                      label="Item Quantity"
                                                      name={`items[${index}].quantity`}
                                                      disabled={isSubmitting || isReadOnly}
                                                      required={!isReadOnly}
                                                      type="number"
                                                      min={1}
                                                    />
                                                    <TextField
                                                      label="Engraving Lines"
                                                      name={`items[${index}].engraving_lines`}
                                                      disabled={isSubmitting || isReadOnly}
                                                      type={'textarea'}
                                                      rows={3}
                                                    />
                                                    <div className="d-flex justify-content-end">
                                                      <a
                                                        href={`#itms-save-${index}`}
                                                        className="btn btn-outline-primary me-1"
                                                        onClick={async (e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();

                                                          const validator = Yup.object(itemValidate);

                                                          setTouched({
                                                            items: values.items.map(() => ({
                                                              quantity: true,
                                                              product: true,
                                                              engraving_lines: true
                                                            }))
                                                          });
                                                          try {
                                                            await validator.validate(item);
                                                            setIsEditing(index, false);
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        }}
                                                      >
                                                        Save
                                                      </a>
                                                      <button
                                                        className="btn btn-outline-danger"
                                                        disabled={isSubmitting || isReadOnly}
                                                        onClick={() => remove(index)}
                                                        type={'button'}
                                                      >
                                                        <FontAwesomeIcon icon="trash" /> Remove
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        {!isReadOnly && (
                                          <button
                                            type="button"
                                            className="btn btn-outline-primary"
                                            disabled={isSubmitting || isReadOnly}
                                            onClick={() => {
                                              setIsEditing(values?.items.length, true);
                                              push({});
                                            }}
                                          >
                                            Add Item
                                          </button>
                                        )}
                                      </>
                                    );
                                  }}
                                />
                              </Card.Body>
                            </ToggleCard>
                          </Card>
                        )}

                        {id && (
                          <Card className="mb-3 animate__animated animate__fadeIn animate__delay-4">
                            <ToggleCard title="Notes" open={true}>
                              <Card.Body className="bg-light">
                                <InternalNote lastUpdate={lastUpdate} params={{ order: id }} />
                              </Card.Body>
                            </ToggleCard>
                          </Card>
                        )}
                      </div>
                      <div className="col-lg-4">
                        <div className="sticky-sidebar">
                          {order && !isLoading && (
                            <>
                              <TotalWidget order={order} />
                              {!order?.is_imported && (
                                <>
                                  <AbandonedCheckoutWidget order={order} />

                                  <RiskInsightsWidget
                                    riskScore={order?.outcome?.risk_score}
                                    sellerMessage={order?.outcome?.seller_message}
                                  />

                                  <MarketingWidget order={order} />

                                  <PaymentMethodWidget order={order} />
                                  {order && order?.charges?.length > 0 && <ChargesWidget order={order} />}
                                </>
                              )}
                            </>
                          )}

                          <GiftMessageWidget disabled={isReadOnly} order={order} id={id} />

                          <MetadataWidget order={order} />

                          <GAEventWidget />

                          <InternalEventWidget />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isReadOnly && <SubmitFooter backUrl={'/order/'} isSubmitting={isSubmitting} />}
              </Form>
              {isLoading && (
                <div className="py-3 animate__animated animate__fadeIn">
                  <LoadingView width={1} />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default OrderCreateUpdate;
