import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import SoftBadge from './common/SoftBadge';
import { Loader } from '@mantine/core';

const PaymentStatusBadge = ({ paymentStatus = null }) => {
  const badgeProps = (() => {
    switch (paymentStatus) {
      case 'Transaction Succeeded':
        return { bg: 'success', text: 'Paid', icon: 'check' };

      case 'Transaction Declined':
        return { bg: 'danger', text: 'Declined', icon: 'ban' };

      case 'Transaction Refund':
        return { bg: 'warning', text: 'Refunded', icon: 'hand-holding-usd' };

      case 'Transaction Partially Refund':
        return { bg: 'warning', text: 'Partially Refunded', icon: 'hand-holding-usd' };

      case null:
        return { bg: 'warning', text: 'Pending', icon: null, isPending: true };

      default:
        return null;
    }
  })();

  if (!badgeProps) return null;
  return (
    <>
      <SoftBadge pill bg={badgeProps.bg} className="fs--1 px-1 py-1">
        {badgeProps.isPending ? (
          <>
            <Loader size="xs" />
            &nbsp;{badgeProps.text}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={badgeProps.icon} transform="shrink-2" />
            &nbsp;{badgeProps.text}
          </>
        )}
      </SoftBadge>
    </>
  );
};

export default PaymentStatusBadge;

PaymentStatusBadge.propTypes = {
  paymentStatus: PropTypes.string
};
