/*eslint camelcase: ["error", {allow: ["customer_birthdate","action_req","engraving_lines","checkout_url","new_order","customer_email","brand_list","first_name", "tracking_url", "item_id", "order_id","order_number","customer_name","customer_phone_number","shipping_address1","shipping_city","shipping_zip","shipping_state","source_meta_data","tracking_number_url","shipping_cost","tax_rate","tax_amount",]}]*/
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { useDocumentTitle } from '../../hooks/usePageTitle';
import { navigateToError } from '../../utils';
import { LogListView } from '../../components/LogListView';

export const OrderLogs = () => {
  const { id } = useParams();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [log, setLog] = useState(null);
  const navigate = useNavigate();
  const loadOrderData = async () => {
    try {
      const { data } = await axios.get(`order/${id}/logs/`);
      setLog(data);
    } catch (e) {
      navigateToError(e, navigate);
    }
  };
  const initialLoad = async () => {
    setIsLoading(true);
    await loadOrderData();
    setIsLoading(false);
  };

  useDocumentTitle([['Order', 'Logs'], log?.order_number]);

  useEffect(() => {
    initialLoad().catch(console.error);
    return abortRequest;
  }, []);

  return <LogListView title="Order Logs" log={log} isLoading={isLoading} backUrl={`/order/${id}/`} />;
};

export default OrderLogs;
