/*eslint camelcase: ["error", {allow: ["user_id", "order_id", "num_pages", "is_public"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination, Timeline } from '@mantine/core';

import Avatar from './common/Avatar';
import avatar from '../assets/img/team/avatar.png';
import threejmsLogo from '../assets/img/team/logo-3jms.png';
import { formatDatetime } from '../services/utils';
import { CanceledError } from 'axios';
import { useAxios } from '../hooks/useAxios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useFilters } from '../hooks/useFilters';

import LoadingButton from './common/LoadingButton';
import { chunkString } from '../utils';
import { LoadingView } from './LoadingView';
import AppContext from '../context/Context';
import { ALLOWED_TAGS, USER_PERMISSION_ACCOUNT, USER_TYPE_ADMIN } from '../services/constants';
import * as DOMPurify from 'dompurify';
import { QuillInput } from './form/QuillInput';
import { Badge, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SwitchInput } from './form/CheckboxField';

// You may ask: why we need that? So, any dict is different of another in JS, so that is why the use effect is always
// triggered
const getMapValues = (map) => Object.keys(map).map((m) => (m in map ? map[m] : null));

const InternalNoteEditor = ({ disabled, isSubmitting, canSetPublic, onSave, onCancel, initialData }) => {
  const [note, setNote] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const handleSave = () => {
    onSave && onSave({ isPublic, note });
    setNote('');
    setIsPublic(false);
  };

  const handleCancel = () => {
    onCancel && onCancel();
    setNote('');
    setIsPublic(false);
  };

  useEffect(() => {
    setNote(initialData?.note || '');
    setIsPublic(initialData?.isPublic || false);
  }, [initialData]);

  return (
    <div className="ms-3">
      <QuillInput
        name="note"
        disabled={isSubmitting || disabled}
        value={note || ''}
        setValue={setNote}
        autoComplete="off"
      />
      <div className="d-flex justify-content-between mt-2">
        {canSetPublic && (
          <>
            <SwitchInput
              onChange={(e) => setIsPublic(e.target.value)}
              elementId="is_public"
              checked={isPublic}
              label="Public"
            />
          </>
        )}
        <div className="d-flex justify-content-end gap-2">
          {onCancel && (
            <LoadingButton
              variant="falcon-primary"
              className="mb-1"
              onClick={handleCancel}
              id={`id_note_cancel`}
              type={'button'}
              disabled={isSubmitting}
            >
              Cancel
            </LoadingButton>
          )}
          <LoadingButton
            variant="falcon-primary"
            className="mb-1"
            onClick={handleSave}
            id={`id_note_save`}
            isLoading={isSubmitting && !disabled}
            type={'button'}
            disabled={isSubmitting || !note || disabled}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

InternalNoteEditor.propTypes = {
  initialData: PropTypes.any,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  canSetPublic: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

const InternalNoteMenu = ({ profile, note, onChangeVisibility, onEdit }) => {
  const handleChangeVisibility = () => {
    const newVisibility = note?.is_public ? 'private' : 'public';
    onChangeVisibility && onChangeVisibility(newVisibility);
  };

  const handleEdit = () => {
    onEdit && onEdit(note);
  };

  const renderItems = () => {
    const items = [];
    if (profile.type === USER_TYPE_ADMIN && note?.user)
      items.push(
        <Dropdown.Item
          key={'visibility'}
          as={'button'}
          type={'button'}
          onClick={handleChangeVisibility}
          className="py-2"
        >
          Set as {note?.is_public ? 'private' : 'public'}
        </Dropdown.Item>
      );

    if (note?.user_id === profile?.id)
      items.push(
        <Dropdown.Item key={'edit'} as={'button'} type={'button'} onClick={handleEdit} className="py-2">
          Edit
        </Dropdown.Item>
      );

    return items;
  };

  const items = renderItems();

  return (
    <div>
      <div className="d-flex justify-content-end">
        {items.length > 0 && (
          <Dropdown className="ms-auto">
            <Dropdown.Toggle
              bsPrefix="toggle"
              as={Link}
              to="#"
              className="nav-link d-flex align-items-center text-muted"
            >
              <FontAwesomeIcon icon="ellipsis-v" />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
              <div className="bg-white rounded-2 dark__bg-1000">{items}</div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

InternalNoteMenu.propTypes = {
  params: PropTypes.any,
  profile: PropTypes.any,
  note: PropTypes.any,
  onChangeVisibility: PropTypes.func,
  onEdit: PropTypes.func
};

const InternalNoteBullet = ({ profile, note }) => {
  return (
    <>
      {note?.metadata?.source === '3JMS' ? (
        <Avatar size="2xl" style={{ height: 48, width: 48 }} src={threejmsLogo} />
      ) : (
        <>
          {note?.user_id && profile?.permissions.includes(USER_PERMISSION_ACCOUNT) ? (
            <Link
              style={{ height: 48, width: 48 }}
              target="_blank"
              to={
                note?.user_id && profile?.permissions.includes(USER_PERMISSION_ACCOUNT)
                  ? `/settings/user/${note?.user_id}`
                  : ''
              }
            >
              <Avatar size="2xl" style={{ height: 48, width: 48 }} src={note?.user_photo ? note?.user_photo : avatar} />
            </Link>
          ) : (
            <div style={{ height: 48, width: 48 }}>
              <Avatar size="2xl" style={{ height: 48, width: 48 }} src={note?.user_photo ? note?.user_photo : avatar} />
            </div>
          )}
        </>
      )}
    </>
  );
};

InternalNoteBullet.propTypes = {
  profile: PropTypes.any,
  note: PropTypes.any
};

const InternalNoteTitle = ({ profile, note, menu }) => {
  return (
    <div className="ms-3 d-flex align-items-center justify-content-between">
      <div style={{ minHeight: 25 }} className="d-flex align-items-center">
        {profile?.permissions.includes(USER_PERMISSION_ACCOUNT) && note?.user_id ? (
          <Link className="mb-0 fw-normal" target="_blank" to={note?.user_id ? `/settings/user/${note?.user_id}` : ''}>
            {note?.user}
          </Link>
        ) : (
          <span className="fs--1 mb-0 fw-normal">{note?.user}</span>
        )}
        {note?.user ? <span className="text-muted">&nbsp;-&nbsp;</span> : ''}
        <small className="text-muted">{formatDatetime(note?.created_at)}</small>
        {note?.is_public && profile.type === USER_TYPE_ADMIN ? (
          <small>
            <Badge bg="warning" className="ms-1">
              Public
            </Badge>
          </small>
        ) : null}
      </div>
      {menu}
    </div>
  );
};

InternalNoteTitle.propTypes = {
  profile: PropTypes.any,
  note: PropTypes.any,
  menu: PropTypes.any
};

const InternalNoteView = ({ note }) => {
  return (
    <div className="ms-3">
      <div className="text-word-break internal-note-body ql-editor d-flex">
        <div
          className="flex-fill"
          dangerouslySetInnerHTML={{
            __html: chunkString(
              DOMPurify.sanitize(note.description, {
                ALLOWED_TAGS,
                ADD_CLASSES: [
                  'ql-indent-1',
                  'ql-indent-2',
                  'ql-indent-3',
                  'ql-indent-4',
                  'ql-indent-5',
                  'ql-indent-6',
                  'ql-indent-7',
                  'ql-indent-8'
                ],
                ADD_ATTR: ['class']
              })
            )
          }}
        ></div>
      </div>
    </div>
  );
};

InternalNoteView.propTypes = {
  note: PropTypes.any.isRequired
};

export const InternalNote = ({ params, lastUpdate = null, disabled, hideDisabled = false, onAdd = null }) => {
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const { profile } = useContext(AppContext);
  const { onPaginate, filters } = useFilters();
  const [response, setResponse] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState(null);

  if (!filters?.page) {
    filters['page'] = 1;
  }

  const loadData = async (elementAdded = false) => {
    if (!params) return;
    try {
      setIsLoading(true);
      const { data } = await axios.get('/order/internal-note/', { params: { ...filters, ...params, lastUpdate } });
      setResponse(data);
      if (elementAdded) onAdd(data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  const updateNoteData = (noteId, note, isPublic) => {
    const updatedResults = response.results.map((item) => {
      return item.id === noteId ? { ...item, description: note, is_public: isPublic } : item;
    });
    const updatedResponse = { ...response, results: updatedResults };
    setResponse(updatedResponse);
  };

  const setVisibility = async (noteId, visibility) => {
    if (!params) return;
    try {
      setIsLoading(true);
      await axios.post(`/order/internal-note/${noteId}/${visibility}/`);
      await loadData();
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        toast.error('Failed to set visibility of Internal Note');
        setIsLoading(false);
      }
    }
  };

  const handleEdit = (noteId) => {
    setEditingNoteId(noteId);
  };

  const handleSave = async ({ note, isPublic }) => {
    setIsSubmitting(true);
    const sanitizedNote = DOMPurify.sanitize(note, {
      ALLOWED_TAGS: []
    }).trim();

    if (!sanitizedNote) {
      toast.error('Empty notes can not be submitted', {
        theme: 'colored'
      });
      setIsSubmitting(false);
      return;
    }
    try {
      if (editingNoteId) updateNoteData(editingNoteId, note, isPublic);
      const payload = { ...params, description: note, is_public: isPublic };
      const {
        data: { message }
      } = editingNoteId
        ? await axios.post(`/order/internal-note/${editingNoteId}/`, payload)
        : await axios.post('/order/internal-note/', payload);

      onPaginate('1');
      await loadData(true);
      toast.success(message, { theme: 'colored' });
    } catch ({ response }) {
      if (response?.data?.message) {
        const message = Array.isArray(response.data.message) ? response.data.message[0] : response.data.message;
        toast.error(message, {
          theme: 'colored'
        });
      }
    }
    setEditingNoteId(null);
    setIsSubmitting(false);
  };

  const handleCancel = () => {
    setEditingNoteId(null);
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, [...getMapValues(filters), lastUpdate, ...getMapValues(params)]);

  return (
    <div>
      <Timeline bulletSize={25} lineWidth={3} style={{ paddingLeft: 24 }}>
        {disabled && hideDisabled ? null : (
          <Timeline.Item
            bulletSize={48}
            bullet={
              <>
                <Avatar size={'2xl'} style={{ height: 48, width: 48 }} src={profile?.photo ? profile?.photo : avatar} />
              </>
            }
          >
            <InternalNoteEditor
              disabled={disabled || !!editingNoteId}
              isSubmitting={isSubmitting}
              canSetPublic={profile.type === USER_TYPE_ADMIN}
              onSave={handleSave}
            />
            {isLoading && response?.results?.length === 0 ? (
              <div className="mb-3">
                <LoadingView />
              </div>
            ) : null}
          </Timeline.Item>
        )}
        {response?.results?.map((note, index) => (
          <Timeline.Item
            key={`${note.id}-${index}`}
            className="animate animate__fadeIn"
            title={
              <InternalNoteTitle
                profile={profile}
                note={note}
                menu={
                  <InternalNoteMenu
                    profile={profile}
                    note={note}
                    onChangeVisibility={(newVisibility) => setVisibility(note.id, newVisibility)}
                    onEdit={(note) => handleEdit(note.id)}
                  />
                }
              />
            }
            bulletSize={note?.user ? 48 : 25}
            bullet={<InternalNoteBullet profile={profile} note={note} />}
            lineVariant="dashed"
          >
            {note.id === editingNoteId ? (
              <InternalNoteEditor
                initialData={{ note: note.description, isPublic: note.is_public }}
                disabled={disabled}
                isSubmitting={isSubmitting}
                canSetPublic={profile.type === USER_TYPE_ADMIN}
                onSave={handleSave}
                onCancel={handleCancel}
              />
            ) : (
              <InternalNoteView note={note} />
            )}
          </Timeline.Item>
        ))}
      </Timeline>
      {response?.results?.length === 0 ? (
        <div className="text-muted text-center mb-3">No internal notes found</div>
      ) : null}

      <div className="d-flex justify-content-center pt-3">
        {/*  <LoadingButton*/}
        {/*    isLoading={isLoading}*/}
        {/*    disabled={isLoading}*/}
        {/*    onClick={() => {*/}
        {/*      loadMore();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Load more*/}
        {/*  </LoadingButton>*/}
        {/*</div>*/}
        <Pagination
          page={response?.page}
          total={response?.num_pages}
          withEdges
          onChange={(page) => onPaginate(page)}
          disabled={response?.num_pages <= 1}
        />
      </div>
    </div>
  );
};

InternalNote.propTypes = {
  endpoint: PropTypes.string,
  disabled: PropTypes.bool,
  lastUpdate: PropTypes.any,
  hideDisabled: PropTypes.bool,
  onAdd: PropTypes.func,
  params: PropTypes.object
};
