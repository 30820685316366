import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Button, ButtonGroup, Form as BootstrapForm } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { RequiredMarker } from './RequiredMarker';

export const ImageUploadField = ({
  label,
  clearable = true,
  helpText = null,
  disabled = false,
  required = true,
  defaultSrc = cloudUpload,
  imageClass = '',
  ...props
}) => {
  const [{ value, name, id }, meta, { setValue }] = useField(props);
  const controlId = id || name;
  const ref = useRef();
  const [preview, setPreview] = useState(value);

  const handleOnChange = ({ target: { files } }) => {
    const file = files?.length > 0 ? files[0] : null;
    setValue(file);
  };

  useEffect(() => {
    if (value instanceof File) {
      setPreview(URL.createObjectURL(value));
    } else {
      setPreview(value || defaultSrc);
    }
  }, [value]);
  return (
    <div className="mt-3">
      {preview && (
        <div className="d-flex align-items-center align-items-sm-start">
          <img src={preview} alt={`Preview of ${label}`} className={`${imageClass} preview-img`} />
        </div>
      )}
      <div className="mt-1 text-center">
        <div>
          <label htmlFor={controlId}>
            {label} {required && <RequiredMarker />}
          </label>
        </div>
        <input
          id={`id_${name}`}
          accept="image/png,image/jpg,image/jpeg,image/webp"
          disabled={disabled}
          type="file"
          onChange={handleOnChange}
          ref={ref}
          key={value}
          required={required}
          className="d-none"
        />
        {clearable ? (
          <ButtonGroup size="sm" className="me-2 mb-1">
            <Button
              id={`id_${name}_change`}
              type="button"
              className="text-nowrap"
              disabled={disabled}
              variant="secondary"
              onClick={() => ref.current?.click()}
            >
              Choose file
            </Button>
            {value && (
              <Button disabled={disabled} variant="light" onClick={() => setValue('')} id={`id_${name}_remove`}>
                <FontAwesomeIcon icon="trash" />
              </Button>
            )}
          </ButtonGroup>
        ) : (
          <button
            id={`id_${name}_change`}
            type="button"
            disabled={disabled}
            className="btn btn-success text-nowrap"
            onClick={() => ref.current?.click()}
          >
            Choose file
          </button>
        )}
      </div>
      {!meta.error && helpText && <BootstrapForm.Text className="text-muted">{helpText}</BootstrapForm.Text>}
      {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>}
    </div>
  );
};

ImageUploadField.propTypes = {
  label: PropTypes.string,
  clazz: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  defaultSrc: PropTypes.any,
  imageClass: PropTypes.any
};
