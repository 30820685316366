import baseAxios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const baseAxiosConfiguration = {
  headers: {
    'Content-type': 'application/json'
  },
  withCredentials: true
};

let csrfToken = null;
export const createAxios = (signal) => {
  const axiosInstance = baseAxios.create({
    baseURL: baseURL,
    ...baseAxiosConfiguration,
    signal
  });
  axiosInstance.interceptors.request.use((config) => {
    if (csrfToken) {
      config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
  });
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response?.headers?.csrf_token) {
        axiosInstance.defaults.headers.common['X-CSRFToken'] = response?.headers.csrf_token;
      }
      csrfToken = response?.headers.csrf_token;
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
