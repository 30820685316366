import { Button, Form, Offcanvas } from 'react-bootstrap';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';

export const FilterModal = ({ show, filters, handleClose, onFilter, filterComponent }) => {
  return (
    <Offcanvas show={show} onHide={handleClose} keyboard={false} placement="end">
      <Offcanvas.Header closeButton className="border-bottom mb-0">
        <Offcanvas.Title className="p-0 m-0">Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex p-0">
        <Formik
          enableReinitialize={true}
          validateOnMount={true}
          initialValues={filters}
          onSubmit={(values) => {
            onFilter(values);
            handleClose();
          }}
        >
          {({ handleSubmit }) => (
            <Form className="d-flex flex-column w-100">
              <div className="flex-fill w-100 overflow-auto px-3 pb-5">{filterComponent}</div>
              <div className="border-top d-flex align-items-center justify-content-between p-3">
                <div></div>
                <Button
                  color="primary"
                  onClick={() => {
                    handleSubmit();
                    toast.success('Filter Applied', { theme: 'colored' });
                  }}
                >
                  Filter
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

FilterModal.propTypes = {
  show: PropTypes.bool,
  filters: PropTypes.any,
  handleClose: PropTypes.func,
  onFilter: PropTypes.func,
  filterComponent: PropTypes.any
};
