/*eslint camelcase: ["error", {allow: ["discount_total","is_imported","substatus_label","status_label","shipping_country","risk_level","shipping_type_name","shipping_state","order_number","source_name","risk_score","item_sum","retailer_name","brand_name","status_name","order_status","tracking_number", "tracking_url","tracking_company","tracking_status", "ids_list", "created_at","customer_email", "items_count","total_range_value","discount_code","has_engraving","internal_tracking_number"]}]*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import { cellAsDatetime, ORDER_STATUS, SOURCE_LIST, STATE_LIST, TRACKING_COMPANIES } from '../../services/utils';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import CurrencyFormat from 'react-currency-format';
import { useFilters } from '../../hooks/useFilters';
import MinMaxField from '../../components/form/MinMaxField';
import { FilterRow } from '../../components/FilterRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CanceledError } from 'axios';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';
import YesNoEmptyField from '../../components/form/YesNoEmptyField';
import AppContext from '../../context/Context';
import { ADMIN_OR_BRAND, USER_PERMISSION_ORDER_EDIT } from '../../services/constants';
import { TableTab } from '../../components/common/ListTable/TableTab';
import { COUNTRY_LIST } from '../../utils';
import { OrderStatusIndicator } from '../../components/OrderStatusIndicator';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import StartDateEndDateField from '../../components/form/StartDateEndDateField';

const OrderList = () => {
  useStaticTitle('Orders');
  const navigate = useNavigate();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();
  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'order_number',
      Header: 'Order Number',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { order_number, id, synchronized, risk_score, is_imported } = rowData.row.original;
        const messages = [];
        if (!synchronized && !is_imported) {
          messages.push(<Popover.Body>Not synchronized with Retailer</Popover.Body>);
        }
        if (risk_score >= 11 && risk_score <= 75) {
          messages.push(<Popover.Body>Elevated risk score</Popover.Body>);
        }
        return (
          <>
            <Link className="text-dark fw-normal" to={`/order/${id}/`}>
              {order_number}
            </Link>
            {messages.length > 0 ? (
              <>
                <OverlayTrigger
                  trigger="click"
                  placement={messages?.length >= 5 ? 'bottom' : 'top'}
                  rootClose
                  overlay={
                    <Popover>
                      {messages.map((message, index) => {
                        return (
                          <div key={`message-${index}`}>
                            {index !== 0 ? <hr className="m-0 p-0" /> : null}
                            {message}
                          </div>
                        );
                      })}
                    </Popover>
                  }
                >
                  <button type="button" className="btn btn-link text-warning">
                    <FontAwesomeIcon icon="exclamation-triangle" />
                  </button>
                </OverlayTrigger>
              </>
            ) : null}
          </>
        );
      }
    },
    {
      accessor: 'shipping_country',
      Header: 'Country',
      Cell: (rowData) => {
        const { id, shipping_country } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {shipping_country}
          </Link>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {cellAsDatetime(rowData, 'created_at')}
          </Link>
        );
      }
    },
    {
      accessor: 'brand',
      Header: 'Brand',
      Cell: (rowData) => {
        const { brand_name, id } = rowData.row.original;
        return (
          <>
            <Link className="text-dark fw-normal" to={`/order/${id}/`}>
              {brand_name}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'subtotal',
      Header: 'Subtotal',
      Cell: (rowData) => {
        const { subtotal, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={subtotal || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'total',
      Header: 'Total',
      Cell: (rowData) => {
        const { total, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={total || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'discount_total',
      Header: 'Discount Total',
      disableSortBy: true,
      Cell: (rowData) => {
        const { discount_total, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={discount_total || 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'donation',
      Header: 'Donation',
      Cell: (rowData) => {
        const { donation, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            <CurrencyFormat
              decimalScale={2}
              fixedDecimalScale={true}
              value={donation ?? 0}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Link>
        );
      }
    },
    {
      accessor: 'shipping_type_name',
      Header: 'Shipping Type',
      Cell: (rowData) => {
        const { shipping_type_name, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {shipping_type_name}
          </Link>
        );
      }
    },
    {
      accessor: 'shipping_state',
      Header: 'Shipping State',
      Cell: (rowData) => {
        const { shipping_state, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {shipping_state ? shipping_state : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'item_sum',
      Header: 'Items',
      disableSortBy: true,
      Cell: (rowData) => {
        const { item_sum, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {item_sum}
          </Link>
        );
      }
    },
    {
      accessor: 'has_engraving',
      Header: 'Engraving',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'py-2'
      },
      Cell: (rowData) => {
        const { has_engraving, id } = rowData.row.original;
        return (
          <Link className="w-100 text-center text-dark fw-normal" to={`/order/${id}/`}>
            {has_engraving ? (
              <FontAwesomeIcon icon="fa-check-circle" className="text-success fs-0 text-success" />
            ) : (
              '-'
            )}
          </Link>
        );
      }
    },
    {
      accessor: 'retailer',
      Header: 'Retailer',
      Cell: (rowData) => {
        const { retailer_name, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {retailer_name}
          </Link>
        );
      }
    },
    {
      accessor: 'order_status',
      Header: 'Order Status',
      Cell: (rowData) => {
        const { status_label, substatus_label, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            <OrderStatusIndicator status={status_label} substatus={substatus_label} />
          </Link>
        );
      }
    },
    {
      accessor: 'customer_email',
      Header: 'Customer',
      Cell: (rowData) => {
        const { customer_email, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {customer_email}
          </Link>
        );
      }
    },
    {
      accessor: 'discount_code',
      Header: 'Discount Code',
      disableSortBy: true,
      Cell: (rowData) => {
        const { discounts, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {discounts?.length ? discounts?.map((discount) => discount.code).join(', ') : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'tracking_company',
      Header: 'Tracking Company',
      Cell: (rowData) => {
        const { tracking_company, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {tracking_company ? tracking_company : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'tracking_number',
      Header: 'Tracking Number',
      Cell: (rowData) => {
        const { internal_tracking_number, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {internal_tracking_number ? internal_tracking_number : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'source',
      Header: 'Source',
      Cell: (rowData) => {
        const { source_name, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {source_name ? source_name : '-'}
          </Link>
        );
      }
    },
    {
      accessor: 'retailer_order_id',
      Header: '3JMS Synchronized',
      Cell: (rowData) => {
        const { synchronized, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {synchronized && <FontAwesomeIcon icon="fa-check-circle" className="text-success fs-0 text-success" />}
          </Link>
        );
      }
    },
    {
      accessor: 'recipient',
      disableSortBy: true,
      Header: 'Recipient',
      Cell: (rowData) => {
        const { recipient, id } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal" to={`/order/${id}/`}>
            {recipient}
          </Link>
        );
      }
    }
  ];

  const defaultTabOptions = [
    { label: 'All', filters: {} },
    {
      label: 'Open',
      filters: { order_status: ['new_order', 'action_req'], ordering: 'created_at' }
    },
    { label: 'Risky', filters: { risk_level: true } },
    {
      label: 'Shipped',
      filters: { order_status: ['in_transit', 'completed'] }
    },
    { label: 'Canceled', filters: { order_status: 'canceled' } }
  ];
  const tabComponent = (
    <>
      <TableTab name="order" onFilter={onFilter} filters={filters} defaultOptions={defaultTabOptions} />
    </>
  );

  const InlineFilterComponent = () => {
    const { values } = useFormikContext();

    const substatusParams = useMemo(() => {
      if (values?.order_status) {
        const order_status = _.isArray(values.order_status) ? values.order_status : [values.order_status];
        return order_status.length > 0 ? { order_status } : {};
      }
      return {};
    }, [values]);

    const hasAnyOrderStatus = useMemo(() => values?.order_status?.length > 0, [values]);

    return (
      <>
        <FilterRow name="shipping_country">
          <SimpleSelectField
            options={COUNTRY_LIST}
            isMulti={true}
            label="Country"
            name="shipping_country"
            className="mb-0"
          />
        </FilterRow>
        <FilterRow name="order_status">
          <SimpleSelectField
            options={ORDER_STATUS}
            label="Order Status"
            name="order_status"
            isMulti={true}
            className="mb-0"
          />
        </FilterRow>
        <FilterRow name="substatus">
          <AsyncSelectField
            label="Order Substatus"
            disabled={!hasAnyOrderStatus}
            params={substatusParams}
            name="substatus"
            isMulti={true}
            strict
            placeholder={hasAnyOrderStatus ? 'Select Substatus' : 'Select Order Status'}
            urlEndpoint={'/dropdown/substatus/'}
            className="mb-0"
          />
        </FilterRow>
        <FilterRow name="source">
          <SimpleSelectField options={SOURCE_LIST} label="Source" name="source" className="mb-0" />
        </FilterRow>
        <FilterRow name="shipping_state">
          <SimpleSelectField options={STATE_LIST} label="State" name="shipping_state" className="mb-0" />
        </FilterRow>
        <FilterRow name="tracking_company">
          <SimpleSelectField
            label="Tracking Company"
            name="tracking_company"
            options={TRACKING_COMPANIES}
            className="mb-0"
          />
        </FilterRow>
        <FilterRow name="retailer">
          <AsyncSelectField label="Retailer" name="retailer" urlEndpoint={'/dropdown/retailer/'} className="mb-0" />
        </FilterRow>
        {ADMIN_OR_BRAND.includes(profile?.type) && (
          <FilterRow name="portfolios">
            <AsyncSelectField
              isMulti={true}
              label="Portfolios"
              name="portfolios"
              urlEndpoint={'/dropdown/portfolio/'}
              className="mb-0"
            />
          </FilterRow>
        )}
        <FilterRow name="brand">
          <AsyncSelectField label="Brand" name="brand" urlEndpoint={'/dropdown/brand/'} className="mb-0" />
        </FilterRow>
        <FilterRow name="financial_status">
          <SimpleSelectField
            label="Financial Status"
            options={[
              { value: 'paid', label: 'Paid' },
              { value: 'pending', label: 'Pending' },
              { value: 'refund', label: 'Refunded' },
              { value: 'partially_refund', label: 'Partially Refunded' }
            ]}
            name="financial_status"
            className="mb-0"
          />
        </FilterRow>
        <FilterRow name="discount_code">
          <AsyncSelectField
            label="Discount Code"
            name="discount_code"
            urlEndpoint={'/dropdown/discount-code/'}
            className="mb-0"
          />
        </FilterRow>
        <YesNoEmptyField yesLabel="Risky" noLabel="Normal" label="Risk Level" name="risk_level" className="mb-0 mt-3" />
        <YesNoEmptyField
          yesLabel="Synchronized"
          noLabel="Not Synchronized"
          label="Is 3JMS Synchronized"
          name="synchronized"
          className="mb-0 mt-3"
        />
        <YesNoEmptyField
          yesLabel="Engraved"
          noLabel="No Engraving"
          label="Engraving"
          name="has_engraving"
          className="mb-0 mt-3"
        />
        <YesNoEmptyField
          yesLabel="Imported"
          noLabel="Not Imported"
          label="Imported"
          name="is_imported"
          className="mb-0 mt-3"
        />
        <FilterRow name={['total_range_min', 'total_range_max']}>
          <MinMaxField label="Total" name="total_range" type="currency" min={0} />
        </FilterRow>
        <FilterRow name={['created_at_min', 'created_at_max']}>
          <StartDateEndDateField name="created_at" />
        </FilterRow>
      </>
    );
  };
  const rowOptions = [
    {
      label: 'Edit',
      icon: 'pencil-alt',
      onClick: (obj) => navigate(`/order/${obj?.id}/`),
      hasPermission: () => {
        return true;
      }
    }
  ];

  const loadOrders = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`order/`, { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      // only hide loading if the error isn't a cancel to a new request
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadOrders().catch(console.error);
    return abortRequest;
  }, [filters]);
  const actions = [].filter((action) => action.hasPermission());

  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Orders'}
        name="order"
        addRoute={profile?.permissions?.includes(USER_PERMISSION_ORDER_EDIT) ? '/order/create/' : null}
        filterComponent={<InlineFilterComponent />}
        actions={profile?.permissions?.includes(USER_PERMISSION_ORDER_EDIT) ? actions : null}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
        tabComponent={tabComponent}
        module="order"
      />
    </>
  );
};
export default OrderList;
