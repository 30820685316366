/*eslint camelcase: ["error", {allow: ["page_size","stage", "sort_position", "is_active"]}]*/
import React, { useEffect, useState } from 'react';
import { useAxios } from '../../../hooks/useAxios';
import { useDocumentTitle } from '../../../hooks/usePageTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useFilters } from '../../../hooks/useFilters';
import { PageLoadingView } from '../../../components/PageLoadingView';
import KanbanContainer from './KanbanContainer';
import { SearchInput } from '../../../components/common/ListTable/SearchInput';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { FilterModal } from '../../../components/common/ListTable/FilterModal';
import { KanbanContext } from '../../../context/Context';
import { FilterComponent } from '../FilterComponent';
import _ from 'lodash';
import { SortSelectField } from '../../../components/form/SelectField';

const KanbanBrand = () => {
  const { type } = useParams();
  const { search, onSearch, onSortBy, sortBy, filters, onFilter, clearFilter } = useFilters();
  const [selectedTask, setSelectedTask] = useState(null);

  const { axios, setIsLoading, isLoading, abortRequest } = useAxios();
  const [columns, setColumns] = useState([]);
  const [brands, setBrands] = useState([]);

  useDocumentTitle([['Brands', 'Kanban'], _.startCase(type)]);

  const loadColumns = async () => {
    setIsLoading(true);
    try {
      const params = { page_size: 300 };
      const response = await axios.get(type === 'stage' ? 'dropdown/stage/?active=true' : 'dropdown/provider/', {
        params
      });
      const headers = response?.data?.results?.map((header) => ({ ...header, brands: [] }));
      setColumns([
        {
          id: 0,
          name: type === 'stage' ? 'No Stage' : 'No Provider',
          sort_position: -1,
          count: 0,
          brands: []
        },
        ...headers
      ]);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const navigate = useNavigate();
  const [filterModalShow, setFilterModalShow] = useState(false);

  const filterComponent = <FilterComponent />;
  const hasFilter =
    Object.keys(filters || {}).filter((field) => !['page', 'q', 'column', 'ordering'].includes(field)).length > 0;

  useEffect(() => {
    loadColumns().catch(console.error);
    return abortRequest;
  }, [type]);

  const updateBrand = (brand) => {
    setBrands((currentBrands) =>
      currentBrands.map((eachBrand) => {
        if (brand?.id === eachBrand?.id) {
          return { ...eachBrand, ...brand };
        }
        return eachBrand;
      })
    );
  };
  const sortOptions = [
    { value: 'name', label: 'Name A-Z' },
    { value: '-name', label: 'Name Z-A' },
    {
      value: 'value',
      label: 'Value 0-10'
    },
    { value: '-value', label: 'Value 10-0' },
    {
      value: '-created_at',
      label: 'Oldest creation'
    },
    { value: 'created_at', label: 'Newest creation' },
    {
      value: 'last_updated_at',
      label: 'Oldest update'
    },
    {
      value: '-last_updated_at',
      label: 'Newest update'
    }
  ];
  return (
    <>
      <KanbanContext.Provider
        value={{ columns, setColumns, brands, setBrands, selectedTask, setSelectedTask, updateBrand }}
      >
        <PageLoadingView visible={isLoading} />

        {columns && (
          <>
            <div className="card mb-3">
              <div className="card-header p-0">
                <div className="p-3">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      {onSearch && (
                        <div>
                          <SearchInput handleSearch={(value) => onSearch(value)} term={search} classNames="w-100" />
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-8 mt-md-0 mt-3">
                      <div className="d-flex justify-content-between justify-content-sm-end flex-wrap">
                        <SortSelectField options={sortOptions} onSortBy={onSortBy} sortBy={sortBy} />

                        <Button
                          variant="outline-secondary"
                          size="sm"
                          transform="shrink-3"
                          disabled={isLoading}
                          className={classNames('me-1 btn-secondary-flat', {
                            'd-none': filters === null
                          })}
                          onClick={() => {
                            onSortBy([]);
                            if (type === 'stage') {
                              navigate('/kanban/brand/provider');
                            } else {
                              navigate('/kanban/brand/stage');
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faRandom} transform="shrink-3" />
                          <span className="d-none d-sm-inline-block ms-1 ">Switch</span>
                        </Button>
                        {filterComponent ? (
                          <div className="d-flex">
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              transform="shrink-3"
                              className="btn-secondary-flat"
                              onClick={() => setFilterModalShow(true)}
                            >
                              <FontAwesomeIcon icon="filter" transform="shrink-3" />
                              <span className="d-none d-sm-inline-block ms-1">Filter</span>
                            </Button>
                            {hasFilter ? (
                              <Button
                                variant="outline-secondary"
                                size="sm"
                                transform="shrink-3"
                                className={classNames('ms-1 btn-secondary-flat', {
                                  'd-none': filters === null
                                })}
                                onClick={() => clearFilter()}
                              >
                                <FontAwesomeIcon icon="times-circle" transform="shrink-3" />
                                <span className="d-none d-sm-inline-block ms-1">Clear all filters</span>
                              </Button>
                            ) : null}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FilterModal
              filters={filters}
              onFilter={(filters) => onFilter(filters)}
              show={filterModalShow}
              handleClose={() => setFilterModalShow(false)}
              filterComponent={filterComponent}
            />

            <KanbanContainer type={type} filters={filters} setIsLoading={setIsLoading} />
          </>
        )}
      </KanbanContext.Provider>
    </>
  );
};

export default KanbanBrand;
