/*eslint camelcase: ["error", {allow: ["ids_list", "is_active"]}]*/

import React, { useContext, useEffect, useState } from 'react';
import { GenericListTable } from '../../../components/common/ListTable/GenericListTable';
import { Link, useNavigate } from 'react-router-dom';
import { cellAsDatetime } from '../../../services/utils';
import { useFilters } from '../../../hooks/useFilters';
import { CanceledError } from 'axios';
import { useAxios } from '../../../hooks/useAxios';
import { useStaticTitle } from '../../../hooks/usePageTitle';
import { USER_PERMISSION_SETTING_EDIT } from '../../../services/constants';
import AppContext from '../../../context/Context';
import { BooleanIndicator } from '../../../components/table/BooleanIndicator';
import { FilterRow } from '../../../components/FilterRow';
import AsyncSelectField from '../../../components/form/SelectField';
import YesNoEmptyField from '../../../components/form/YesNoEmptyField';

const EmailSettingList = () => {
  useStaticTitle('Email Alerts');

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();

  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/email-setting/${rowData.row.original.id}`}>
          {rowData.row.original.name}
        </Link>
      )
    },
    {
      accessor: 'since_x_hours',
      Header: 'Since X Hours',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/email-setting/${rowData.row.original.id}`}>
          {rowData.row.original.since_x_hours}
        </Link>
      )
    },
    {
      accessor: 'is_active',
      Header: 'Active',
      Cell: (rowData) => {
        const { id, is_active } = rowData.row.original;
        return (
          <Link className="text-dark fw-normal d-flex align-items-center" to={`/settings/email-setting/${id}/`}>
            <BooleanIndicator isActive={is_active} />
          </Link>
        );
      }
    },
    {
      accessor: 'created_by',
      Header: 'Created By',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/email-setting/${id}`}>
            {rowData.row.original.created_by}
          </Link>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/email-setting/${rowData.row.original.id}`}>
          {cellAsDatetime(rowData, 'created_at')}
        </Link>
      )
    },
    {
      accessor: 'updated_at',
      Header: 'Updated At',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/email-setting/${rowData.row.original.id}`}>
          {cellAsDatetime(rowData, 'updated_at')}
        </Link>
      )
    }
  ];

  const loadData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`email-setting/`, { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, [filters]);

  const rowOptions = [
    {
      label: 'Edit',
      icon: 'pencil-alt',
      onClick: (obj) => navigate(`/settings/email-setting/${obj?.id}/`),
      hasPermission: () => {
        return true;
      }
    }
  ];
  const filterComponent = (
    <>
      <FilterRow name="portfolios">
        <AsyncSelectField
          isMulti={true}
          label="Portfolios"
          name="portfolios"
          urlEndpoint={'/dropdown/portfolio/'}
          className="mb-0"
        />
      </FilterRow>
      <FilterRow name="brand">
        <AsyncSelectField label="Brand" name="brand" urlEndpoint={'/dropdown/brand/'} className="mb-0" />
      </FilterRow>
      <YesNoEmptyField label="Active" name="is_active" className="mb-0 mt-3" />
    </>
  );
  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Email Alerts'}
        name="email_setting"
        addRoute={
          profile?.permissions?.includes(USER_PERMISSION_SETTING_EDIT) ? '/settings/email-setting/create/' : null
        }
        filterComponent={filterComponent}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
      />
    </>
  );
};

export default EmailSettingList;
