/*eslint camelcase: ["error", {allow: ["order_count","created_at","type_display","status_display","brand_name"]}]*/

import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import { useFilters } from '../../hooks/useFilters';
import { CanceledError } from 'axios';
import { useAxios } from '../../hooks/useAxios';
import { FilterRow } from '../../components/FilterRow';
import { SimpleSelectField } from '../../components/form/SelectField';
import { formatDatetime, IMPORT_STATUS } from '../../services/utils';
import Avatar from '../../components/common/Avatar';
import avatar from '../../assets/img/team/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import { USER_PERMISSION_ACCOUNT, USER_PERMISSION_IMPORT_EDIT, USER_PERMISSION_ORDER } from '../../services/constants';
import AppContext from '../../context/Context';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const PermissionLink = ({ children, href, hasPermission, ...props }) => {
  if (hasPermission) {
    return (
      <a target="_blank" href={href} rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return <div {...props}>{children}</div>;
};
PermissionLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.any,
  hasPermission: PropTypes.any
};

export const ImportList = () => {
  useTitle('Import Task | Bottle Nexus');

  const navigate = useNavigate();

  const { profile } = useContext(AppContext);
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [responseData, setResponseData] = useState(null);
  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();

  const fields = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return <div className="d-flex align-items-center text-dark fw-normal">{id}</div>;
      }
    },
    {
      accessor: 'brand',
      Header: 'Brand',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { brand_name } = rowData.row.original;
        return <div className="d-flex align-items-center text-dark fw-normal">{brand_name}</div>;
      }
    },
    {
      accessor: 'user',
      Header: 'Imported By',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { user } = rowData.row.original;
        return (
          <div className="d-flex align-items-center text-dark fw-normal">
            {user ? (
              <PermissionLink
                className="d-flex align-items-center"
                hasPermission={profile?.permissions.includes(USER_PERMISSION_ACCOUNT)}
                href={`/settings/user/${user.id}`}
              >
                <Avatar size="2xl" src={user?.photo ? user?.photo : avatar} className="me-2" />
                <span>
                  {user?.first_name} {user?.last_name}
                </span>
              </PermissionLink>
            ) : (
              <Avatar size="2xl" src={avatar} className="me-2" />
            )}
          </div>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (rowData) => {
        const { status, status_display } = rowData.row.original;
        return (
          <>
            <div className="d-flex align-items-center text-dark fw-normal">
              {status === 'pending' && (
                <>
                  <Spinner animation="border" role="status" size={'sm'} variant="info">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  <span className="ms-1">{status_display}</span>
                </>
              )}

              {status === 'failed' && (
                <>
                  <FontAwesomeIcon
                    className="text-danger"
                    icon="exclamation"
                    transform="shrink-3"
                    aria-label="Filters"
                  />
                  <span className="ms-2">{status_display}</span>
                </>
              )}

              {status === 'generated' && (
                <>
                  <FontAwesomeIcon
                    className="text-success"
                    icon="check-circle"
                    transform="shrink-3"
                    aria-label="Filters"
                  />
                  <span className="ms-2">{status_display}</span>
                </>
              )}

              {status === 'incomplete' && (
                <>
                  <FontAwesomeIcon className="text-secondary" icon="ban" transform="shrink-3" aria-label="Filters" />
                  <span className="ms-2">{status_display}</span>
                </>
              )}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'file',
      Header: 'Import File',
      Cell: (rowData) => {
        const { file } = rowData.row.original;
        return (
          <div className="d-flex align-items-center text-dark fw-normal">
            {file ? (
              <a
                href={file}
                download={file || 'download'}
                target="_blank"
                rel="noreferrer"
                className="btn btn-outline-primary btn-sm text-decoration-none me-3"
              >
                <FontAwesomeIcon icon="fas fa-download" /> Download
              </a>
            ) : null}
          </div>
        );
      }
    },
    {
      accessor: 'errors',
      Header: 'Error File',
      Cell: (rowData) => {
        const { errors } = rowData.row.original;
        return (
          <>
            <div className="d-flex align-items-center text-dark fw-normal">
              {errors ? (
                <a
                  href={errors}
                  download={'download'}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-danger btn-sm text-decoration-none me-3"
                >
                  <FontAwesomeIcon icon="fas fa-download" /> Download
                </a>
              ) : (
                '-'
              )}
            </div>
          </>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Requested At',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return (
          <>
            <div className="text-dark fw-normal">{formatDatetime(created_at)}</div>
          </>
        );
      }
    }
  ];

  const filterComponent = (
    <>
      <FilterRow name="status">
        <SimpleSelectField
          options={IMPORT_STATUS}
          label="Import Status"
          name="status"
          isMulti={true}
          className="mb-0"
        />
      </FilterRow>
    </>
  );

  const loadImports = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('import/', { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      // only hide loading if the error isn't a cancel to a new request
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadImports().catch(console.error);
    return abortRequest;
  }, [filters]);

  const rowOptions = [
    {
      label: 'View Orders',
      icon: 'eye',
      onClick: (obj) => navigate(`/order?import_task_id=${obj?.id}`),
      hasPermission: () => {
        return profile?.permissions?.includes(USER_PERMISSION_ORDER);
      }
    }
  ];
  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Import Tasks'}
        addRoute={profile?.permissions?.includes(USER_PERMISSION_IMPORT_EDIT) ? '/import/create/' : null}
        name="import"
        search={search}
        onSearch={onSearch}
        filterComponent={filterComponent}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
        rowOptions={rowOptions}
      />
    </>
  );
};
