import { Spinner } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

export const LoadingView = ({ width = 1 }) => (
  <div className="d-flex align-items-center justify-content-center h-100">
    {width ? <Spinner animation="border" style={{ borderWidth: width }} /> : <Spinner animation="border" />}
  </div>
);

LoadingView.propTypes = {
  width: PropTypes.number
};
