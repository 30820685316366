export const IMAGE_EXTENSION_TYPE_MESSAGE = 'Only the following formats are accepted: .jpeg, .jpg, .png';

export const validateImageExtension = (value) => {
  if (!(value instanceof File)) {
    return true;
  }
  return (
    value?.type === 'image/jpeg' ||
    value?.type === 'image/jpg' ||
    value?.type === 'image/png' ||
    value?.type === 'image/webp'
  );
};
