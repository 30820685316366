import { Dropdown, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

export const ColumnDropdown = ({ fields, visibleFields, setVisibleField }) => {
  return (
    <Dropdown autoClose="outside" className="d-sm-inline-table d-md-inline-block ms-1">
      <Dropdown.Toggle variant="outline-secondary" className="btn-sm text-nowrap d-flex align-items-center">
        Columns
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {fields.map((field) => {
          const isVisible = visibleFields[field.name];
          return (
            <Dropdown.Item key={field?.name} onClick={() => setVisibleField(field?.name, !isVisible)}>
              <div className="d-flex align-content-center">
                <Form.Check
                  onChange={() => {}}
                  key={`check_${isVisible}`}
                  type="checkbox"
                  checked={isVisible}
                  id={`id_visible_${field.name}`}
                  name={`name_visible_${field.name}`}
                />
                &nbsp; {field.label}
              </div>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ColumnDropdown.propTypes = {
  fields: PropTypes.array,
  visibleFields: PropTypes.any,
  setVisibleField: PropTypes.func,
  selectedRowIds: PropTypes.any
};
