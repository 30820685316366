/*eslint camelcase: ["error", {allow: ["stage", "sort_position", "is_active", "brands_count"]}]*/
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { KanbanColumn } from './KanbanColumn';
import { AddAnotherForm } from './AddAnotherForm';
import { BrandKanbanModal } from './BrandKanbanModal';
import { DragDropContext } from 'react-beautiful-dnd';
import IconButton from 'components/common/IconButton';
import is from 'is_js';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useAxios } from '../../../hooks/useAxios';
import { KanbanContext } from '../../../context/Context';
import { withColumnId } from '../../../helpers/utils';

const KanbanContainer = ({ type, filters, setIsLoading }) => {
  const { axios, abortRequest } = useAxios();

  const [showForm, setShowForm] = useState(false);
  const containerRef = useRef(null);

  const { columns, setColumns, brands, setBrands, updateBrand } = useContext(KanbanContext);

  const handleSubmit = async (listData) => {
    if (!Object.keys(listData).length) {
      setShowForm(false);
      return;
    }
    try {
      const { data } = await axios.post(type === 'stage' ? 'stage/' : 'provider/', {
        name: listData.title,
        brands: [],
        is_active: true
      });
      setColumns([...columns, { ...data, brands: [] }]);
    } catch ({ response: { data } }) {
      if (data?.name) toast.error(data?.name[0]);
      else toast.error(data?.message);
    }

    setShowForm(false);
  };

  useEffect(() => {
    if (is.ipad()) {
      containerRef.current.classList.add('ipad');
    }
    if (is.mobile()) {
      containerRef.current.classList.add('mobile');
      if (is.safari()) {
        containerRef.current.classList.add('safari');
      }
      if (is.chrome()) {
        containerRef.current.classList.add('chrome');
      }
    }
    document.body.classList.add('fullscreen-page');
  }, []);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceColumn = getColumn(source.droppableId);
    const destinationColumn = getColumn(destination.droppableId);

    setColumns((allColumns) =>
      allColumns.map((column) => {
        if (column.id === sourceColumn.id) {
          return { ...column, count: column.count - 1 };
        }
        if (column.id === destinationColumn.id) {
          return { ...column, count: column.count + 1 };
        }
        return column;
      })
    );

    const sourceBrand = sourceColumn.brands[source.index];

    const value = destinationColumn?.id ? destinationColumn?.id : null;

    let payload = { sort_position: destination.index };
    payload = type === 'stage' ? { ...payload, stage: value } : { ...payload, provider: value };

    const updatedBrand = { ...sourceBrand, ...payload };

    updateBrand(updatedBrand);

    const filterBrandsByColumn = (brands, column) =>
      brands.filter((brand) => {
        let columnId = type === 'stage' ? brand?.stage : brand?.provider;
        if (!columnId) {
          columnId = 0;
        }
        return parseInt(columnId) === parseInt(column);
      });

    setBrands((allBrands) => {
      const clonedBrands = [...allBrands];
      const brandIndex = clonedBrands.findIndex((b) => b?.id === sourceBrand?.id);
      const selectedBrand = clonedBrands.splice(brandIndex, 1)[0];
      const destinationBrands = filterBrandsByColumn(clonedBrands, destination.droppableId);
      destinationBrands.splice(destination.index, 0, selectedBrand);

      return [
        ...clonedBrands.filter((brand) => {
          let columnId = type === 'stage' ? brand?.stage : brand?.provider;
          if (!columnId) {
            columnId = 0;
          }
          return parseInt(columnId) !== parseInt(destination.droppableId);
        }),
        ...destinationBrands
      ];
    });

    try {
      await axios.post(`kanban/brand/${sourceBrand.id}/`, payload);
    } catch {
      toast.error(`We found some problems when saving brand data`);
    }
  };

  const brandByColumns = useMemo(() => {
    return columns.map((eachColumn) => {
      const selectedBrands = brands.map(withColumnId(type)).filter((brand) => {
        return parseInt(brand.columnId) === parseInt(eachColumn.id);
      });
      return {
        ...eachColumn,
        brands: selectedBrands.map((brand, index) => ({ ...brand, sort_position: index }))
      };
    });
  }, [columns, brands]);

  const getColumn = (id) => brandByColumns?.find((item) => item.id === Number(id));
  const [pageByColumn, setPageByColumn] = useState({});
  useEffect(() => {
    setPageByColumn({});
  }, [type]);

  const updateNextPage = (columnId, nextPage) => {
    const payload = { ...pageByColumn };
    payload[`${columnId}`] = nextPage;
    setPageByColumn(payload);
  };
  const pageSize = 25;
  const fetchBrands = async (currentPage, columnId) => {
    if (!currentPage) {
      return;
    }
    let currentFilters = { ...filters };
    if (!currentFilters?.ordering) {
      currentFilters['ordering'] = 'sort_position';
    }
    currentFilters['page_size'] = pageSize;
    currentFilters['page'] = currentPage;

    try {
      const suffix = columnId ? '' : '__isnull';
      if (type === 'stage') {
        currentFilters[`stage${suffix}`] = columnId;
      } else {
        currentFilters[`provider${suffix}`] = columnId;
      }

      const { data } = await axios.get(`brand/`, { params: currentFilters });
      updateNextPage(columnId, data?.next_page ? data?.next_page : -1);

      setColumns((currentColumns) =>
        currentColumns.map((column) => {
          if (column.id === columnId) {
            return { ...column, count: data.count };
          }
          return { ...column };
        })
      );

      const dataResults = data?.results ? data?.results : [];

      setBrands((currentBrands) => [
        ...currentBrands,
        ...dataResults.filter((newBrand) => !currentBrands.find((b) => parseInt(b.id) === parseInt(newBrand.id)))
      ]);
    } catch (error) {
      console.error(error);
      updateNextPage(columnId, -1);
    }
  };

  const changeKey = columns.map((c) => c.id).join('-');

  useEffect(() => {
    setBrands([]);
    columns.map((column) => {
      fetchBrands(1, column.id);
    });
    return abortRequest;
  }, [filters, changeKey]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="kanban-container me-n3 scrollbar" ref={containerRef}>
          {brandByColumns?.map((column) => {
            return (
              <KanbanColumn
                onLoadMore={async (setRequestSent) => {
                  let nextPage = pageByColumn[`${column.id}`];
                  if (!nextPage && column?.count > pageSize) nextPage = 2;
                  if (nextPage !== -1) {
                    setRequestSent(true);
                    await fetchBrands(nextPage, column.id);
                    setRequestSent(false);
                  }
                }}
                type={type}
                filters={filters}
                key={`${type}-${column.id}`}
                column={column}
                setIsLoading={setIsLoading}
              />
            );
          })}
          <div className="kanban-column">
            <AddAnotherForm type="list" onSubmit={handleSubmit} showForm={showForm} setShowForm={setShowForm} />

            {!showForm && (
              <IconButton
                variant="primary"
                className="d-block w-100 border-400"
                icon="plus"
                iconClassName="me-1"
                onClick={() => setShowForm(true)}
              >
                {type === 'stage' ? 'Add Stage' : 'Add Provider'}
              </IconButton>
            )}
          </div>
        </div>
      </DragDropContext>

      <BrandKanbanModal />
    </>
  );
};

KanbanContainer.propTypes = {
  setBrands: PropTypes.any,
  columns: PropTypes.any,
  brands: PropTypes.any,
  type: PropTypes.any,
  headers: PropTypes.any,
  selectedTask: PropTypes.any,
  openTaskInformationModal: PropTypes.any,
  setOpenTaskInformationModal: PropTypes.func,
  setColumns: PropTypes.func,
  setSelectedTask: PropTypes.func,
  setIsLoading: PropTypes.func,
  filters: PropTypes.any
};
export default KanbanContainer;
