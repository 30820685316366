import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';

const Main = (props) => {
  const configState = {
    isRTL: false,
    isNavbarVerticalCollapsed: getItemFromStore('isNavbarVerticalCollapsed', settings.isNavbarVerticalCollapsed),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);
  const [profile, setProfile] = useState(null);

  const { isLoaded } = useToggleStyle(config.isRTL, configDispatch);

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: ['isRTL', 'isNavbarVerticalCollapsed'].includes(key)
      }
    });
  };

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch, profile, setProfile }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
