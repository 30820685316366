/*eslint camelcase: ["error", {allow: ["new_password", "confirm_password"]}]*/
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../../components/form/TextField';
import LoadingButton from '../../components/common/LoadingButton';
import * as Yup from 'yup';
import { useAxios } from '../../hooks/useAxios';
import { useStaticTitle } from '../../hooks/usePageTitle';

const passwordValidation = Yup.string()
  .min(6, 'Password must be at least 6 characters')
  .required('Password is required');

const validate = Yup.object({
  password: passwordValidation,
  confirmPassword: passwordValidation
});

const PasswordReset = () => {
  useStaticTitle('Reset Password');
  const navigate = useNavigate();
  const { uidb64, token } = useParams();

  const { axios, abortRequest } = useAxios();
  useEffect(() => abortRequest, []);
  return (
    <>
      <h5 className="text-center text-capitalize">Reset new password</h5>
      <div className="text-left">
        <Formik
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={validate}
          onSubmit={async ({ password, confirmPassword }) => {
            try {
              const {
                data: { message }
              } = await axios.patch('account/auth/reset-password-form/', {
                new_password: password,
                confirm_password: confirmPassword,
                uidb64: uidb64,
                token: token
              });
              if (message) {
                toast.success(message, { theme: 'colored' });
                navigate('/login');
              }
            } catch ({ response: { data } }) {
              if (data?.new_password) {
                toast.error(data?.new_password[0], {
                  theme: 'colored'
                });
              } else if (data?.message) {
                toast.error(data?.message, {
                  theme: 'colored'
                });
              }
            }
          }}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                required={true}
                label="New Password"
                name="password"
                type="password"
                disabled={isSubmitting}
                data-lpignore="true"
                autoComplete="new-password"
                minLength={6}
              />
              <TextField
                required={true}
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                disabled={isSubmitting}
                data-lpignore="true"
                autoComplete="new-password"
                minLength={6}
              />
              <LoadingButton
                type="submit"
                color="primary"
                className="mt-3 w-100"
                disabled={!isValid}
                isLoading={isSubmitting}
              >
                Set password
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default PasswordReset;
