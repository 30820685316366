/*eslint camelcase: ["error", {allow: ["disclaimer","brand_disclaimer","minimum_requirements","minimum_requirements_value","ids_list","starts_at","ends_at","url_endpoint","type_discount","is_active","url_endpoint"user_id","brand_list",]}]*/
import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import TextField from '../../components/form/TextField';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import 'yup-phone';
import { asFormData, DISCOUNT_TYPES } from '../../services/utils';
import { SubmitFooter } from '../../components/form/SubmitFooter';
import DatePickerField from '../../components/form/DatePickerField';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import {
  CUSTOMER_ELIGIBILITY,
  DISCOUNT_FIXED_AMOUNT,
  DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE,
  DISCOUNT_MINIMUM_REQUIREMENT_QUANTITY,
  DISCOUNT_NO_MINIMUM_REQUIREMENTS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_TYPE_CART_FIXED_AMOUNT,
  USER_PERMISSION_GIFT_CARD_EDIT,
  USER_TYPE_BRAND
} from '../../services/constants';
import CurrencyField from '../../components/form/CurrencyField';
import IntegerField from '../../components/form/IntegerField';
import moment from 'moment';
import classNames from 'classnames';
import { useAxios } from '../../hooks/useAxios';
import { useDocumentAction, useDocumentTitle } from '../../hooks/usePageTitle';
import { LoadingView } from '../../components/LoadingView';
import { SwitchField } from '../../components/form/CheckboxField';
import { DATETIME_FORMAT, MAX_PRICE_ERROR, priceMax } from '../../utils';
import AppContext from '../../context/Context';
import ToggleCard from '../../components/form/ToggleCard';
import { InternalNote } from '../../components/InternalNote';

const GiftCardCreateUpdate = () => {
  const { id } = useParams();
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [discount, setDiscount] = useState(null);
  const [selectedProducts] = useState([]);
  const { profile } = useContext(AppContext);
  const isBrand = profile?.type === USER_TYPE_BRAND;

  const isReadOnly = id && !profile?.permissions?.includes(USER_PERMISSION_GIFT_CARD_EDIT);
  const loadDiscountData = async () => {
    const { data } = await axios.get(`gift-card/${id}/`);
    setDiscount(data);
  };

  const navigate = useNavigate();

  const percentageValidator = Yup.number()
    .required()
    .test('is_positive', 'The value must be positive and greater than zero', (value) => value > 0)
    .test(
      'is_lower_or_equal_100_percent',
      () => 'The value must be less than or equal to 100%',
      async (value, testContext) => {
        let {
          parent: { type }
        } = testContext;
        if (!type) return true;
        type = parseInt(type);
        const isPercentage = DISCOUNT_PERCENTAGE.includes(type);
        if (!isPercentage || !value) {
          return true;
        }
        return parseFloat(value) <= 100;
      }
    );

  const valueValidatorImplementation = Yup.number()
    .label('Value')
    .when('type', {
      is: (type) => DISCOUNT_PERCENTAGE.includes(parseInt(type)),
      then: () => percentageValidator,
      otherwise: (schema) =>
        schema.when('type', {
          is: (type) => DISCOUNT_FIXED_AMOUNT.includes(parseInt(type)),
          then: (schema) => schema.required().test('error_max_price', MAX_PRICE_ERROR, priceMax),
          otherwise: (schema) => schema.nullable()
        })
    });

  const validate = Yup.object({
    code: id ? Yup.string().nullable() : Yup.string().required('Gift card code is required'),
    minimum_requirements_value: Yup.number().when('minimum_requirements', {
      is: (minimum_requirements) => minimum_requirements === DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE,
      then: () =>
        Yup.number()
          .nullable()
          .test('error_max_price', MAX_PRICE_ERROR, priceMax)
          .label('Minimum requirement value')
          .required()
          .positive('The value must be positive'),
      otherwise: () => Yup.number().required().moreThan(-1).label('Minimum requirement value')
    }),
    minimum_requirements: Yup.number().required().label('Minimum requirements'),
    eligibility: Yup.number().required('Eligibility is a required field'),
    brand: Yup.string().required('Brand is required').label('Brand'),
    type: Yup.string().required().label('Type'),
    value: valueValidatorImplementation,
    starts_at: Yup.string().nullable(),
    ends_at: Yup.string()
      .nullable()
      .test(
        'ends_at',
        () => 'Ends At must be after Starts At',
        async (value, testContext) => {
          let {
            parent: { starts_at }
          } = testContext;
          if (!starts_at || !value) {
            return true;
          }
          const startsAt = moment(starts_at, DATETIME_FORMAT);
          const endsAt = moment(value, DATETIME_FORMAT);
          return startsAt <= endsAt;
        }
      )
      .test(
        'at_least_5_minutes',
        () => 'Ends At must be at least 5 minutes after Start At',
        async (value, testContext) => {
          let {
            parent: { starts_at }
          } = testContext;
          if (!starts_at || !value) {
            return true;
          }
          const startsAt = moment(starts_at, DATETIME_FORMAT).add(5, 'minute');
          const endsAt = moment(value, DATETIME_FORMAT);
          return startsAt <= endsAt;
        }
      )
  });

  useDocumentTitle([
    ['Gift Card', useDocumentAction()],
    [discount?.id, discount?.type_name]
  ]);

  const loadData = async () => {
    setIsLoading(true);
    if (id) {
      await loadDiscountData();
    }
    setIsLoading(false);
  };
  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, []);
  // required fields will work fine with validation once you provide an initial value
  const randomString = () => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const length = 16;
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.round(Math.random() * (chars.length - 1))];
    }
    return result;
  };
  const initialValues = id
    ? {
        ...discount,
        brand: discount?.brand?.value,
        brand_disclaimer: discount?.brand_disclaimer,
        code: discount?.code,
        products: discount?.products?.map((p) => p.value)
      }
    : {
        minimum_requirements: '',
        minimum_requirements_value: 0,
        eligibility: '',
        type: DISCOUNT_TYPE_CART_FIXED_AMOUNT,
        value: '',
        brand: '',
        products: [],
        code: randomString(),
        is_active: true,
        brand_disclaimer: false
      };
  const minimumRequirements = [
    { value: DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE, label: 'Minimum Purchase' },
    { value: DISCOUNT_MINIMUM_REQUIREMENT_QUANTITY, label: 'Minimum Quantity' },
    {
      value: DISCOUNT_NO_MINIMUM_REQUIREMENTS,
      label: 'No Minimum Requirements'
    }
  ];

  return (
    <>
      <div className="mt-3">
        <Formik
          validateOnBlur={true}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validate}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            setSubmitting(true);
            try {
              const payload = asFormData({
                ...values
              });
              const { data } = await axios.post(id ? `/gift-card/${id}/` : '/gift-card/', payload, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              });
              toast.success(
                <>
                  <Link to={`/gift-card/${data.id}/`}>Gift Card code</Link> was{' '}
                  <>{id ? 'updated' : 'created'} successfully</>
                </>,
                { theme: 'colored' }
              );
              navigate(`/gift-card/`);
            } catch ({ response }) {
              setErrors(response?.data);
              if (response?.data?.message) {
                toast.error(response?.data?.message, {
                  theme: 'colored'
                });
              }
              setSubmitting(false);
            }
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => {
            const currentCode = values?.code ? values?.code : values?.safe_code;
            useEffect(() => {
              if (
                values?.minimum_requirements_value &&
                values?.minimum_requirements === DISCOUNT_MINIMUM_REQUIREMENT_QUANTITY
              ) {
                setFieldValue('minimum_requirements_value', parseInt(values?.minimum_requirements_value));
              } else if (
                values?.minimum_requirements_value &&
                values?.minimum_requirements === DISCOUNT_NO_MINIMUM_REQUIREMENTS
              ) {
                setFieldValue('minimum_requirements_value', parseInt('0'));
              }
            }, [values?.minimum_requirements]);
            return (
              <>
                <Form
                  className={classNames('', {
                    'd-none': isLoading
                  })}
                >
                  <Row className="g-3">
                    <Col lg={8}>
                      <Card>
                        <FalconCardHeader
                          title={id ? 'Update Gift Card' : 'Create Gift Card'}
                          endEl={
                            <>
                              <div className="d-flex">
                                <SwitchField
                                  name="is_active"
                                  label="Active"
                                  disabled={isSubmitting || isReadOnly}
                                  required={false}
                                />
                              </div>
                            </>
                          }
                        />
                        <Card.Body className="bg-light">
                          <div className="row">
                            <div className="col-sm-12">
                              <Row className="mb-3 g-3">
                                <Col>
                                  {id ? (
                                    <TextField
                                      label="Gift Card code"
                                      required={false}
                                      name="safe_code"
                                      disabled={true}
                                    />
                                  ) : (
                                    <TextField label="Gift Card code" required={false} name="code" disabled={true} />
                                  )}

                                  <small>Customers should enter this code at checkout.</small>
                                </Col>
                                <Col>
                                  <CurrencyField
                                    required={true}
                                    label="Gift Card Value"
                                    name="value"
                                    disabled={isSubmitting || isReadOnly}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <AsyncSelectField
                                    label="Brand"
                                    name="brand"
                                    required={true}
                                    disabled={isSubmitting || isReadOnly}
                                    urlEndpoint="/dropdown/brand/"
                                    isMulti={false}
                                    onChangeValue={() =>
                                      setFieldValue(
                                        'products',
                                        selectedProducts
                                          .filter((product) => values?.brand?.value == product.brand)
                                          .map((product) => product.value)
                                      )
                                    }
                                  />
                                </Col>
                                <Col>
                                  <AsyncSelectField
                                    key={values?.brand}
                                    params={{ brand: values?.brand }}
                                    label="Products"
                                    name="products"
                                    disabled={isSubmitting || !values?.brand || isReadOnly}
                                    urlEndpoint={'/dropdown/product-by-brand/'}
                                    isMulti={true}
                                  />
                                </Col>
                              </Row>
                              {values?.products?.length === 0 && (
                                <div>
                                  <small className="text-warning">
                                    If you do not select a product, it will apply to all of the brand's products.
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>

                      <Card className="mt-3">
                        <Card.Body className="bg-light">
                          <div className="row">
                            <div className="col-sm-12">
                              <Row className="mb-3 g-3">
                                <Col lg="6">
                                  <SimpleSelectField
                                    label="Minimum Requirements"
                                    name="minimum_requirements"
                                    required={true}
                                    options={minimumRequirements}
                                    disabled={isSubmitting || isReadOnly}
                                    placeholder="Select..."
                                  />
                                </Col>
                                <Col lg="6">
                                  {values?.minimum_requirements === DISCOUNT_MINIMUM_REQUIREMENT_PURCHASE && (
                                    <CurrencyField
                                      required={true}
                                      label="Minimum Requirements Amount"
                                      name="minimum_requirements_value"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  )}
                                  {values?.minimum_requirements === DISCOUNT_MINIMUM_REQUIREMENT_QUANTITY && (
                                    <IntegerField
                                      required={true}
                                      label="Minimum Requirements Quantity"
                                      name="minimum_requirements_value"
                                      disabled={isSubmitting || isReadOnly}
                                    />
                                  )}
                                </Col>
                                <Col lg="6">
                                  <SimpleSelectField
                                    label="Customer eligibility"
                                    name="eligibility"
                                    required={true}
                                    options={CUSTOMER_ELIGIBILITY}
                                    disabled={isSubmitting || isReadOnly}
                                    placeholder="Select..."
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="mt-3">
                        <Card.Body className="bg-light">
                          <div className="row">
                            <div className="col-sm-12">
                              <Row className="mb-3 g-3">
                                <Col lg="6">
                                  <DatePickerField
                                    required={false}
                                    label="Starts at"
                                    name="starts_at"
                                    disabled={isSubmitting || isReadOnly}
                                  />
                                </Col>
                                <Col lg="6">
                                  <DatePickerField
                                    required={false}
                                    label="Ends at"
                                    name="ends_at"
                                    disabled={isSubmitting || isReadOnly}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      <Card className="mt-3">
                        <FalconCardHeader title={'Disclaimer'} required={true} />
                        <Card.Body className="bg-light">
                          <div className="row">
                            <SwitchField
                              name="brand_disclaimer"
                              label={
                                <>
                                  Copy the code for gift card recipient. The code won't show again after creating the
                                  gift card.
                                  {isBrand && (
                                    <>
                                      <br />
                                      <br />
                                      By creating a gift card on your own, you acknowledge and agree that it is entirely
                                      your responsibility, and you will be invoiced the discount amount on a monthly
                                      basis. By opting to generate these discounts, you expressly acknowledge and agree
                                      to adhere to these stipulated terms, obligating yourself to cover the complete
                                      extent of the discount. This constitutes a binding agreement.
                                    </>
                                  )}
                                </>
                              }
                              disabled={isSubmitting || isReadOnly}
                              required={true}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                      {id && (
                        <Card className="mt-3 animate__animated animate__fadeIn animate__delay-4">
                          <ToggleCard title="Notes" open={true}>
                            <Card.Body className="bg-light">
                              <InternalNote params={{ discount: id }} />
                            </Card.Body>
                          </ToggleCard>
                        </Card>
                      )}
                    </Col>
                    <Col lg={4}>
                      <div className="sticky-sidebar">
                        <Card className="mb-3">
                          <FalconCardHeader title="Summary" />
                          <Card.Body className="bg-light">
                            <h6>TYPE AND METHOD</h6>
                            <ul>
                              {DISCOUNT_TYPES?.filter((type) => type?.value === values?.type).map((discountType) => (
                                <li key={discountType.value}>{discountType.label}</li>
                              ))}
                              <li>Code</li>
                            </ul>
                            {values?.code !== null && values.type !== null && (
                              <>
                                <h6>
                                  <b>DETAILS</b>
                                </h6>
                                <div
                                  className="dropzone-area mb-4"
                                  onClick={async () => {
                                    if (!id) {
                                      await navigator.clipboard.writeText(currentCode);
                                      toast.success('Gift Card code copied to clipboard successfully');
                                    }
                                  }}
                                >
                                  {values?.value && (
                                    <>
                                      <h5>GET ${values?.value} OFF YOUR NEXT PURCHASE!</h5>
                                      <br />
                                    </>
                                  )}
                                  use coupon code:
                                  <br />
                                  <b>{currentCode}</b>
                                </div>
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    {values?.brand_disclaimer}
                  </Row>
                  {!isReadOnly && (
                    <SubmitFooter
                      backUrl={'/discount/'}
                      isSubmitting={isSubmitting}
                      disabled={!values?.brand_disclaimer}
                    />
                  )}
                </Form>
                {isLoading && (
                  <div className="py-3">
                    <LoadingView width={1} />
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default GiftCardCreateUpdate;
