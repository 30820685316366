import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from 'context/Context';
import Logo from 'components/common/Logo';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from './common/Avatar';
import avatar from '../assets/img/team/avatar.png';
import { useAxios } from '../hooks/useAxios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NavbarTop = () => {
  const navigate = useNavigate();

  const { axios, abortRequest } = useAxios();

  const { profile } = useContext(AppContext);

  const logoutHandler = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await axios.get('account/auth/logout/');
    navigate('/login');
    toast.success('Logout', { theme: 'colored' });
  };
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => setConfig('showBurgerMenu', !showBurgerMenu);

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  useEffect(() => {
    return abortRequest;
  }, []);
  return (
    <>
      <Navbar
        className={classNames('navbar-glass fs--1 navbar-top sticky-kit', {
          'navbar-glass-shadow': showDropShadow
        })}
        expand={true}
      >
        <Navbar.Toggle className={classNames('toggle-icon-wrapper me-md-3 me-2 d-xl-none', {})} as="div">
          <button
            className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
            onClick={handleBurgerMenu}
            id="burgerMenu"
          >
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        </Navbar.Toggle>

        <Logo at="navbar-top" id="topLogo" />

        <Nav navbar className="navbar-nav-icons ms-auto flex-row align-items-center" as="ul">
          <Dropdown navbar={true} as="li" className="dropdown-user-wrapper">
            <Dropdown.Toggle bsPrefix="toggle" as={Link} to="#" className="nav-link d-flex align-items-center">
              <div className="px-2 d-flex align-items-center">
                <Avatar src={profile?.photo ? profile?.photo : avatar} />
                <div className="d-flex flex-column ms-2">
                  <span className="d-none d-sm-block">
                    {profile?.first_name} <span className="d-none d-lg-inline">{profile?.last_name}&nbsp;</span>
                    <FontAwesomeIcon icon="chevron-down" />
                  </span>
                  <small className="d-none d-sm-block text-muted">{profile?.type_name}</small>
                  <FontAwesomeIcon icon="chevron-down" className="d-inline d-sm-none" />
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
              <div className="bg-white dark__bg-1000" style={{ borderRadius: '.375rem' }}>
                <Dropdown.Item as={Link} to="/profile/" className="py-2">
                  Profile Settings
                </Dropdown.Item>
                <Dropdown.Divider className="my-0" />
                <a
                  href="https://bottlenexus.com/support/"
                  target="_blank"
                  rel="noreferrer"
                  className="dropdown-item text-decoration-none py-2"
                >
                  <FontAwesomeIcon icon="info-circle" /> Support
                </a>
                <Dropdown.Divider className="my-0" />
                <Dropdown.Item onClick={logoutHandler} className="py-2">
                  Logout
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>
      <div
        className={classNames('navbar-backdrop backdrop d-xl-none', { 'd-none': !showBurgerMenu })}
        onClick={() => setConfig('showBurgerMenu', !showBurgerMenu)}
      />
    </>
  );
};
