import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import CurrencyInput from 'react-currency-input-field';
import { capitalizeFirstLetter } from '../../utils';

export const PositiveStockField = ({
  label,
  disabled,
  placeholder,
  prefix,
  required = false,
  className = 'mb-2',
  ...props
}) => {
  const [{ name, value, onBlur }, meta, { setValue }] = useField(props);
  return (
    <div className={className}>
      {label && (
        <label htmlFor={name} className="text-capitalize">
          {label} {required && <RequiredMarker />}
        </label>
      )}
      <CurrencyInput
        allowNegativeValue={true}
        prefix={prefix}
        disabled={disabled}
        placeholder={placeholder ? placeholder : 'Please enter a quantity'}
        decimalsLimit={2}
        className="form-control"
        name={name}
        value={value}
        onValueChange={(value) => setValue(value)}
        onBlur={onBlur}
      />

      <FormikError name={name} meta={meta} />
    </div>
  );
};

PositiveStockField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  prefix: PropTypes.string,

  placeholder: PropTypes.string
};

export const PositiveNumberField = ({ label, labelComponent, required = false, step = 0.01, ...props }) => {
  const [{ value, ...field }, meta, { setTouched, setValue }] = useField(props);
  const capitalizedLabel = capitalizeFirstLetter(label);
  const currentValue = value || value === 0 ? parseFloat(value) : '';

  return (
    <div className="mb-2">
      <div className="d-flex align-items-center">
        {label && (
          <label htmlFor={field.name} className="text-capitalize">
            {capitalizedLabel} {required && <RequiredMarker />}
          </label>
        )}
        {labelComponent ? labelComponent : null}
      </div>
      <input
        className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
        required={required}
        placeholder={label + '...'}
        type="number"
        step={step}
        value={currentValue}
        autoComplete="new-password"
        onChange={(e) => {
          setTouched(true);
          if (e.target.value && parseFloat(e.target.value) >= 0) {
            setValue(parseFloat(e.target.value));
          } else {
            setValue('');
          }
        }}
        {...props}
      />

      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

PositiveNumberField.propTypes = {
  labelComponent: PropTypes.any,
  step: PropTypes.any,
  label: PropTypes.string,
  required: PropTypes.bool
};

PositiveNumberField.defaultProps = {
  label: ''
};

export const PositiveIntegerField = ({
  label,
  labelComponent,
  placeholder,
  showLabel = true,
  step = 1,
  required = false,
  helpText = null,
  className = null,
  ...props
}) => {
  const [{ value, ...field }, meta, { setTouched, setValue }] = useField(props);
  const capitalizedLabel = capitalizeFirstLetter(label);
  const currentValue = value || value === 0 ? parseFloat(value) : '';

  return (
    <div className={className || 'mb-2'}>
      <div className="d-flex align-items-center">
        {showLabel && label ? (
          <label htmlFor={field.name} className="text-capitalize">
            {capitalizedLabel} {required && <RequiredMarker />}
          </label>
        ) : null}
        {labelComponent ? labelComponent : null}
      </div>
      <input
        className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
        required={required}
        placeholder={placeholder ? placeholder : label + '...'}
        type="number"
        step={step}
        value={currentValue}
        autoComplete="new-password"
        onChange={(e) => {
          setTouched(true);
          if (e.target.value && parseInt(e.target.value) >= 0) {
            setValue(parseInt(e.target.value));
          } else {
            setValue('');
          }
        }}
        {...props}
      />

      <FormikError name={field.name} meta={meta} />
      <div>{helpText ? <small className="text-muted">{helpText}</small> : null}</div>
    </div>
  );
};

PositiveIntegerField.propTypes = {
  showLabel: PropTypes.any,
  step: PropTypes.any,
  labelComponent: PropTypes.any,
  helpText: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

PositiveIntegerField.defaultProps = {
  label: ''
};

export default PositiveNumberField;

export const PositiveNumberFloatingField = ({ label, required = false, ...props }) => {
  const [{ value, ...field }, meta, { setTouched, setValue }] = useField(props);
  const currentValue = value || value === 0 ? parseFloat(value) : '';

  return (
    <div className="form-floating mb-2">
      <input
        className={`form-control shadow-none ${field.className && meta.touched && meta.error && 'is-invalid'}`}
        required={required}
        placeholder={label + '...'}
        type="number"
        value={currentValue}
        onChange={(e) => {
          setTouched(true);
          if (e.target.value && parseFloat(e.target.value) >= 0) {
            setValue(parseFloat(e.target.value));
          } else {
            setValue('');
          }
        }}
        autoComplete="new-password"
        {...props}
      />
      <label htmlFor={field.name} className="text-capitalize">
        {label} {required && <RequiredMarker />}
      </label>

      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

PositiveNumberFloatingField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool
};

PositiveNumberFloatingField.defaultProps = {
  label: ''
};
