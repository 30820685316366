import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
export const SimpleTimelineItem = ({ children, centerBullet = false, bullet = null }) => {
  return (
    <div className={classNames('simple-timeline-item d-flex', { 'center-bullet': centerBullet })}>
      <div className="d-flex justify-content-center align-items-start bullet">
        {bullet ?? <FontAwesomeIcon icon="fas fa-circle" style={{ fontSize: 40 }} color="#DEE1E5" />}
      </div>
      <div className="flex-fill">{children}</div>
    </div>
  );
};

SimpleTimelineItem.propTypes = {
  children: PropTypes.any,
  centerBullet: PropTypes.any,
  bullet: PropTypes.any
};
