import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { SUPPORT_WEBSITE } from '../../services/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import AppContext from '../../context/Context';
import { getRedirectTo } from '../../helpers/utils';

const Error500 = () => {
  const { profile } = useContext(AppContext);
  const redirectUrl = getRedirectTo(profile?.permissions);

  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">500</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">Whoops, something went wrong!</p>
        <hr />
        <p>
          Try refreshing the page, or going back and attempting the action again. If this problem persists,
          <a href={SUPPORT_WEBSITE} target="_blank" className="ms-1" rel="noreferrer">
            contact us
          </a>
          .
        </p>
        <Link className="btn btn-primary btn-sm mt-3" type="button" to={redirectUrl}>
          <FontAwesomeIcon icon={faHome} className="me-2" />
          Take me home
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error500;
