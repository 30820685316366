import { Card } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { COUNT_UP_DURATION } from '../helpers/utils';

export const RiskInsightsWidget = ({ riskScore, sellerMessage }) => {
  if (riskScore === null || riskScore === undefined) {
    return null;
  }
  const sectionRanges = [
    {
      min: 0,
      max: 4,
      color: '#3FA40E',
      style: { width: '5%', borderTopLeftRadius: 4, borderBottomLeftRadius: 4, left: 0 }
    },
    { min: 5, max: 64, color: '#FBBD3A', style: { width: '60%' } },
    { min: 65, max: 74, color: '#ED6703', style: { width: '10%' } },
    {
      min: 75,
      max: 100,
      color: '#880e37',
      style: { width: '25%', borderTopRightRadius: 4, borderBottomRightRadius: 4 }
    }
  ];
  const range = sectionRanges.find((range) => riskScore >= range.min && riskScore <= range.max);

  const color = range ? range.color : '#3FA40E';

  return (
    <>
      <div className="risk-insights-widget-container">
        <Card className="mb-3 animate__animated animate__fadeIn animate__delay-3">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="pb-0 bt-0 mb-0 mt-0">Risk Insights</h5>
            </div>
          </div>
          <Card.Body className="bg-light pt-5">
            <div className="position-relative">
              <div
                className="d-flex flex-column justify-content-center"
                style={{
                  position: 'absolute',
                  left: `calc(${riskScore}% - 15px)`,
                  width: 30,
                  bottom: 10
                }}
              >
                <span className="badge rounded-pill" style={{ backgroundColor: color }}>
                  <small>
                    <CountUp end={riskScore} duration={COUNT_UP_DURATION} />
                  </small>
                </span>
                <FontAwesomeIcon icon="sort-down" size={'sm'} style={{ marginTop: '-5px' }} />
              </div>
              <div className="progress-container">
                <div className="rounded d-flex">
                  {sectionRanges.map((section, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          height: 8,
                          backgroundColor: section.color,
                          ...section.style
                        }}
                      ></div>
                    );
                  })}
                </div>

                <div className="progress-relative-container mb-4">
                  {sectionRanges.map((progress) => (
                    <div key={progress.min} className="position-absolute" style={{ left: `${progress.min}%` }}>
                      <small>{progress.min}</small>
                    </div>
                  ))}
                  <div className="position-absolute" style={{ right: 0 }}>
                    <small>100</small>
                  </div>
                </div>
              </div>
            </div>
            {sellerMessage && (
              <div>
                <small className="text-muted">{sellerMessage}</small>
              </div>
            )}

            <small className="text-muted">
              Risk factors for this payment are compared to payments across the Stripe network.
            </small>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

RiskInsightsWidget.propTypes = {
  riskScore: PropTypes.number,
  sellerMessage: PropTypes.string
};
