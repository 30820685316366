import React from 'react';
import PropTypes from 'prop-types';
import { LoadingView } from './LoadingView';

export const PageLoadingView = ({ visible, position = 'center' }) => {
  if (!visible) {
    return null;
  }
  let styles = {
    zIndex: 2
  };
  if (position === 'top') {
    styles = {
      ...styles,
      left: '50%',
      top: '2rem'
    };
  } else {
    styles = {
      ...styles,
      left: '50%',
      top: '50%'
    };
  }
  return (
    <div
      style={{
        position: 'absolute',
        ...styles
      }}
    >
      <LoadingView />
    </div>
  );
};

PageLoadingView.propTypes = {
  visible: PropTypes.any,
  position: PropTypes.oneOf(['top', 'center'])
};
