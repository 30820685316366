/*eslint camelcase: ["error", {allow: ["recurrence_name","tax_details","action_req","engraving_lines","checkout_url","new_order","customer_email","brand_list","first_name", "tracking_url", "item_id", "order_id","order_number","customer_name","customer_phone_number","source_meta_data"]}]*/
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAxios } from '../../hooks/useAxios';
import { useDocumentTitle } from '../../hooks/usePageTitle';
import { Card, Dropdown } from 'react-bootstrap';
import classNames from 'classnames';
import FalconCardHeader from '../../components/common/FalconCardHeader';
import { Thumbnail } from '../../components/common/Avatar';
import itemNotFound from '../../assets/img/item-not-found.jpg';
import { formatDatetime, formatPhoneNumber, moneyFormat, USER_TYPE_ADMIN } from '../../services/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { CustomEmailModal } from '../order/CustomEmailModal';
import { DATE_FORMAT, preventDefault } from '../../utils';
import { InternalNote } from '../../components/InternalNote';
import { LoadingView } from '../../components/LoadingView';
import { getUTMLabel } from '../../utils/utm';
import { useWindowSize } from '../../hooks/useWindowSize';
import moment from 'moment';
import AppContext from '../../context/Context';
import { USER_PERMISSION_DISCOUNT, USER_PERMISSION_PRODUCT } from '../../services/constants';
import { MarketingWidget } from '../order/widgets/MarketingWidget';
import TaxDetails from '../../components/TaxDetails';
import { Swal } from '../../services/sweetalert';

const UTM_FIELDS = ['utm_id', 'utm_source', 'utm_medium', 'utm_term', 'utm_campaign', 'utm_content'];

const SubscriptionView = () => {
  const { id } = useParams();
  const [width] = useWindowSize();

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [subscription, setSubscription] = useState(null);

  const [lastUpdate, setLastUpdate] = useState(null);
  const [isShowingEmail, setIsShowingEmail] = useState(false);
  const [showCustomerEmailModal, setShowCustomerEmailModal] = useState(false);
  const { profile } = useContext(AppContext);

  const loadData = async () => {
    try {
      const { data } = await axios.get(`subscription/${id}/`);
      setSubscription(data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useDocumentTitle([['Subscription', 'View'], subscription?.code]);

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, []);

  const internalNoteComponent = useMemo(() => {
    return (
      <>
        <InternalNote
          hideDisabled={true}
          lastUpdate={lastUpdate}
          params={{
            subscription: subscription?.id
          }}
          disabled={!subscription}
        />
      </>
    );
  }, [subscription, lastUpdate]);

  const handleRefresh = async () => {
    const response = await axios.get(`/subscription/${id}/refresh/`);
    await loadData();
    toast.success(response?.data?.message);
  };
  const handleCancel = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: `This action cannot be undone`,
      showCancelButton: true,
      confirmButtonColor: '#bababa',
      cancelButtonColor: '#7066e0',
      confirmButtonText: 'Cancel Subscription',
      showConfirmButton: true,
      cancelButtonText: 'Close'
    });
    if (isConfirmed) {
      const response = await axios.get(`/subscription/${id}/cancel/`);
      await loadData();
      toast.success(response?.data?.message);
    }
  };

  const handleRestore = async () => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: `This action cannot be undone`,
      showCancelButton: true,
      confirmButtonColor: '#7066e0',
      cancelButtonColor: '#bababa',
      confirmButtonText: 'Reactivate Subscription',
      showConfirmButton: true,
      cancelButtonText: 'Close'
    });
    if (isConfirmed) {
      const response = await axios.get(`/subscription/${id}/reactivate/`);
      await loadData();
      toast.success(response?.data?.message);
    }
  };

  const breakpointWidth = 992;

  return (
    <div>
      {isLoading ? <LoadingView /> : null}

      {subscription ? (
        <>
          <CustomEmailModal
            params={{ subscription: id }}
            show={showCustomerEmailModal}
            onHide={() => setShowCustomerEmailModal()}
            onSuccess={(data) => {
              setShowCustomerEmailModal(false);
              setSubscription({ ...subscription, customer_email: data?.customer_email });
              setLastUpdate(new Date());
              setIsShowingEmail(true);
            }}
          />
          <div className="py-3 d-flex align-items-center flex-wrap">
            <Link to="/subscription" className="btn btn-outline-secondary">
              <FontAwesomeIcon icon="arrow-left" />
            </Link>
            <div className="ps-sm-3 flex-fill">
              <div className="d-flex flex-wrap align-items-center">
                <strong
                  className="cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(subscription.code).catch(console.error);
                    toast.success('Checkout ID copied to clipboard');
                  }}
                >
                  #{subscription?.code} - <FontAwesomeIcon icon="clock" className="me-1" />{' '}
                  {subscription.recurrence_name}
                </strong>
              </div>
              {subscription?.created_at ? (
                <div>
                  <small>{formatDatetime(subscription.created_at)}</small>
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {subscription?.status !== 'canceled' && subscription?.cancel_at ? (
                <div className="badge bg-danger text-white">Cancel at {formatDatetime(subscription?.cancel_at)}</div>
              ) : (
                <div
                  className={classNames('badge text-white', {
                    'bg-success': subscription?.status !== 'canceled',
                    'bg-danger': subscription?.status === 'canceled'
                  })}
                >
                  {subscription.status_display}
                </div>
              )}

              {profile?.type === USER_TYPE_ADMIN && subscription?.status !== 'canceled' ? (
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle
                    bsPrefix="toggle"
                    as={Link}
                    to="#"
                    className="nav-link d-flex align-items-center text-muted"
                  >
                    <FontAwesomeIcon icon="ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
                    <div className="bg-white rounded-2 dark__bg-1000">
                      {!subscription?.cancel_at ? (
                        <Dropdown.Item
                          as="button"
                          type="button"
                          onClick={handleCancel}
                          className="py-2 text-danger d-flex align-items-center"
                        >
                          <FontAwesomeIcon icon="times" className="me-1" />
                          Cancel Subscription
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          as="button"
                          type="button"
                          onClick={handleRestore}
                          className="py-2 d-flex align-items-center"
                        >
                          <FontAwesomeIcon icon="undo-alt" className="me-1" />
                          Reactivate Subscription
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        as="button"
                        type="button"
                        onClick={handleRefresh}
                        className="py-2 d-flex align-items-center"
                      >
                        <FontAwesomeIcon icon="sync" className="me-1" />
                        Refresh Subscription
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
            </div>
          </div>

          <div className={classNames('row', { 'd-none': !subscription })}>
            <div className="col-12 col-lg-8">
              <div className="card mb-3 animate__animated animate__fadeIn animate__delay-1">
                <div className="card-header">
                  <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <div className="flex-grow-0 py-1">
                      <h5 className="mb-0 pb-0">Checkout Details</h5>
                    </div>
                    <div className="text-right">
                      <div className="d-flex flex-wrap">
                        {subscription?.id && profile.type === USER_TYPE_ADMIN ? (
                          <>
                            <a
                              className="me-2 mb-1 btn btn-outline-dark my-1"
                              target="_blank"
                              href={subscription?.stripe_url}
                              rel="noreferrer"
                            >
                              View Subscription
                            </a>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <Card.Body>
                  {subscription?.items?.map((item, index) => {
                    return (
                      <div key={`item-${index}`} className="order-item mb-3 pb-3 flex-wrap">
                        <div className={'d-flex flew-wrap align-items-start'}>
                          <div className="d-flex w-100 align-items-center pe-1">
                            <div className="pe-4">
                              <div className="position-relative d-table">
                                <Thumbnail
                                  className="border text-center"
                                  src={item?.image ? item?.image : itemNotFound}
                                />
                                <span
                                  style={{ top: 100 }}
                                  className={
                                    'position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary'
                                  }
                                >
                                  {item.quantity}
                                </span>
                              </div>
                            </div>
                            <div className="flex-fill flex-grow-1">
                              <div>
                                {profile?.permissions?.includes(USER_PERMISSION_PRODUCT) ? (
                                  <a href={`/product/${item.product}`} target="_blank" rel="noreferrer">
                                    {item.name}
                                  </a>
                                ) : (
                                  <a target="_blank" rel="noreferrer">
                                    {item.name}
                                  </a>
                                )}
                                <div>
                                  <small>
                                    {moneyFormat(item.price)} &times; {item.quantity}
                                  </small>
                                </div>

                                {item.engraving_lines ? (
                                  <div
                                    style={{
                                      wordBreak: 'break-all'
                                    }}
                                  >
                                    <small>
                                      <strong>Engraving Lines: </strong>
                                      {item.engraving_lines}
                                    </small>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="justify-content-end flex-column d-flex">
                            <div className="d-flex mt-sm-0 align-items-center justify-content-center">
                              <div>
                                <div className="text-end">
                                  <div>
                                    <span>{moneyFormat(item.subtotal)}</span>
                                  </div>
                                </div>

                                {item.discount ? (
                                  <div className="text-end">
                                    <small className="text-danger">-{moneyFormat(item?.discount)}</small>
                                  </div>
                                ) : null}
                                {item.engraving_subtotal ? (
                                  <div>
                                    <small>+{moneyFormat(item.engraving_subtotal)}</small>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {subscription?.subtotal ? (
                    <div className="d-flex justify-content-between">
                      <strong>Subtotal</strong>
                      <span>{moneyFormat(subscription?.subtotal)}</span>
                    </div>
                  ) : null}
                  {subscription?.shipping_original_cost ? (
                    <div className="d-flex justify-content-between">
                      <strong>
                        Shipping{' '}
                        {subscription?.shipping_type_name ? <small>({subscription?.shipping_type_name})</small> : null}
                      </strong>
                      <span>{moneyFormat(subscription?.shipping_original_cost)}</span>
                    </div>
                  ) : null}

                  {subscription && <TaxDetails order={subscription} />}

                  {subscription.discount_cost ? (
                    <div className="d-flex justify-content-between">
                      <strong>
                        Discounts
                        {subscription?.discounts?.map((discount) => (
                          <div key={discount.id} className="d-flex">
                            {profile?.permissions?.includes(USER_PERMISSION_DISCOUNT) ? (
                              <small className="d-flex">
                                <a
                                  href={
                                    discount.is_gift_card ? `/gift-card/${discount.id}/` : `/discount/${discount.id}/`
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                                  {discount.code}
                                </a>
                              </small>
                            ) : (
                              <small>
                                <FontAwesomeIcon icon="fa-solid fa-tags" className="ms-2 me-1" />
                                {discount.code}
                              </small>
                            )}
                          </div>
                        ))}
                      </strong>
                      <span className="text-danger">-{moneyFormat(subscription.discount_cost)}</span>
                    </div>
                  ) : null}
                  {subscription?.total ? (
                    <div
                      className="d-flex justify-content-between mt-3 pt-3"
                      style={{
                        borderTop: 'solid 1px rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      <strong>Total</strong>
                      <span>{moneyFormat(subscription?.total)}</span>
                    </div>
                  ) : null}
                </Card.Body>
              </div>

              <div className="card animate__animated animate__fadeIn animate__delay-2">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h5 className="mb-0 pb-0">Last Orders</h5>
                  <Link
                    target="_blank"
                    className="btn btn-link"
                    to={`/order/?subscription=${subscription.id}&ordering=-created_at`}
                  >
                    Show All Orders
                    <FontAwesomeIcon icon="arrow-right" className="ms-1" />
                  </Link>
                </div>
                <div>
                  <div className="table-responsive w-100">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Created At</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscription?.last_orders?.length === 0 ? (
                          <tr>
                            <th colSpan={4} className="text-muted">
                              No orders found
                            </th>
                          </tr>
                        ) : null}
                        {subscription?.last_orders?.map((order) => {
                          return (
                            <tr key={order.id}>
                              <td>#{order.id}</td>
                              <td>{formatDatetime(order?.created_at)}</td>
                              <td>{moneyFormat(order?.total)}</td>
                              <td className="d-flex justify-content-end">
                                <Link to={`/order/${order.id}`} target="_blank">
                                  <FontAwesomeIcon icon="arrow-right" />
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {width >= breakpointWidth ? <div className="mt-5 mb-3">{internalNoteComponent}</div> : null}
            </div>
            <div className="col-12 col-lg-4">
              <div className="card mb-3 animate__animated animate__fadeIn animate__delay-1">
                <FalconCardHeader title="Customer" />

                <Card.Body className="pb-0 pt-0 mb-1">
                  <div>
                    <strong className="fw-bold">
                      <small>Customer Name</small>
                    </strong>
                    <div>{subscription?.customer_name}</div>
                  </div>
                  <div>
                    <strong className="fw-bold">
                      <small>Phone</small>
                    </strong>
                    <div>
                      <a href={`tel:${subscription?.customer_phone_number}`}>
                        {formatPhoneNumber(subscription?.customer_phone_number)}
                      </a>
                    </div>
                  </div>
                  {subscription?.customer_birthdate ? (
                    <div>
                      <strong className="fw-bold">
                        <small>Birth Date</small>
                      </strong>
                      <div>{moment(subscription?.customer_birthdate, 'YYYY-MM-DD').format(DATE_FORMAT)}</div>
                    </div>
                  ) : null}
                  <div>
                    <strong className="fw-bold">
                      <small>Email</small>
                    </strong>
                    <div>
                      {isShowingEmail ? (
                        <a href={`mailto:${subscription?.customer_email}`}>{subscription?.customer_email}</a>
                      ) : (
                        subscription?.customer_email
                      )}
                      <br />
                      {subscription?.customer_email && !isShowingEmail ? (
                        <button
                          type="button"
                          className="ms-auto btn btn-outline-primary btn-sm"
                          onClick={(e) => {
                            preventDefault(e);
                            setShowCustomerEmailModal(true);
                          }}
                        >
                          Show Email
                        </button>
                      ) : null}
                    </div>
                  </div>
                </Card.Body>
                <hr />
                <Card.Body className="pb-0 pt-0 mb-1">
                  <div className="mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <strong className="fw-bold">
                        <small>Shipping Address</small>
                      </strong>
                      <a
                        className="btn btn-link btn-sm"
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard
                            .writeText(subscription?.recipient)
                            .then(() => toast.success('Copied Address To Clipboard'))
                            .catch(console.error);
                        }}
                      >
                        <FontAwesomeIcon icon="copy" />
                      </a>
                    </div>
                    <div>
                      <div>{subscription?.recipient}</div>
                    </div>
                  </div>
                </Card.Body>
                {subscription?.billing_address1 ? (
                  <>
                    <hr />
                    <Card.Body className="pb-0 pt-0 mb-1">
                      <div className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <strong className="fw-bold">
                            <small>Billing</small>
                          </strong>
                          <a
                            className="btn btn-link btn-sm"
                            onClick={(e) => {
                              preventDefault(e);
                              navigator.clipboard
                                .writeText(
                                  [
                                    subscription?.billing_name,
                                    subscription?.billing_address1,
                                    subscription?.billing_address2,
                                    subscription?.billing_city,
                                    subscription?.billing_state,
                                    subscription?.shipping_zip_code
                                  ]
                                    .filter((part) => part.length > 0)
                                    .join(' ')
                                )
                                .catch(console.error);
                              toast.success('Copied Address To Clipboard');
                            }}
                          >
                            <FontAwesomeIcon icon="copy" />
                          </a>
                        </div>

                        <div>{subscription?.billing_name}</div>
                        <div>
                          {subscription?.billing_address1} {subscription?.billing_address2}
                        </div>
                        <div>
                          {subscription?.billing_city}, {subscription?.billing_state}, {subscription?.billing_zip_code}
                        </div>
                      </div>
                    </Card.Body>
                  </>
                ) : null}
                {subscription?.retailer ? (
                  <>
                    <hr />
                    <Card.Body className="pb-0 pt-0 mb-1">
                      <div className="mb-2">
                        <div>
                          <strong className="fw-bold">
                            <small>Retailer</small>
                          </strong>
                        </div>
                        <div>{subscription?.retailer_name}</div>
                      </div>
                    </Card.Body>
                  </>
                ) : null}
                {subscription?.gift_message_text ? (
                  <>
                    <hr />
                    <Card.Body className="pb-0 pt-0 mb-1">
                      <div className="mb-2">
                        <strong className="fw-bold">
                          <small>Gift Message</small>
                        </strong>
                        <div>
                          <strong>
                            <small>From: </small>
                          </strong>
                          {subscription?.gift_message_sender}
                        </div>
                        <div>
                          <strong>
                            <small>To: </small>
                          </strong>
                          {subscription?.gift_message_recipient}
                        </div>
                        <div>
                          <strong>
                            <small>Message: </small>
                          </strong>
                          {subscription?.gift_message_text}
                        </div>
                      </div>
                    </Card.Body>
                  </>
                ) : null}
              </div>
              {UTM_FIELDS.filter((field) => field in subscription && !!subscription[field]).length > 0 ? (
                <div className="card mb-3 animate__animated animate__fadeIn animate__delay-1">
                  <FalconCardHeader title="UTM Fields" />
                  <>
                    <Card.Body className="pb-0 pt-0 mb-1">
                      <div className="mb-2">
                        {UTM_FIELDS.filter((field) => field in subscription && subscription[field]).map((utmField) => (
                          <div key={utmField}>
                            <strong>
                              <small>{getUTMLabel(utmField)}:</small>
                            </strong>
                            <div>{subscription[utmField]}</div>
                          </div>
                        ))}
                      </div>
                    </Card.Body>
                  </>
                </div>
              ) : null}
              <MarketingWidget order={subscription} />
            </div>

            <div>{width < breakpointWidth ? internalNoteComponent : null}</div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SubscriptionView;
