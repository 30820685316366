import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDatetime } from '../../../services/utils';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/Context';
import { USER_TYPE_ADMIN } from '../../../services/constants';
import ToggleCard from '../../../components/form/ToggleCard';
import { useAxios } from '../../../hooks/useAxios';
import { SimpleTimelineItem } from '../../../components/SimpleTimeline';
import { faCircle, faFlag, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import { LoadingView } from '../../../components/LoadingView';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const EventItem = ({ event }) => {
  const [show, setShow] = useState(false);
  let flagName = faFlag;
  if (event.name === 'purchase') {
    flagName = faFlagCheckered;
  } else if (event.name === 'add_to_cart') {
    flagName = faCircle;
  }
  return (
    <SimpleTimelineItem
      centerBullet={true}
      bullet={
        <div
          className={
            'd-flex justify-content-center align-items-center rounded rounded-circle bg-white border ' +
            'border-gray-300'
          }
          style={{ width: 40, height: 40 }}
        >
          <FontAwesomeIcon icon={flagName} />
        </div>
      }
    >
      <div className="card mb-3 me-3">
        <div className="card-body">
          <div className="mb-1 d-flex align-items-center mb-3">
            <div>
              <div>{event.name} </div>
              <div className="text-muted">
                <small>{formatDatetime(event.created_at)}</small>
              </div>
            </div>
            <div className="flex-1 d-flex justify-content-end">
              <a href="#show-details" onClick={() => setShow(!show)} className="btn btn-sm btn-link">
                {show ? 'Hide details' : 'Show details'}
              </a>
            </div>
          </div>

          <div
            style={{ fontFamily: 'monospace' }}
            className={classNames('d-flex border bg-dark text-300 p-1 rounded', {
              'd-none': !show
            })}
          >
            <pre>{JSON.stringify(event.data, null, 4)}</pre>
          </div>
        </div>
      </div>
    </SimpleTimelineItem>
  );
};

EventItem.propTypes = {
  event: PropTypes.any
};

export const InternalEventWidget = ({ isCheckout = false }) => {
  const { profile } = useContext(AppContext);
  const { id } = useParams();
  const [eventData, setEventData] = useState(null);

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  if (profile.type !== USER_TYPE_ADMIN) {
    return null;
  }

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        isCheckout ? `abandoned-checkout/${id}/internal/events/` : `order/${id}/internal/events/`
      );
      setEventData(response.data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    return abortRequest;
  }, []);

  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-4">
      <ToggleCard title={'Internal Events'} onOpen={() => loadData()}>
        <div className="bg-light pt-3" style={{ maxHeight: 500, overflow: 'auto' }}>
          {isLoading || eventData === null ? (
            <div className="p-3">
              <LoadingView />
            </div>
          ) : (
            <>
              {eventData && eventData?.length === 0 ? (
                <div className="text-muted text-center p-3 mb-3">No events found.</div>
              ) : null}
              {eventData?.map((event, index) => {
                return <EventItem event={event} key={index} />;
              })}
            </>
          )}
        </div>
      </ToggleCard>
    </Card>
  );
};

InternalEventWidget.propTypes = { isCheckout: PropTypes.bool };
