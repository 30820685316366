import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { preventDefault } from '../../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAxios } from '../../../hooks/useAxios';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import TextField from '../../form/TextField';
import classNames from 'classnames';
import * as Yup from 'yup';

const MAX_LENGTH = 30;

export const getFilterKey = (filterDictionary) => {
  const params = [];
  Object.keys(filterDictionary)
    .sort()
    .map((key) => {
      let value = filterDictionary[key];
      if (value instanceof Array) {
        value = value.sort().join(',');
      }
      params.push(`${key}=${value}`);
    });
  return params.join('&');
};

export const TabNameModal = ({ currentTab, show, onHide, onSuccess }) => {
  const validationSchema = Yup.object().shape({
    label: Yup.string().required().label('Label').max(MAX_LENGTH)
  });
  return (
    <Modal show={show} onHide={onHide} keyboard={false}>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={validationSchema}
        initialValues={currentTab}
        onSubmit={async (values, { setSubmitting }) => {
          onSuccess(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <TextField
                helpText={`${values?.label.length}/${MAX_LENGTH}`}
                maxLength={MAX_LENGTH}
                autoFocus={true}
                name="label"
                label="Name"
                disabled={isSubmitting}
              />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                className="ms-3"
                type="button"
                color="primary"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

TabNameModal.propTypes = {
  show: PropTypes.any,
  onHide: PropTypes.any,
  onSuccess: PropTypes.any,
  currentTab: PropTypes.any
};

export const TableTab = ({ name, filters, onFilter, defaultOptions = [] }) => {
  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const { axios, isLoading, setIsLoading } = useAxios();

  const filterData = { ...filters };

  if ('page' in filterData) {
    delete filterData['page'];
  }

  const currentFilterKey = getFilterKey(filterData);

  const saveTabs = async (currentTabList) => {
    try {
      setIsLoading(true);
      const payload = {};
      payload[name] = currentTabList;
      const {
        data: { message }
      } = await axios.post('user-preferences/?name=filter', payload);
      await loadTabs();
      setIsLoading(false);
      toast.success(message, { theme: 'colored' });
    } catch ({ data: { message } }) {
      toast.error(message, {
        theme: 'colored'
      });
    }
  };

  const loadTabs = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get('user-preferences/?name=filter');
      if (data && name in data) {
        setTabs(data[name]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTabs();
  }, [getFilterKey(filterData)]);

  const sortedTabs = useMemo(() => {
    return Object.values(tabs).sort((a, b) => a.order - b.order);
  }, [tabs]);

  const alreadyExists =
    (tabs && sortedTabs.find((option) => getFilterKey(option?.filters) === currentFilterKey)) ||
    defaultOptions?.find((option) => getFilterKey(option?.filters) === currentFilterKey);

  const showSaveFilter = Object.keys(filterData).length > 0 && !alreadyExists;

  return (
    <>
      <TabNameModal
        show={currentTab}
        currentTab={currentTab}
        onHide={() => setCurrentTab(null)}
        onSuccess={(tabData) => {
          setCurrentTab(null);
          const payload = { ...tabs };
          payload[getFilterKey(tabData?.filters)] = tabData;
          setTabs(payload);
          saveTabs(payload);
        }}
        name="example"
      />
      <div className="d-flex flex-wrap align-items-center w-100">
        {defaultOptions.map((config) => {
          const isActive = getFilterKey(config.filters) === currentFilterKey;
          return (
            <Button
              disabled={isLoading}
              key={`default_${getFilterKey(config.filters)}`}
              type="button"
              onClick={(e) => {
                preventDefault(e);
                onFilter(config.filters);
              }}
              className="me-1"
              variant={isActive ? 'primary' : 'light'}
              size="sm"
              title={config.label}
              id={`id_${getFilterKey(config.filters)}`}
            >
              {config.label}
            </Button>
          );
        })}
        {sortedTabs.map((tab) => {
          const isActive = getFilterKey(tab.filters) === currentFilterKey;
          return isActive ? (
            <Dropdown key={`option_${getFilterKey(tab.filters)}`} className="me-1">
              <Dropdown.Toggle
                variant="primary"
                size="sm"
                title={tab.label}
                id={`id_${getFilterKey(tab.filters)}`}
                disabled={isLoading}
              >
                <span>{tab.label.substring(0, MAX_LENGTH)}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="custom-tab-menu">
                <Dropdown.Item
                  href="#"
                  onClick={(e) => {
                    preventDefault(e);
                    setCurrentTab(tab);
                  }}
                >
                  <FontAwesomeIcon icon="i-cursor" className="me-1" style={{ width: 10 }} />
                  Change Name
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger d-flex align-items-center"
                  href="#"
                  onClick={(e) => {
                    preventDefault(e);
                    const payload = { ...tabs };
                    if (getFilterKey(tab.filters) in payload) {
                      delete payload[getFilterKey(tab.filters)];
                    }
                    setTabs(payload);
                    saveTabs(payload);
                  }}
                >
                  <FontAwesomeIcon icon="trash" className="me-1" style={{ width: 10 }} />
                  <span>Delete</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              disabled={isLoading}
              type="button"
              onClick={(e) => {
                preventDefault(e);
                onFilter(tab.filters);
              }}
              className="me-1"
              variant={isActive ? 'primary' : 'light'}
              size="sm"
              title={tab.label}
              id={`id_${getFilterKey(tab.filters)}`}
              key={`option_${getFilterKey(tab.filters)}`}
            >
              <span>{tab.label.substring(0, MAX_LENGTH)}</span>
            </Button>
          );
        })}

        <div className="ms-auto d-flex align-items-center">
          {isLoading ? (
            <Spinner title="Loading Filters" animation="border" className="text-muted me-2 ms-2" size="sm" />
          ) : null}
          {showSaveFilter ? (
            <a
              href="#"
              className={classNames('btn btn-light btn-sm', {
                disabled: isLoading
              })}
              onClick={(e) => {
                preventDefault(e);
                setCurrentTab({ label: '', filters: filterData, key: getFilterKey(filterData) });
              }}
            >
              Save Filter
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};

TableTab.propTypes = {
  name: PropTypes.any,
  onFilter: PropTypes.func,
  filters: PropTypes.any,
  defaultOptions: PropTypes.any
};
