import { useField } from 'formik';

import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import classNames from 'classnames';

export const CurrencyField = ({
  label,
  showLabel = true,
  disabled,
  required = false,
  placeholder = undefined,
  className = 'mb-2',
  ...props
}) => {
  const [{ name, value, onBlur }, meta, { setValue }] = useField(props);
  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        {showLabel && label && (
          <label htmlFor={name} className="text-capitalize">
            {label} {required && <RequiredMarker />}
          </label>
        )}
      </div>
      <CurrencyInput
        allowNegativeValue={false}
        prefix="$"
        disabled={disabled}
        placeholder={placeholder ? placeholder : 'Please enter a number...'}
        decimalsLimit={2}
        className="form-control"
        name={name}
        value={value !== undefined ? value : ''}
        onValueChange={(value) => setValue(value ? value : '')}
        onBlur={onBlur}
      />

      <FormikError name={name} meta={meta} />
    </div>
  );
};

CurrencyField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool
};

export const RawCurrencyField = ({ disabled, placeholder, className, required = false, ...props }) => {
  const [{ name, value, onBlur }, meta, { setValue }] = useField(props);
  return (
    <CurrencyInput
      allowNegativeValue={false}
      prefix="$"
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      decimalsLimit={2}
      className={classNames(`form-control ${className ? className : ''}`, {
        'is-valid': !meta?.error,
        'is-invalid': meta?.error
      })}
      name={name}
      value={value}
      onValueChange={(value) => setValue(value)}
      onBlur={onBlur}
    />
  );
};

RawCurrencyField.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.any,
  className: PropTypes.any
};

export default CurrencyField;
