/*eslint camelcase: ["error", {allow: []}]*/
import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAxios } from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { FILE_UPLOAD_MAX_SIZE, IMAGE_LARGE_ERROR_MESSAGE } from '../../utils';

const QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image']
  ]
};
const QUILL_FORMATS = ['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link', 'image'];
export const QuillInput = ({ name, onKeyUp, value, setValue, disabled = false, ...props }) => {
  const quillRef = useRef(null);
  const { axios } = useAxios();

  useEffect(() => {
    // toast.error('An unexpected error occurred while uploading the file', { autoClose: 1000323 });
    const editor = quillRef.current.getEditor();
    editor.getModule('toolbar').addHandler('image', async () => {
      const input = document.createElement('input');

      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');

      input.click();

      input.onchange = async () => {
        const file = input.files[0];

        const size = file?.size / 1024 / 1024;
        if (size > FILE_UPLOAD_MAX_SIZE) {
          toast.error(IMAGE_LARGE_ERROR_MESSAGE);
          return;
        }

        const formData = new FormData();

        formData.append('image', file);
        try {
          const { data } = await axios.post('upload-image/', formData);
          editor.insertEmbed(editor.getSelection().index, 'image', data?.url);
        } catch ({ response: { data } }) {
          toast.error(data?.message ? data?.message : 'An unexpected error occurred while uploading the file');
        } finally {
          input.remove();
        }
      };
    });
  }, [quillRef]);
  useEffect(() => {
    const toolbar = quillRef.current.editor.getModule('toolbar');
    const toolbarElement = toolbar.container;
    toolbarElement.classList.toggle('ql-toolbar-disabled', disabled);
  }, [disabled]);

  return (
    <ReactQuill
      id={`id_${name}`}
      onKeyUp={onKeyUp}
      readOnly={disabled}
      ref={quillRef}
      modules={QUILL_MODULES}
      formats={QUILL_FORMATS}
      theme="snow"
      value={value}
      onChange={(e) => {
        setValue(e);
      }}
      {...props}
    />
  );
};

QuillInput.propTypes = {
  disabled: PropTypes.any,
  onKeyUp: PropTypes.any,
  value: PropTypes.any,
  name: PropTypes.any,
  setValue: PropTypes.any
};
