/*eslint camelcase: ["error", {allow: ["action_req","engraving_lines","checkout_url","new_order","customer_email","brand_list","first_name", "tracking_url", "item_id", "order_id","order_number","customer_name","customer_phone_number","shipping_address1","shipping_city","shipping_zip","shipping_state","source_meta_data","tracking_number_url","shipping_cost","tax_rate","tax_amount",]}]*/
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import { preventDefault } from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '../../../components/form/TextField';
import { TextInput } from '@mantine/core';
import { formatDatetime } from '../../../services/utils';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FaCcDiscover, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { SiAmericanexpress } from 'react-icons/si';
import { BsCreditCard } from 'react-icons/bs';
import { USER_TYPE_ADMIN } from '../../../services/constants';
import AppContext from '../../../context/Context';
import PaymentStatusBadge from '../../../components/PaymentStatusBadge';

export const CardBrand = ({ brand }) => {
  if (brand === 'visa') {
    return <FaCcVisa className="fs-2" />;
  } else if (brand === 'mastercard') {
    return <FaCcMastercard className="fs-2" />;
  } else if (brand === 'amex') {
    return <SiAmericanexpress className="fs-2" />;
  } else if (brand === 'discover') {
    return <FaCcDiscover className="fs-2" />;
  } else {
    return <BsCreditCard className="fs-2" />;
  }
};

CardBrand.propTypes = {
  brand: PropTypes.any
};

export const PaymentMethodWidget = ({ order }) => {
  const { profile } = useContext(AppContext);
  if (order?.total <= 0) {
    return null;
  }
  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-4">
      <ToggleCard
        title={'Payment Method'}
        endElement={
          <>
            {profile?.type === USER_TYPE_ADMIN && order?.stripe_url && (
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={'tooltip-payment-method'}>Open Stripe URL</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={(e) => {
                    preventDefault(e);
                    window.open(order?.stripe_url, '_blank');
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-eye" />
                </button>
              </OverlayTrigger>
            )}
          </>
        }
      >
        <Card.Body className="bg-light">
          <div className="row">
            <div className="col-sm-12">
              {order?.card_holder ? (
                <Row className="g-3">
                  <Col lg="12">
                    <TextField label="Card Holder" name="card_holder" disabled={true} />
                  </Col>
                </Row>
              ) : null}
              <Row className="g-3">
                <Col lg="12">
                  <TextInput
                    label="Card Details"
                    name="card_last4_digits"
                    value={order?.card_last4_digits || ''}
                    placeholder="Card Details..."
                    rightSection={<CardBrand brand={order?.card_brand} />}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className="g-3">
                <Col lg="6">
                  <TextField label="Card Code" name="card_code" value="***" disabled={true} />
                </Col>
                <Col lg="6">
                  <TextField label="Card Expiration" name="card_exp_date" disabled={true} />
                </Col>
              </Row>
              <Row className="g-3">
                <Col lg="12">
                  <TextField label="Message" name="card_payment_status" disabled={true} />
                </Col>
              </Row>
              <Row className="h6">
                <Col>
                  <b>Status: </b>
                  <PaymentStatusBadge paymentStatus={order?.card_payment_status} />
                </Col>
                <Col className="mt-1">
                  <b>Paid At: </b> <br />
                  {order?.payment_created_at ? formatDatetime(order?.payment_created_at) : '-'}
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </ToggleCard>
    </Card>
  );
};

PaymentMethodWidget.propTypes = {
  order: PropTypes.any
};
