import React from 'react';
import PropTypes from 'prop-types';

export const Avatar = ({ size, rounded, src, className, mediaClass, style }) => {
  return (
    <div className={['avatar', `avatar-${size}`, className].join(' ')} style={style ? style : {}}>
      {src ? (
        <img className={[rounded ? `rounded-${rounded}` : 'rounded', mediaClass].join(' ')} src={src} alt="" />
      ) : null}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.any
};

Avatar.defaultProps = {
  size: 'xl',
  rounded: 'circle'
};

export const Thumbnail = ({ src, className, style = {} }) => {
  return (
    <div
      style={{ backgroundImage: `url(${src})`, ...style }}
      className={`invisible-thumbnail ${className ? className : ''}`}
    ></div>
  );
};

Thumbnail.propTypes = {
  src: PropTypes.string,
  style: PropTypes.any,
  className: PropTypes.string
};

export default Avatar;
