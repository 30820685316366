import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const IntegerField = ({
  label,
  required = false,
  showLabel = true,
  className = 'mb-2',
  informativeText,
  ...props
}) => {
  const [{ value, ...field }, meta, { setTouched }] = useField(props);

  return (
    <div className={`${className ? className : ''}`}>
      <div className="d-flex align-items-center">
        {showLabel && label && (
          <label htmlFor={field.name} className="text-capitalize">
            {label} {required && <RequiredMarker />}
            {informativeText && (
              <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose
                overlay={
                  <Popover>
                    <Popover.Body>{informativeText}</Popover.Body>
                  </Popover>
                }
              >
                <a className="ms-1">
                  <FontAwesomeIcon icon="info-circle" />
                </a>
              </OverlayTrigger>
            )}
          </label>
        )}
      </div>

      <input
        className={`form-control shadow-none ${field.className && meta.touched && meta.error && 'is-invalid'}`}
        required={required}
        placeholder={label + '...'}
        type="number"
        step={1}
        autoComplete="new-password"
        value={value || value === 0 ? parseInt(value) : ''}
        onChange={(e) => {
          setTouched(true);
          field?.onChange(e);
        }}
        {...field}
        {...props}
      />

      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

IntegerField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  showLabel: PropTypes.bool,
  informativeText: PropTypes.string
};

IntegerField.defaultProps = {
  label: ''
};

export default IntegerField;
