import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { Draggable } from 'react-beautiful-dnd';
import { formatDatetime } from '../../../services/utils';

export const BrandCard = ({ brand, index, setSelectedTask, type }) => {
  const getItemStyle = (isDragging) => ({
    cursor: isDragging ? 'grabbing' : 'pointer',
    transform: isDragging ? 'rotate(-2deg)' : ''
  });

  const stage = brand.stage_name ? brand.stage_name : 'No Stage';
  const provider = brand.provider_name ? brand.provider_name : 'No Provider';

  const badgeName = type !== 'stage' ? stage : provider;

  return (
    <Draggable draggableId={`task${brand?.id}`} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className="kanban-item"
        >
          <Card
            style={getItemStyle(snapshot.isDragging)}
            className="kanban-item-card hover-actions-trigger"
            onClick={() => setSelectedTask(brand?.id)}
          >
            <Card.Body>
              <div className="mb-2 fw-medium font-sans-serif">{brand?.name}</div>
              {brand?.last_updated_at && (
                <div className="mb-0 fw-medium font-sans-serif stretched-link">
                  <strong>Updated at:</strong> {formatDatetime(brand?.last_updated_at)}
                </div>
              )}

              <div className="kanban-item-footer cursor-default">
                <div className="text-500 ">
                  {badgeName ? (
                    <SoftBadge bg={'info'} className="py-1 me-1 mb-1">
                      {badgeName}
                    </SoftBadge>
                  ) : null}
                </div>

                <div>
                  {brand?.has_order_last_month === false && (
                    <FontAwesomeIcon icon={'exclamation-triangle'} size={'lg'} className="me-2 text-danger" />
                  )}
                  <FontAwesomeIcon icon={'star'} /> {brand?.value ? brand?.value : 0}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

BrandCard.propTypes = {
  type: PropTypes.any,
  brand: PropTypes.any,
  setOpenTaskInformationModal: PropTypes.func,
  setSelectedTask: PropTypes.func,
  index: PropTypes.number,
  filters: PropTypes.any
};
