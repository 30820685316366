/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();

  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Form.Check type="checkbox" class Name="form-check fs-0 mb-0 d-flex align-items-center">
      <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
    </Form.Check>
  );
});

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  hiddenColumns,
  pageSize,
  pageCount,
  onSort,
  initialSortBy
}) => {
  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, selectedRowIds, globalFilter, sortBy },
    setGlobalFilter,
    toggleAllRowsSelected,
    setHiddenColumns
  } = useTable(
    {
      manualSortBy: true,
      autoResetPage: false,
      manualPagination: true,
      columns,
      data,
      pageCount: pageCount,
      disableSortBy: !sortable,
      disableMultiSort: false,
      autoResetSortBy: false,
      initialState: {
        sortBy: initialSortBy,
        hiddenColumns
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);
      }
    }
  );

  useEffect(() => onSort && onSort(sortBy), [sortBy]);
  useEffect(() => setHiddenColumns(hiddenColumns), [hiddenColumns]);

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      if (!child) {
        return;
      }
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter,
            toggleAllRowsSelected
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
