import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import TextField from '../../../components/form/TextField';
import { useFormikContext } from 'formik';

export const GiftMessageWidget = ({ id, order, disabled }) => {
  const { isSubmitting } = useFormikContext();
  const isReadOnly = (id && !['new_order', 'action_req'].includes(order?.status)) || disabled;
  const hasData = order?.gift_message_sender || order?.gift_message_recipient || order?.gift_message_text;

  return (
    <Card className="mb-3 animate__animated animate__fadeIn animate__delay-5">
      <ToggleCard title="Gift Message" open={!!hasData}>
        <Card.Body className="bg-light">
          <div className="row">
            <div className="col-sm-12">
              <Row className="mb-3 g-3">
                <Col lg="12">
                  <TextField
                    label="Gift Message Sender"
                    name="gift_message_sender"
                    disabled={isSubmitting || isReadOnly}
                  />
                </Col>
                <Col lg="12">
                  <TextField
                    label="Gift Message Recipient"
                    name="gift_message_recipient"
                    disabled={isSubmitting || isReadOnly}
                  />
                </Col>
                <Col lg="12">
                  <TextField
                    label="Gift Message Text"
                    name="gift_message_text"
                    disabled={isSubmitting || isReadOnly}
                    type="textarea"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </ToggleCard>
    </Card>
  );
};

GiftMessageWidget.propTypes = {
  disabled: PropTypes.bool,
  order: PropTypes.any,
  id: PropTypes.any
};
