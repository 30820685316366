/*eslint camelcase: ["error", {allow: ["gift_card",]}]*/
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TIME_UNITS } from '../services/constants';

dayjs.extend(duration);

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, payload);

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

export const capitalize = (str) => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const dashed = (str) => {
  return str.toLowerCase().replaceAll(' ', '-');
};

export const sentryCapture = (e) => {
  console.error(e);
};

export const COUNT_UP_DURATION = 2; // in seconds

// Helper to annotate the correct kanban item column on the brand
export const withColumnId = (type) => (brand) => {
  let columnId = type === 'stage' ? brand?.stage : brand?.provider;
  if (columnId === null) {
    columnId = 0;
  }
  return { ...brand, columnId };
};

export const getRedirectTo = (permissions) => {
  const pages = ['dashboard', 'order', 'report', 'customer', 'product', 'brand', 'discount', 'gift_card', 'settings'];
  const permissionKey = pages.find((p) => permissions?.includes(p));
  if (permissionKey) {
    return {
      dashboard: '/',
      order: '/order',
      report: '/reports',
      customer: '/customer',
      product: '/product',
      brand: '/brand',
      discount: '/discount',
      gift_card: '/gift-card',
      settings: '/settings/retailer'
    }[permissionKey];
  } else {
    return '/profile';
  }
};

export const formatTimestamp = (timestamp) => {
  const now = Date.now();
  let diff = Math.abs(now - new Date(timestamp).getTime());

  const formatUnit = (value, unit) => `${value} ${unit}${value !== 1 ? 's' : ''}`;

  const formattedTime = TIME_UNITS.reduce((result, { unit, ms }, index) => {
    const value = Math.floor(diff / ms);
    if (value === 0) return result;

    diff -= value * ms; // Subtract the value in the current unit from diff

    const formattedValue = formatUnit(value, unit);
    const separator = result.length ? ', ' : '';

    return index === 0 || result.split(', ').length < 2 ? `${result}${separator}${formattedValue}` : result;
  }, '');

  return formattedTime ? `${formattedTime} ago` : 'just now';
};
