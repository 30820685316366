import React from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormikError } from './FormikError';
import DatePickerField from './DatePickerField';

export const StartDateEndDateField = ({ ...props }) => {
  const { name } = props;
  const [meta] = useField(props);

  return (
    <div>
      <div className="d-flex" style={{ gap: 10 }}>
        <DatePickerField
          className=""
          showTimeSelect={false}
          dateFormat={'MM/dd/yyyy'}
          inputFormat="YYYY-MM-DD"
          label="Start Date"
          name={`${name}_min`}
          required={false}
          disabled={false}
        />
        <DatePickerField
          className=""
          showTimeSelect={false}
          dateFormat={'MM/dd/yyyy'}
          inputFormat="YYYY-MM-DD"
          label="End Date"
          name={`${name}_max`}
          required={false}
          disabled={false}
        />
      </div>
      <FormikError name={name} meta={meta} />{' '}
    </div>
  );
};

StartDateEndDateField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string
};

export default StartDateEndDateField;
