import React from 'react';
import RemoveFilterButton from './form/RemoveFilterButton';
import PropTypes from 'prop-types';

export const FilterRow = ({ children, name, className = '', showRemoveButton = true }) => {
  return (
    <div className={`d-flex align-items-end w-100 mt-3 ${className}`}>
      <div className="flex-fill">{children}</div>
      {showRemoveButton && <RemoveFilterButton names={name} />}
    </div>
  );
};

FilterRow.propTypes = {
  showRemoveButton: PropTypes.bool,
  children: PropTypes.any,
  name: PropTypes.any,
  className: PropTypes.string
};
