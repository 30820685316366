/*eslint camelcase: ["error", {allow: ["created_at","type_display","status_display","started_at"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { GenericListTable } from '../../components/common/ListTable/GenericListTable';
import { useFilters } from '../../hooks/useFilters';
import { CanceledError } from 'axios';
import { useAxios } from '../../hooks/useAxios';
import { FilterRow } from '../../components/FilterRow';
import { SimpleSelectField } from '../../components/form/SelectField';
import {
  AFFILIATE_EXPORT_TYPES,
  EXPORT_CANCELED,
  EXPORT_FAILED,
  EXPORT_GENERATED,
  EXPORT_PENDING,
  EXPORT_STATUS,
  EXPORT_TYPES,
  formatDatetime
} from '../../services/utils';
import Avatar from '../../components/common/Avatar';
import avatar from '../../assets/img/team/avatar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import { USER_PERMISSION_ACCOUNT, USER_TYPE_AFFILIATE } from '../../services/constants';
import AppContext from '../../context/Context';
import PropTypes from 'prop-types';
import { preventDefault } from '../../utils';
import { toast } from 'react-toastify';

const URL_BY_TYPE = {
  customer: '/customer/',
  brand: '/brand/',
  product: '/product/',
  order: '/order/',
  discount: '/discount/',
  'gift-card': '/gift-card/',
  'abandoned-checkout': '/abandoned-checkout/'
};

const PermissionLink = ({ children, href, hasPermission, ...props }) => {
  if (hasPermission) {
    return (
      <a target="_blank" href={href} rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return <div {...props}>{children}</div>;
};
PermissionLink.propTypes = {
  children: PropTypes.any,
  href: PropTypes.any,
  hasPermission: PropTypes.any
};

const ExportsList = () => {
  useTitle('Exports | Bottle Nexus');
  const { profile } = useContext(AppContext);
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [responseData, setResponseData] = useState(null);
  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();

  const handleCancel = async (exportId) => {
    setIsLoading(true);
    try {
      await axios.post(`export/${exportId}/cancel/`);
    } catch (e) {
      if (e.response.data?.message) {
        toast.error(e.response.data?.message);
      }
    }
    await loadExports();
    setIsLoading(false);
  };

  const fields = [
    {
      accessor: 'id',
      Header: 'ID',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return <div className="d-flex align-items-center text-dark fw-normal">{id}</div>;
      }
    },
    {
      accessor: 'user',
      Header: 'Exported By',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { user } = rowData.row.original;
        return (
          <div className="d-flex align-items-center text-dark fw-normal">
            {user ? (
              <PermissionLink
                className="d-flex align-items-center"
                hasPermission={profile?.permissions.includes(USER_PERMISSION_ACCOUNT)}
                href={`/settings/user/${user.id}`}
              >
                <Avatar size="2xl" src={user?.photo ? user?.photo : avatar} className="me-2" />
                <span>
                  {user?.first_name} {user?.last_name}
                </span>
              </PermissionLink>
            ) : (
              <>
                <Avatar size="2xl" src={avatar} className="me-2" />
              </>
            )}
          </div>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: (rowData) => {
        const { status, file, status_display, id } = rowData.row.original;
        return (
          <div className="d-flex">
            <div className="d-flex align-items-center text-dark fw-normal">
              {file ? (
                <a
                  href={file}
                  download={file || 'download'}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary btn-sm text-decoration-none me-3"
                >
                  <FontAwesomeIcon icon="fas fa-download" /> Download
                </a>
              ) : [EXPORT_GENERATED].includes(status) ? (
                status_display
              ) : null}

              {[EXPORT_PENDING, EXPORT_CANCELED].indexOf(status) !== -1 && (
                <>
                  {status !== EXPORT_CANCELED ? (
                    <Spinner animation="border" role="status" size={'sm'} variant="info">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : null}

                  <span className="ms-1">{status_display}</span>
                </>
              )}

              {status === EXPORT_FAILED && (
                <>
                  <FontAwesomeIcon
                    className="text-danger"
                    icon="exclamation"
                    transform="shrink-3"
                    aria-label="Filters"
                  />
                  <span className="ms-2">{status_display}</span>
                </>
              )}

              {[EXPORT_PENDING].includes(status) ? (
                <button
                  onClick={async (e) => {
                    preventDefault(e);
                    await handleCancel(id);
                  }}
                  type="button"
                  className="btn btn-outline-danger btn-sm text-decoration-none ms-3 d-flex align-items-center"
                >
                  <FontAwesomeIcon icon="times-circle" />
                  &nbsp;Cancel
                </button>
              ) : null}
            </div>
          </div>
        );
      }
    },
    {
      accessor: 'created_at',
      Header: 'Requested At',
      headerProps: { className: 'pe-1' },
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return (
          <>
            <div className="text-dark fw-normal">{formatDatetime(created_at)}</div>
          </>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      Cell: (rowData) => {
        const { type_display } = rowData.row.original;
        return (
          <>
            <div className="text-dark fw-normal">{type_display}</div>
          </>
        );
      }
    },
    {
      accessor: 'filters',
      Header: 'Filters',
      disableSortBy: true,
      Cell: (rowData) => {
        let { filters, type } = rowData.row.original;
        const encodedFilters = new URLSearchParams();
        filters = JSON.parse(filters);
        Object.keys(filters).map((key) => {
          const value = filters[key];
          if (_.isArray(value)) {
            for (const eachValue of value) {
              encodedFilters.append(key, eachValue);
            }
          } else {
            encodedFilters.append(key, value);
          }
        });

        const url = URL_BY_TYPE[type];

        return (
          <>
            {filters ? (
              <a
                className="btn btn-outline-primary btn-sm text-decoration-none"
                target="_blank"
                href={`${url}?${encodedFilters.toString()}`}
                rel="noreferrer"
              >
                View
              </a>
            ) : (
              '-'
            )}
          </>
        );
      }
    }
  ];

  const filterComponent = (
    <>
      <FilterRow name="status">
        <SimpleSelectField
          options={EXPORT_STATUS}
          label="Export Status"
          name="status"
          isMulti={true}
          className="mb-0"
        />
      </FilterRow>
      <FilterRow name="type">
        <SimpleSelectField
          options={profile?.type === USER_TYPE_AFFILIATE ? AFFILIATE_EXPORT_TYPES : EXPORT_TYPES}
          label="Export Type"
          name="type"
          isMulti={true}
          className="mb-0"
        />
      </FilterRow>
    </>
  );

  const loadExports = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('export/', { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      // only hide loading if the error isn't a cancel to a new request
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadExports().catch(console.error);
    return abortRequest;
  }, [filters]);

  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Export Requests'}
        name="export"
        search={search}
        onSearch={onSearch}
        filterComponent={filterComponent}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
      />
    </>
  );
};
export default ExportsList;
