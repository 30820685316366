import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import { formatPhoneNumber } from '../../services/utils';
import { COUNTRY_AREA_CODES } from '../../utils';

export const PhoneField = ({
  label,
  country = 'US',
  required = false,
  freeForm = false,
  extraClass = 'mb-2',
  ...props
}) => {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  const autoComplete = `custom-phone-${new Date().getTime()}`;

  const actualValue = useMemo(() => {
    if (freeForm) return value || '';
    if (value) return formatPhoneNumber(value, country);
    return '';
  }, [value]);

  const handleValueChange = (e) => {
    if (freeForm) setValue(e.target.value);
    else setValue(formatPhoneNumber(e.target.value, country));
  };

  return (
    <div className={extraClass}>
      <div className="d-flex align-items-center">
        {label && (
          <label htmlFor={field.name} className="text-capitalize">
            {label} {required && <RequiredMarker />}
          </label>
        )}
      </div>
      <div className="input-group">
        {!freeForm && country && (
          <span className="input-group-text" id={`prepend_${field.name}`}>
            {COUNTRY_AREA_CODES[country]}
          </span>
        )}
        <input
          maxLength={255}
          className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
          required={required}
          placeholder={label + '...'}
          value={actualValue}
          {...field}
          {...props}
          onChange={handleValueChange}
          autoComplete={autoComplete}
        />
      </div>

      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

PhoneField.propTypes = {
  label: PropTypes.string,
  extraClass: PropTypes.string,
  country: PropTypes.string,
  freeForm: PropTypes.bool,
  required: PropTypes.bool
};

PhoneField.defaultProps = {
  label: ''
};

export default PhoneField;
