import React, { useContext, useEffect, useState } from 'react';
import { GenericListTable } from '../../../components/common/ListTable/GenericListTable';
import { Link, useNavigate } from 'react-router-dom';
import { cellAsDatetime } from '../../../services/utils';
import { useFilters } from '../../../hooks/useFilters';
import { CanceledError } from 'axios';
import { useAxios } from '../../../hooks/useAxios';
import { useStaticTitle } from '../../../hooks/usePageTitle';
import { USER_PERMISSION_SETTING_EDIT } from '../../../services/constants';
import AppContext from '../../../context/Context';

const PortfolioList = () => {
  useStaticTitle('Portfolios');

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [responseData, setResponseData] = useState(null);

  const { search, onSearch, sortBy, onSortBy, filters, onFilter, onPaginate, clearFilter } = useFilters();

  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  const fields = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/portfolio/${rowData.row.original.id}`}>
          {rowData.row.original.name}
        </Link>
      )
    },
    {
      accessor: 'created_at',
      Header: 'Created At',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/portfolio/${rowData.row.original.id}`}>
          {cellAsDatetime(rowData, 'created_at')}
        </Link>
      )
    },
    {
      accessor: 'updated_at',
      Header: 'Updated At',
      Cell: (rowData) => (
        <Link className="fs--1 mb-0 text-dark fw-normal" to={`/settings/portfolio/${rowData.row.original.id}`}>
          {cellAsDatetime(rowData, 'updated_at')}
        </Link>
      )
    }
  ];

  const loadData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`portfolio/`, { params: filters });
      setResponseData(response?.data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, [filters]);

  const rowOptions = [
    {
      label: 'Edit',
      icon: 'pencil-alt',
      onClick: (obj) => navigate(`/settings/portfolio/${obj?.id}/`),
      hasPermission: () => {
        return true;
      }
    }
  ];

  return (
    <>
      <GenericListTable
        onPaginate={onPaginate}
        title={'Portfolio'}
        name="category"
        addRoute={profile?.permissions?.includes(USER_PERMISSION_SETTING_EDIT) ? '/settings/portfolio/create/' : null}
        search={search}
        onSearch={onSearch}
        filters={filters}
        onFilter={onFilter}
        clearAllFilters={clearFilter}
        rowOptions={rowOptions}
        columnsList={fields}
        data={responseData}
        sortBy={sortBy}
        onSort={onSortBy}
        isLoading={!responseData || isLoading}
        // module="portfolio"
      />
    </>
  );
};

export default PortfolioList;
