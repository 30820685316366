/*eslint camelcase: ["error", {allow: ["position_title","phone_number","task_due_date", "task_start_date", "task_priority", "user_id", "order_id", "num_pages", "task_assignee","converted_time"]}]*/
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/Context';
import PropTypes from 'prop-types';
import { asFormData, formatDatetime, formatDateWithMoment, USER_TYPE_ADMIN } from '../../../services/utils';
import { useAxios } from '../../../hooks/useAxios';

import { CanceledError } from 'axios';
import { toast } from 'react-toastify';
import { Timeline } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { chunkString } from '../../../utils';
import * as DOMPurify from 'dompurify';
import { ALLOWED_TAGS, USER_PERMISSION_BRAND_EDIT } from '../../../services/constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FalconCloseButton from '../../../components/common/FalconCloseButton';
import TextField from '../../../components/form/TextField';
import AsyncSelectField from '../../../components/form/SelectField';
import DatePickerField from '../../../components/form/DatePickerField';
import { LoadingView } from '../../../components/LoadingView';

const DATE_FORMAT = 'YYYY-MM-DD';

export const JiraTaskSection = ({ brand, className = 'mb-3' }) => {
  const { profile } = useContext(AppContext);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;
  const isReadOnly = !profile?.permissions?.includes(USER_PERMISSION_BRAND_EDIT);
  const [opened, setOpened] = useState(false);

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();

  const [assignees, setAssignees] = useState(null);

  const loadAssignees = async () => {
    const response = await axios.get('/dropdown/jira-assignee/');
    setAssignees(response?.data?.results);
  };

  const [selectedTask, setSelectedTask] = useState(null);

  const [response, setResponse] = useState([]);

  const loadData = async () => {
    if (!brand) return;
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/brand/${brand.id}/tasks/?page_size=1000`);
      setResponse(data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  const closeModal = () => {
    setSelectedTask(null);
  };

  useEffect(() => {
    if (opened) {
      loadData().catch(console.error);
      loadAssignees().catch(console.error);
    }
    return abortRequest;
  }, [brand, opened]);

  if (!brand || !isAdmin) {
    return null;
  }

  return (
    <>
      {selectedTask ? <div style={{ opacity: 0.5 }} className="modal-backdrop w-100 h-100"></div> : null}
      <div className={`card ${className ? className : ''}`}>
        <ToggleCard title="Tasks" open={false} onOpen={() => setOpened(true)} onClose={() => setOpened(false)}>
          <div>
            <Modal show={selectedTask} onHide={closeModal} size="lg">
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={Yup.object({
                  summary: Yup.string().required().label('Summary')
                })}
                initialValues={selectedTask?.id ? selectedTask : { summary: '' }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  try {
                    const payload = asFormData({ ...values, brand: brand.id });
                    await axios.post(`/brand/${brand.id}/tasks/`, payload);
                    await loadData();
                    setSelectedTask(null);
                    setSubmitting(false);
                  } catch ({ response }) {
                    if (response?.data?.message) {
                      const message = Array.isArray(response.data.message)
                        ? response.data.message[0]
                        : response.data.message;
                      toast.error(message);
                    }
                    setSubmitting(false);
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Header>
                      <Modal.Title>{selectedTask?.id ? 'Change Task' : 'Add Task'}</Modal.Title>
                      <FalconCloseButton onClick={closeModal} />
                    </Modal.Header>

                    <Modal.Body>
                      <div className="row">
                        <div className={'col-12 col-lg-8'}>
                          <TextField
                            name="summary"
                            label="Summary"
                            required={true}
                            disabled={isSubmitting || isReadOnly}
                            placeholder="Task Summary"
                          />
                          <TextField
                            name="description"
                            type="textarea"
                            label="Description"
                            disabled={isSubmitting || isReadOnly}
                            placeholder="Task Description"
                          />
                        </div>
                        <div className="col-12 col-lg-4">
                          <DatePickerField
                            inputFormat={DATE_FORMAT}
                            showTimeSelect={false}
                            required={false}
                            label="Start Date"
                            name="start_date"
                            disabled={isSubmitting || isReadOnly}
                          />
                          <DatePickerField
                            inputFormat={DATE_FORMAT}
                            showTimeSelect={false}
                            required={false}
                            label="Due Date"
                            name="due_date"
                            disabled={isSubmitting || isReadOnly}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className={'col-12 col-lg-8'}>
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <AsyncSelectField
                                label="Reporter"
                                name="reporter"
                                disabled={isSubmitting || isReadOnly}
                                urlEndpoint="/dropdown/jira-assignee/"
                              />
                            </div>
                            <div className="col-12 col-lg-6">
                              <AsyncSelectField
                                label="Assignee"
                                name="assignee"
                                disabled={isSubmitting || isReadOnly}
                                urlEndpoint="/dropdown/jira-assignee/"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <Button variant="default" onClick={closeModal} disabled={isSubmitting}>
                        Close
                      </Button>
                      <Button className="ms-3" color="primary" disabled={isSubmitting} type="submit">
                        Save
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>
            {isLoading ? (
              <div className="card-body">
                <LoadingView />
              </div>
            ) : (
              <Card.Body>
                {!isReadOnly && (
                  <button
                    type="button"
                    className="btn btn-outline-primary mb-3"
                    disabled={isReadOnly}
                    onClick={() => {
                      setSelectedTask({});
                    }}
                  >
                    Add Task
                  </button>
                )}
                <div>
                  <Timeline bulletSize={25} lineWidth={3} style={{ paddingLeft: 24 }}>
                    {response?.results?.map((task, index) => {
                      const assignee = assignees?.find((a) => a.value === task.assignee);
                      return (
                        <Timeline.Item
                          key={`${task.id}-${index}`}
                          className="animate animate__fadeIn"
                          title={
                            <div className="ms-3 d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                {task?.task_url ? (
                                  <a
                                    className="mb-0 fw-normal me-1"
                                    target="_blank"
                                    href={task?.task_url}
                                    rel="noreferrer"
                                  >
                                    {task.key} {task?.summary}
                                  </a>
                                ) : (
                                  <>
                                    {task.key} {task?.summary}
                                  </>
                                )}
                                <small className="text-muted ms-2">{formatDatetime(task.created_at)}</small>
                              </div>
                              <p className="text-muted">
                                <small>Time Spent: {task.converted_time ? task.converted_time : '0M'}</small>
                              </p>
                            </div>
                          }
                          bulletSize={25}
                          bullet={
                            <>
                              <FontAwesomeIcon icon={'check'} style={{ color: '#007cff' }} />
                            </>
                          }
                          lineVariant="dashed"
                        >
                          <div className="ms-3">
                            <div className="text-word-break internal-task-body">
                              <Row>
                                {assignee && (
                                  <Col>
                                    <small>
                                      <strong>Assignee</strong>: {assignee.label}
                                    </small>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                {task.start_date && (
                                  <Col>
                                    <small>
                                      <strong>Start Date</strong>: {formatDateWithMoment(task.start_date)}
                                    </small>
                                  </Col>
                                )}
                                {task.due_date && (
                                  <Col>
                                    <small>
                                      <strong>Due Date</strong>: {formatDateWithMoment(task.due_date)}
                                    </small>
                                  </Col>
                                )}
                              </Row>
                              <small>
                                <div
                                  className="font-weight-light mt-3"
                                  dangerouslySetInnerHTML={{
                                    __html: chunkString(
                                      DOMPurify.sanitize(task.description, {
                                        ALLOWED_TAGS,
                                        ADD_CLASSES: [
                                          'ql-indent-1',
                                          'ql-indent-2',
                                          'ql-indent-3',
                                          'ql-indent-4',
                                          'ql-indent-5',
                                          'ql-indent-6',
                                          'ql-indent-7',
                                          'ql-indent-8'
                                        ],
                                        ADD_ATTR: ['class']
                                      })
                                    )
                                  }}
                                ></div>
                              </small>
                            </div>
                          </div>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                  {response?.results?.length === 0 ? (
                    <div className="text-muted text-center mb-3">No tasks found</div>
                  ) : null}
                </div>
              </Card.Body>
            )}
          </div>
        </ToggleCard>
      </div>
    </>
  );
};

JiraTaskSection.propTypes = {
  className: PropTypes.any,
  brand: PropTypes.any
};
