import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import FalconCloseButton from '../FalconCloseButton';

export const SearchInput = ({ term, handleSearch, classNames = '' }) => {
  const [value, setValue] = useState(term || '');
  const [isLoading, setIsLoading] = useState(false);

  const debounceHandleSearch = useCallback(
    debounce(async (term) => {
      setIsLoading(false);
      handleSearch(term);
    }, 700),
    [handleSearch]
  );

  useEffect(() => {
    setValue(term ? term : '');
  }, [term]);

  return (
    <Dropdown className={`search-box ${classNames ? classNames : ''}`}>
      <Dropdown.Toggle as="div" data-toggle="dropdown" bsPrefix="toggle">
        <Form.Control
          type="search"
          placeholder="Search..."
          aria-label="Search"
          className="position-relative rounded-pill search-input"
          style={{ height: '30px' }}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setIsLoading(true);
            debounceHandleSearch(e.target.value);
          }}
        />
        {isLoading ? (
          <FontAwesomeIcon icon="spinner" spin={true} className="position-absolute text-400 search-box-icon-loading" />
        ) : (
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
        )}
        {value && (
          <div className="search-box-close-btn-container" style={{ right: '10px', top: '5px' }}>
            <FalconCloseButton
              size="sm"
              noOutline
              onClick={() => {
                setValue('');
                handleSearch('');
                debounceHandleSearch('');
              }}
            />
          </div>
        )}
      </Dropdown.Toggle>
    </Dropdown>
  );
};
SearchInput.propTypes = {
  handleSearch: PropTypes.func,
  term: PropTypes.string,
  classNames: PropTypes.string
};
