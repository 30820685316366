import React from 'react';
import envelope from '../../assets/img/icons/spot-illustrations/mail-box.png';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStaticTitle } from '../../hooks/usePageTitle';

const ConfirmMail = () => {
  useStaticTitle('Email Sent');

  return (
    <div className="text-center">
      <>
        <img className="d-block mx-auto mb-4" src={envelope} alt="sent" width={100} />
        <p>
          We've emailed you instructions for setting your password, if an account exists with the email you entered you
          should receive it shortly. If you don't receive an email, please make sure you've entered the address you
          registered with and check your spam folder.
        </p>
        <Button as={Link} color="primary" className="mt-3 text-capitalize" to={'/login'}>
          <FontAwesomeIcon icon="chevron-left" className="me-1" />
          Return to login
        </Button>
      </>
    </div>
  );
};

export default ConfirmMail;
