import React, { lazy, useContext, useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import Error403 from '../components/errors/Error403';
import Error500 from 'components/errors/Error500';
import AppContext from 'context/Context';

import AuthSimpleLayout from './AuthSimpleLayout';
import ErrorLayout from './ErrorLayout';

import AuthenticatedLayout from 'components/AuthenticatedLayout';

import RequireAdminType from '../components/RequireAdminType';
import MainLayout from './MainLayout';

import Login from '../pages/authentication/Login';
import SignUp from '../pages/authentication/SignUp';
import ForgetPassword from '../pages/authentication/ForgetPassword';
import ConfirmMail from '../pages/authentication/ConfirmMail';
import PasswordReset from '../pages/authentication/PasswordReset';
import Permission from '../components/Permission';

// TODO in the future we may think in move that to lazy load, but for now it must be in main bundler
import OrderCreateUpdate from '../pages/order/OrderCreateUpdate';
import OrderList from '../pages/order/OrderList';
import OrderLogs from '../pages/order/OrderLogs';
import AbandonedCheckoutLogs from '../pages/abandoned-checkout/AbandonedCheckoutLogs';
import GiftCardList from '../pages/gift-card/GiftCardList';
import GiftCardCreateUpdate from '../pages/gift-card/GiftCardCreateUpdate';
import CustomerList from '../pages/customer/CustomerList';
import CustomerView from '../pages/customer/CustomerView';
import KanbanBrand from '../pages/brand/kanban/BrandKanban';
import PortfolioList from '../pages/settings/portfolio/PortfolioList';
import PortfolioCreateUpdate from '../pages/settings/portfolio/PortfolioCreateUpdate';
import ExportList from '../pages/export/ExportList';
import EmailSettingList from '../pages/settings/email-setting/EmailSettingList';
import EmailSettingCreateUpdate from '../pages/settings/email-setting/EmailSettingCreateUpdate';
import { ImportCreate } from '../pages/import/ImportCreate';
import SubscriptionList from '../pages/subscription/SubscriptionList';
import SubscriptionView from '../pages/subscription/SubscriptionView';
import { ImportList } from '../pages/import/ImportList';
import { SentryTest } from '../pages/SentryTest';

// This pages will be under lazy loading, so pages that we expect use always isn't a good ideia put here
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Reports = lazy(() => import('pages/report/Reports'));
const ProductList = lazy(() => import('pages/product/ProductList'));
const ProductCreateUpdate = lazy(() => import('pages/product/ProductCreateUpdate'));

const BuyButtonDashboard = lazy(() => import('pages/buy-button/BuyButtonDashboard'));
const BuyButtonDesign = lazy(() => import('pages/buy-button/BuyButtonDesign'));
const Profile = lazy(() => import('pages/account/Profile'));
const BrandList = lazy(() => import('pages/brand/BrandList'));
const BrandCreateUpdate = lazy(() => import('pages/brand/BrandCreateUpdate'));
const TrackingPageDesignUpdate = lazy(() => import('pages/brand/TrackingPageDesignUpdate'));

const AccountList = lazy(() => import('pages/account/AccountList'));
const AccountCreateUpdate = lazy(() => import('pages/account/AccountCreateUpdate'));

const DiscountList = lazy(() => import('pages/discount/DiscountList'));
const DiscountCreateUpdate = lazy(() => import('pages/discount/DiscountCreateUpdate'));

const RetailerList = lazy(() => import('pages/settings/retailer/RetailerList'));
const RetailerCreateUpdate = lazy(() => import('pages/settings/retailer/RetailerCreateUpdate'));

const StageList = lazy(() => import('pages/settings/stage/StageList'));
const StageCreateUpdate = lazy(() => import('pages/settings/stage/StageCreateUpdate'));

const ShippingTypeList = lazy(() => import('pages/settings/shipping-type/ShippingTypeList'));
const ShippingTypeCreateUpdate = lazy(() => import('pages/settings/shipping-type/ShippingTypeCreateUpdate'));

const WebhookList = lazy(() => import('pages/settings/webhook/WebhookList'));
const WebhookCreateUpdate = lazy(() => import('pages/settings/webhook/WebhookCreateUpdate'));

const CategoryList = lazy(() => import('pages/settings/category/CategoryList'));
const CategoryCreateUpdate = lazy(() => import('pages/settings/category/CategoryCreateUpdate'));

const SubcategoryCreateUpdate = lazy(() => import('pages/settings/subcategory/SubcategoryCreateUpdate'));
const SubcategoryList = lazy(() => import('pages/settings/subcategory/SubcategoryList'));

const EmailTemplateCreateUpdate = lazy(() => import('pages/brand/EmailTemplateCreateUpdate'));

const AbandonedCheckoutList = lazy(() => import('pages/abandoned-checkout/AbandonedCheckoutList'));
const AbandonedCheckoutView = lazy(() => import('pages/abandoned-checkout/AbandonedCheckoutView'));

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/*- ------------- Authentication ---------------------------  */}
        <Route element={<AuthSimpleLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/confirm-email" element={<ConfirmMail />} />
          <Route path="/reset-password/:uidb64/:token/" element={<PasswordReset />} />
        </Route>

        <Route element={<AuthenticatedLayout />}>
          {/* TODO merge MainLayout with AuthenticatedLayout */}
          <Route element={<MainLayout />}>
            <Route path="/sentry-test" element={<SentryTest />} />

            <Route element={<Permission permission={'dashboard'} />}>
              <Route path="/" element={<Dashboard />} />
            </Route>

            <Route element={<Permission permission={'product'} />}>
              <Route path="/product/:id" element={<ProductCreateUpdate />} exact={true} />
              <Route path="/product" element={<ProductList />} />
              <Route path="/buy-button" element={<BuyButtonDashboard />} />
              <Route path="/buy-button/:productId" element={<BuyButtonDesign />} />
            </Route>
            <Route element={<Permission permission={'product_edit'} />}>
              <Route path="/product/create" element={<ProductCreateUpdate />} />
            </Route>
            <Route element={<Permission permission={'customer'} />}>
              <Route path="/customer/:id" element={<CustomerView />} exact={true} />
              <Route path="/customer" element={<CustomerList />} />
            </Route>
            <Route element={<Permission permission={'order'} />}>
              <Route path="/order" element={<OrderList />} />
              <Route path="/order/:id" element={<OrderCreateUpdate />} exact={true} />
              <Route path="/order/:id/logs" element={<OrderLogs />} exact={true} />

              <Route path="/abandoned-checkout" element={<AbandonedCheckoutList />} />
              <Route path="/abandoned-checkout/:id" element={<AbandonedCheckoutView />} exact={true} />
              <Route path="/abandoned-checkout/:id/logs" element={<AbandonedCheckoutLogs />} exact={true} />
            </Route>
            <Route element={<Permission permission={'subscription'} />}>
              <Route path="/subscription" element={<SubscriptionList />} />
              <Route path="/subscription/:id" element={<SubscriptionView />} />
            </Route>
            <Route element={<Permission permission={'order_edit'} />}>
              <Route path="/order/create" element={<OrderCreateUpdate />} />
            </Route>
            <Route element={<Permission permission={'discount'} />}>
              <Route path="/discount" element={<DiscountList />} />
              <Route path="/discount/:id" element={<DiscountCreateUpdate />} exact={true} />
            </Route>
            <Route element={<Permission permission={'discount_edit'} />}>
              <Route path="/discount/create" element={<DiscountCreateUpdate />} />
            </Route>
            <Route element={<Permission permission={'gift_card'} />}>
              <Route path="/gift-card" element={<GiftCardList />} />
              <Route path="/gift-card/:id" element={<GiftCardCreateUpdate />} exact={true} />
            </Route>
            <Route element={<Permission permission={'gift_card_edit'} />}>
              <Route path="/gift-card/create" element={<GiftCardCreateUpdate />} />
            </Route>
            <Route element={<Permission permission={'brand'} />}>
              <Route path="/brand/:id" element={<BrandCreateUpdate />} exact={true} />
              <Route path="/brand" element={<BrandList />} />
              <Route path="/brand/:id/tracking-page/" element={<TrackingPageDesignUpdate />} exact={true} />
              <Route path="/brand/:id/email-template/:status/" element={<EmailTemplateCreateUpdate />} exact={true} />
            </Route>
            <Route element={<Permission permission={'report'} />}>
              <Route path="/reports" element={<Reports />} />
            </Route>
            <Route element={<Permission permission={'export'} />}>
              <Route path="/export" element={<ExportList />} />
            </Route>
            <Route element={<Permission permission={'import'} />}>
              <Route path="/import" element={<ImportList />} />
            </Route>
            <Route element={<Permission permission={'brand'} />}>
              <Route path="/settings/webhook/" element={<WebhookList />} />
              <Route path="/settings/webhook/:id" element={<WebhookCreateUpdate />} />
              <Route path="/settings/webhook/create" element={<WebhookCreateUpdate />} />
            </Route>
            <Route element={<RequireAdminType />}>
              <Route element={<Permission permission={'account'} />}>
                <Route path="settings/user" element={<AccountList />} />
                <Route path="settings/user/:id" element={<AccountCreateUpdate />} exact={true} />
              </Route>
              <Route element={<Permission permission={'setting'} />}>
                <Route path="/kanban/brand/:type/" element={<KanbanBrand />} />

                <Route path="/settings/stage" element={<StageList />} />
                <Route path="/settings/stage/create" element={<StageCreateUpdate />} />
                <Route path="/settings/stage/:id" element={<StageCreateUpdate />} />

                <Route path="/settings/retailer" element={<RetailerList />} />
                <Route path="/settings/retailer/:id" element={<RetailerCreateUpdate />} />

                <Route path="/settings/shipping-type/" element={<ShippingTypeList />} />
                <Route path="/settings/shipping-type/:id" element={<ShippingTypeCreateUpdate />} />
                <Route path="/settings/category/" element={<CategoryList />} />
                <Route path="/settings/category/:id" element={<CategoryCreateUpdate />} />
                <Route path="/settings/subcategory/" element={<SubcategoryList />} />
                <Route path="/settings/subcategory/:id" element={<SubcategoryCreateUpdate />} />
                <Route path="/settings/portfolio/" element={<PortfolioList />} />
                <Route path="/settings/portfolio/:id" element={<PortfolioCreateUpdate />} />
                <Route path="/settings/email-setting/" element={<EmailSettingList />} />
                <Route path="/settings/email-setting/:id" element={<EmailSettingCreateUpdate />} />
              </Route>
              <Route element={<Permission permission={'setting_edit'} />}>
                <Route path="/settings/shipping-type/create/" element={<ShippingTypeCreateUpdate />} />
                <Route path="/settings/retailer/create/" element={<RetailerCreateUpdate />} />
                <Route path="/settings/category/create/" element={<CategoryCreateUpdate />} />
                <Route path="/settings/subcategory/create/" element={<SubcategoryCreateUpdate />} />
                <Route path="/settings/portfolio/create/" element={<PortfolioCreateUpdate />} />
                <Route path="/settings/email-setting/create/" element={<EmailSettingCreateUpdate />} />
              </Route>
              <Route element={<Permission permission={'brand_edit'} />}>
                <Route path="/brand/create" element={<BrandCreateUpdate />} />
              </Route>
              <Route element={<Permission permission={'account_edit'} />}>
                <Route path="settings/user/create/" element={<AccountCreateUpdate />} exact={true} />
              </Route>
            </Route>
            <Route element={<Permission permission={'import_edit'} />}>
              <Route path="/import/create/" element={<ImportCreate />} exact={true} />
            </Route>
            <Route path="/profile/" element={<Profile />} />
          </Route>
          <Route element={<ErrorLayout />}>
            <Route path="errors/404" element={<Error404 />} />
            <Route path="errors/403" element={<Error403 />} />
            <Route path="errors/500" element={<Error500 />} />
          </Route>
          <Route element={<ErrorLayout />}>
            <Route path="*" element={<Error404 />} />
          </Route>
        </Route>
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Layout;
