import React, { Suspense, useContext, useEffect } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoadingView } from './LoadingView';
import { useAxios } from '../hooks/useAxios';
import LoadingScreen from '../pages/LoadingScreen';
import AppContext from '../context/Context';
import { setParameter } from '../utils/ga';

const AuthenticatedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { profile, setProfile } = useContext(AppContext);
  const { axios, abortRequest } = useAxios();

  const loadUserInfo = async () => {
    try {
      const { data } = await axios.get(`profile/`);
      setProfile(data);
      setParameter(data?.id, data?.type, data?.email);
    } catch (e) {
      const { response } = e;
      if (response?.status === 401) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    loadUserInfo().catch(console.error);
    return abortRequest;
  }, [location.pathname]);

  return (
    <>
      <Suspense fallback={<LoadingScreen />}>{profile ? <Outlet /> : <LoadingView />}</Suspense>
    </>
  );
};

export default AuthenticatedLayout;
