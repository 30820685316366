/*eslint camelcase: ["error", {allow: ["phone_number","position_title"]}]*/
import { Button, Card, Dropdown, Modal, Table } from 'react-bootstrap';
import ToggleCard from '../../../components/form/ToggleCard';
import { Form, Formik } from 'formik';
import TextField from '../../../components/form/TextField';
import PhoneField from '../../../components/form/PhoneField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAxios } from '../../../hooks/useAxios';
import { CanceledError } from 'axios';
import FalconCloseButton from '../../../components/common/FalconCloseButton';
import { toast } from 'react-toastify';
import { LoadingView } from '../../../components/LoadingView';
import SweetAlert from 'sweetalert2';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import AppContext from '../../../context/Context';
import { USER_PERMISSION_BRAND_EDIT } from '../../../services/constants';
import { USER_TYPE_ADMIN } from '../../../services/utils';

export const ContactSection = ({ brand, className = 'mb-3' }) => {
  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [opened, setOpened] = useState(false);
  const [response, setResponse] = useState([]);
  const { profile } = useContext(AppContext);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;

  const isReadOnly = brand?.id && !profile?.permissions?.includes(USER_PERMISSION_BRAND_EDIT);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/brand/${brand.id}/contacts/?page_size=1000`);
      setResponse(data);
      setIsLoading(false);
    } catch (e) {
      if (!(e instanceof CanceledError)) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (opened) {
      loadData().catch(console.error);
    }
    return abortRequest;
  }, [brand, opened]);

  const [selectedContact, setSelectedContact] = useState(null);

  const deleteContact = async (contact) => {
    try {
      const { isConfirmed } = await SweetAlert.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      });
      if (isConfirmed) {
        setIsLoading(true);
        await axios.delete(`/brand/${brand.id}/contacts/${contact?.id}/`);
        await loadData();
        toast.success('Contact was deleted');
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(`We found some problems when deleting contact data`);
    }
  };

  const closeModal = () => {
    setSelectedContact(null);
  };
  if (!brand || !isAdmin) {
    return null;
  }

  return (
    <div>
      {selectedContact ? <div style={{ opacity: 0.5 }} className="modal-backdrop w-100 h-100"></div> : null}
      <Card className={className}>
        <ToggleCard title="Contacts" open={false} onOpen={() => setOpened(true)} onClose={() => setOpened(false)}>
          <div>
            <Modal show={selectedContact} onHide={closeModal}>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={Yup.object({
                  name: Yup.string().required().label('Name').trim().min(1, 'Name cannot be empty')
                })}
                initialValues={
                  selectedContact?.id
                    ? selectedContact
                    : {
                        name: '',
                        email: '',
                        phone_number: '',
                        position_title: ''
                      }
                }
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                  try {
                    const url = values.id
                      ? `/brand/${brand.id}/contacts/${values.id}/`
                      : `/brand/${brand.id}/contacts/`;
                    await axios.post(url, { ...values, brand: brand.id });
                    await loadData();
                    setSelectedContact(null);
                    setSubmitting(false);
                  } catch ({ response }) {
                    setSubmitting(false);
                    if (response?.data?.message) {
                      toast.error(response?.data?.message);
                    }
                    setErrors(response?.data);
                  }
                }}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Modal.Header>
                      <Modal.Title>{selectedContact?.id ? 'Change Contact' : 'Add Contact'}</Modal.Title>
                      <FalconCloseButton onClick={closeModal} />
                    </Modal.Header>

                    <Modal.Body>
                      <TextField
                        label="Name"
                        name="name"
                        disabled={isSubmitting || isReadOnly}
                        required={true}
                        data-lpignore="true"
                        autoComplete="new-password"
                      />
                      <TextField
                        label="Email"
                        type="email"
                        name={`email`}
                        disabled={isSubmitting || isReadOnly}
                        data-lpignore="true"
                        autoComplete="new-password"
                      />
                      <PhoneField
                        label="Phone Number"
                        name="phone_number"
                        disabled={isSubmitting || isReadOnly}
                        data-lpignore="true"
                        autoComplete="new-password"
                      />
                      <TextField
                        label="Position Title"
                        name="position_title"
                        disabled={isSubmitting || isReadOnly}
                        data-lpignore="true"
                        autoComplete="new-password"
                      />
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <Button variant="default" onClick={closeModal} disabled={isSubmitting}>
                        Close
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        className="ms-3"
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>
            {isLoading ? (
              <div className="card-body">
                <LoadingView />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '250px' }}>Name</th>
                        <th style={{ minWidth: '250px' }}>Email</th>
                        <th style={{ minWidth: '250px' }}>Number</th>
                        <th style={{ minWidth: '200px' }}>Position</th>
                        <th style={{ minWidth: '100px' }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {response?.results?.length === 0 ? (
                        <tr className="text-muted text-center mb-3">
                          <td className="text-center" colSpan={5}>
                            No contacts found
                          </td>
                        </tr>
                      ) : null}
                      {response?.results?.map((contact, index) => {
                        return (
                          <tr key={`contact-${index}`}>
                            <td className="text-nowrap">{contact?.name ? contact.name : '-'}</td>
                            <td className="text-nowrap">{contact?.email ? contact.email : '-'}</td>
                            <td className="text-nowrap">{contact?.phone_number ? contact.phone_number : '-'}</td>
                            <td>{contact?.position_title ? contact.position_title : '-'}</td>
                            <td>
                              <div className="d-flex flex-justify-end">
                                <Dropdown className="ms-auto">
                                  <Dropdown.Toggle
                                    bsPrefix="toggle"
                                    as={Link}
                                    to="#"
                                    className="nav-link d-flex align-items-center text-muted"
                                  >
                                    <FontAwesomeIcon icon="ellipsis-v" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu align="end" className="dropdown-caret dropdown-menu-card">
                                    <div className="bg-white rounded-2 dark__bg-1000">
                                      <Dropdown.Item
                                        as={'button'}
                                        type={'button'}
                                        className="d-flex align-items-center py-2"
                                        onClick={() => setSelectedContact(contact)}
                                      >
                                        <FontAwesomeIcon icon="pencil-alt" className="me-2" />
                                        <span style={{ lineHeight: 1 }}>Edit</span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        as={'button'}
                                        type={'button'}
                                        className="d-flex align-items-center py-2"
                                        onClick={() => deleteContact(contact)}
                                      >
                                        <FontAwesomeIcon icon="trash" className="text-danger me-2" />
                                        <span style={{ lineHeight: 1 }}>Delete</span>
                                      </Dropdown.Item>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>

                <div className="card-body">
                  {!isReadOnly && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      disabled={isReadOnly}
                      onClick={() => {
                        setSelectedContact({});
                      }}
                    >
                      Add Contact
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </ToggleCard>
      </Card>
    </div>
  );
};

ContactSection.propTypes = {
  brand: PropTypes.any,
  className: PropTypes.any
};
