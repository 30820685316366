import YesNoEmptyField from '../../components/form/YesNoEmptyField';
import { FilterRow } from '../../components/FilterRow';
import AsyncSelectField, { SimpleSelectField } from '../../components/form/SelectField';
import { BRAND_PRIORITY_OPTIONS, ADMIN_OR_BRAND, USER_TYPE_ADMIN } from '../../services/constants';
import { COUNTRY_LIST } from '../../utils';
import React, { useContext } from 'react';
import AppContext from '../../context/Context';

export const FilterComponent = () => {
  const { profile } = useContext(AppContext);
  const isAdmin = profile?.type === USER_TYPE_ADMIN;

  return (
    <>
      <YesNoEmptyField label="Active" name="is_active" className="mb-0 mt-3" />
      {ADMIN_OR_BRAND.includes(profile?.type) && (
        <FilterRow showRemoveButton={false} name="portfolios">
          <AsyncSelectField
            isMulti={true}
            label="Portfolios"
            name="portfolios"
            urlEndpoint={'/dropdown/portfolio/'}
            className="mb-0"
          />
        </FilterRow>
      )}
      {isAdmin && (
        <>
          <FilterRow showRemoveButton={false} name="stages">
            <AsyncSelectField
              label="Stage"
              name="stages"
              urlEndpoint={'/dropdown/stage/?active=true'}
              className="mb-0"
              isMulti={true}
            />
          </FilterRow>
          <FilterRow showRemoveButton={false} name="providers">
            <AsyncSelectField
              isMulti={true}
              label="Provider"
              name="providers"
              urlEndpoint={'/dropdown/provider/'}
              className="mb-0"
            />
          </FilterRow>
          <FilterRow showRemoveButton={false} name="value">
            <SimpleSelectField
              options={BRAND_PRIORITY_OPTIONS}
              label="Value"
              name="value"
              className="mb-0 me-3 w-100"
              isMulti={true}
            />
          </FilterRow>
          <FilterRow showRemoveButton={false} name="account_manager">
            <AsyncSelectField
              label="Account Manager"
              name="account_manager"
              urlEndpoint="/dropdown/admin-users/"
              className="mb-0"
              isMulti={false}
            />
          </FilterRow>
          <FilterRow showRemoveButton={false} name="go_cardless_active">
            <YesNoEmptyField
              yesLabel="Active"
              noLabel="Inactive"
              label=" GoCardless Integration"
              name="go_cardless_active"
              className="mb-0"
            />
          </FilterRow>
        </>
      )}
      <FilterRow showRemoveButton={false} name="country">
        <SimpleSelectField
          options={COUNTRY_LIST.filter((country) => country.value !== 'US')}
          label="Ship to"
          name="country"
          className="mb-0 me-3 w-100"
          isMulti={true}
        />
      </FilterRow>
      <FilterRow showRemoveButton={false} name="has_order_last_month">
        <YesNoEmptyField
          yesLabel="Orders Recorded"
          noLabel="No Orders"
          label="Orders recorded for previous month"
          name="has_order_last_month"
          className="mb-0"
        />
      </FilterRow>
    </>
  );
};
