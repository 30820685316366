import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import { capitalizeFirstLetter } from '../../utils';

export const TextField = ({
  label,
  type,
  labelComponent,
  required = false,
  helpText = null,
  autoComplete,
  maxLength,
  showLabel = true,
  extraClass = 'mb-2',
  ...props
}) => {
  const [{ value, ...field }, meta] = useField(props);
  const capitalizedLabel = capitalizeFirstLetter(label);
  const fieldId = `id_${field.name}`;

  return (
    <div className={extraClass}>
      <div className="d-flex align-items-center">
        {showLabel && label && (
          <label htmlFor={field.name} className="text-capitalize">
            {capitalizedLabel} {required && <RequiredMarker />}
          </label>
        )}
        {labelComponent && (
          <div className="d-flex align-items-center">
            {labelComponent} {required && <RequiredMarker />}
          </div>
        )}{' '}
      </div>

      {type === 'textarea' ? (
        <div>
          <textarea
            id={fieldId}
            className={`form-control shadow-none  ${field.className && meta.touched && meta.error ? 'is-invalid' : ''}`}
            placeholder={capitalizedLabel + '...'}
            rows={6}
            value={value || ''}
            required={required}
            {...field}
            {...props}
          />
        </div>
      ) : (
        <input
          id={fieldId}
          maxLength={maxLength}
          className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
          required={required}
          placeholder={label + '...'}
          type={type}
          value={value || ''}
          autoComplete={autoComplete ? autoComplete : 'new-password'}
          {...field}
          {...props}
        />
      )}
      <div className="d-flex justify-content-between">
        <FormikError name={field.name} meta={meta} />
        {helpText ? <small className="text-muted">{helpText}</small> : null}
      </div>
    </div>
  );
};

TextField.propTypes = {
  maxLength: PropTypes.number,
  label: PropTypes.string,
  extraClass: PropTypes.string,
  helpText: PropTypes.any,
  type: PropTypes.string,
  labelComponent: PropTypes.any,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  showLabel: PropTypes.bool
};

export default TextField;
