/*eslint camelcase: ["error", {allow: ["is_imported","tax_details","tax_rate","tax_amount","state_rate","state_amount","county_rate","county_amount","city_rate","city_amount","special_rate","special_amount"]}]*/
import React from 'react';
import PropTypes from 'prop-types';
import { moneyFormat } from '../services/utils';

const TaxDetails = ({ order, useStrongTag = true }) => {
  const TaxTag = useStrongTag ? 'strong' : 'span';
  const { tax_amount, tax_rate, tax_details, is_imported } = order;
  return (
    <>
      <div className="d-flex justify-content-between">
        {!is_imported ? (
          <TaxTag>
            Tax <small>({tax_rate || '0'})</small>
          </TaxTag>
        ) : (
          <span>Tax</span>
        )}
        <span id="tax-amount">{moneyFormat(tax_amount)}</span>
      </div>
      {tax_amount ? (
        <>
          {tax_details?.state_rate ? (
            <div className="h6 d-flex justify-content-between ms-3 mt-1 text-secondary">
              <span>State Tax {!is_imported ? <small>({tax_details?.state_rate})</small> : null}</span>
              <span>{tax_details?.state_amount}</span>
            </div>
          ) : null}
          {tax_details?.county_rate ? (
            <div className="h6 d-flex justify-content-between ms-3 text-secondary">
              <span>County Tax {!is_imported ? <small>({tax_details?.county_rate})</small> : null}</span>
              <span>{tax_details?.county_amount}</span>
            </div>
          ) : null}
          {tax_details?.city_rate ? (
            <div className="h6 d-flex justify-content-between ms-3 text-secondary">
              <span>City Tax {!is_imported ? <small>({tax_details?.city_rate})</small> : null}</span>
              <span>{tax_details?.city_amount}</span>
            </div>
          ) : null}
          {tax_details?.special_rate ? (
            <div className="h6 d-flex justify-content-between ms-3 text-secondary">
              <span>Special Tax {!is_imported ? <small>({tax_details?.special_rate})</small> : null}</span>
              <span>{tax_details?.special_amount}</span>
            </div>
          ) : null}
          {tax_details?.other_amount ? (
            <div className="h6 d-flex justify-content-between ms-3 text-secondary">
              <span>Other Tax </span>
              <span>{tax_details?.other_amount}</span>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

TaxDetails.propTypes = {
  order: PropTypes.any,
  useStrongTag: PropTypes.bool
};

export default TaxDetails;
