import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Collapse, Nav } from 'react-bootstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import classNames from 'classnames';
import AppContext from 'context/Context';

const CollapseMenu = ({ route }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const openCollapse = (childrens) => {
    const checkLink = (children) => {
      return (
        children.to === pathname ||
        pathname.startsWith(children.to) ||
        (Object.prototype.hasOwnProperty.call(children, 'children') && children.children.some(checkLink))
      );
    };
    return childrens.some(checkLink);
  };
  const isCurrentRoute = location?.pathname.startsWith(route.to) && !route?.rootRouter;
  const isCurrentModule =
    (location?.pathname.startsWith(route.to) || openCollapse(route.children)) && !route?.rootRouter;

  const [isOpen, setIsOpen] = useState(openCollapse(route.children));
  let isCollapsed = isOpen;
  if (route?.to) {
    isCollapsed = isCurrentModule;
  }

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => {
          if (route?.to) {
            navigate(route.to);
            // setIsOpen(true);
          } else {
            setIsOpen(!isOpen);
          }
          if (showBurgerMenu) {
            setConfig('showBurgerMenu', false);
          }
        }}
        className={classNames('cursor-pointer', {
          'text-500': !route.active,
          active: isCurrentRoute,
          'dropdown-indicator': !route?.to
        })}
        aria-expanded={isCollapsed}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={isCollapsed}>
        <Nav className="flex-column nav" as="ul">
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseMenu.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    to: PropTypes.any,
    rootRouter: PropTypes.any
  }).isRequired
};

export const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const location = useLocation();

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  return routes.map((route) => {
    if (!route.children) {
      return (
        <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            state={{ open: true }}
            className={({ isActive }) => {
              const clazz = ['nav-link'];
              if (isActive) {
                clazz.push('active');
              } else {
                if (location?.pathname.startsWith(route.to) && !route?.rootRouter) {
                  clazz.push('active');
                }
              }
              return clazz.join(' ');
            }}
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    }
    return <CollapseMenu route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes)).isRequired
};
