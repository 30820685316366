import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppContext from '../context/Context';
import PropTypes from 'prop-types';
import { getRedirectTo } from '../helpers/utils';

const Permission = ({ permission }) => {
  const {
    profile: { permissions }
  } = useContext(AppContext);
  const location = useLocation();
  // logic to redirect the user if dashboard permission does not exist
  if (location?.pathname === '/' && !permissions.includes(permission)) {
    const redirectUrl = getRedirectTo(permissions);
    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  } else {
    // users have permission to access pages based on their permissions
    return permissions?.includes(permission) ? (
      <Outlet />
    ) : (
      <Navigate to="/errors/403/" state={{ from: location }} replace />
    );
  }
};

Permission.propTypes = {
  permission: PropTypes.string
};
export default Permission;
