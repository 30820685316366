import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { useTitle } from 'react-use';

// deploy version: 001

const App = () => {
  useTitle('Bottle Nexus');

  return (
    <Router basename={process.env.REACT_APP_PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
