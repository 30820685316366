import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { DATETIME_FORMAT } from '../../utils';
import { formatDate } from '../../services/utils';

const CustomMaskedInput = forwardRef(({ value, ...props }, ref) => {
  return <input {...props} value={formatDate(value)} ref={ref} />;
});

CustomMaskedInput.propTypes = {
  value: PropTypes.any
};

export const DatePickerField = ({
  label,
  required = false,
  dateFormat = 'MM-dd-yyyy hh:mm a',
  showTimeSelect = true,
  inputFormat = DATETIME_FORMAT,
  placeHolder,
  className = 'mb-2',

  ...props
}) => {
  const [{ value, ...field }, meta, { setValue, setTouched }] = useField(props);
  const selected = value ? moment(value, inputFormat).toDate() : null;
  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        {label && (
          <label htmlFor={field.name} className="text-capitalize">
            {label} {required && <RequiredMarker />}
          </label>
        )}
      </div>
      <div>
        <DatePicker
          timeIntervals={30}
          showTimeSelect={showTimeSelect}
          selected={selected}
          onChange={(dateValue) => {
            setTouched(true);
            setValue(dateValue ? moment(dateValue).format(inputFormat) : null);
          }}
          customInput={showTimeSelect ? null : <CustomMaskedInput />}
          dateFormat={dateFormat}
          className={`form-control shadow-none  ${field.className && meta.touched && meta.error && 'is-invalid'}`}
          placeholderText={placeHolder ? placeHolder : label + '...'}
          required={required}
          {...props}
        />
      </div>
      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

DatePickerField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeHolder: PropTypes.string,
  dateFormat: PropTypes.string,
  inputFormat: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string
};

export default DatePickerField;
