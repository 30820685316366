import React, { useState } from 'react';
import FalconCardHeader from '../common/FalconCardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
export const ToggleView = ({ header, children, show }) => {
  return (
    <>
      {header}
      <Collapse in={show}>{children}</Collapse>
    </>
  );
};

ToggleView.propTypes = {
  header: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
  show: PropTypes.any
};

export const ToggleCard = ({
  title,
  children,
  onOpen,
  onClose,
  open = false,
  currentlyOpen = false,
  endElement = null
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
    if (!isOpen && onOpen) {
      onOpen();
    }
    if (isOpen && onClose) {
      onClose();
    }
  };

  return (
    <>
      <ToggleView
        show={isOpen || currentlyOpen}
        header={
          <a href={`#toggle-${new Date().getDate()}`} className="text-decoration-none" onClick={onClick}>
            <FalconCardHeader
              title={title}
              endEl={
                <>
                  {endElement ? endElement : null}
                  <button type="button" className="btn btn-link">
                    <FontAwesomeIcon
                      icon={'chevron-down'}
                      className={isOpen ? 'fa-rotate-180 animate-rotate' : 'animate-rotate'}
                    />
                  </button>
                </>
              }
            />
          </a>
        }
      >
        {children}
      </ToggleView>
    </>
  );
};
ToggleCard.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  currentlyOpen: PropTypes.bool,
  endElement: PropTypes.any
};
export const ToggleSection = ({ title, children, endEl, initialOpen = false }) => {
  const [isOpen, setIsOpen] = useState(initialOpen ? initialOpen : false);

  return (
    <>
      <ToggleView
        show={isOpen}
        header={
          <div
            className={[
              'text-decoration-none',
              'text-dark',
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'bg-light',
              'px-4'
            ].join(' ')}
          >
            <div>{title}</div>
            <span className="d-flex align-items-center">
              {endEl ? endEl : null}
              <button type="button" className="btn btn-link" onClick={() => setIsOpen(!isOpen)}>
                <FontAwesomeIcon
                  icon={'chevron-down'}
                  className={isOpen ? 'fa-rotate-180 animate-rotate' : 'animate-rotate'}
                />
              </button>
            </span>
          </div>
        }
      >
        {children}
      </ToggleView>
    </>
  );
};
ToggleSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  endEl: PropTypes.any,
  initialOpen: PropTypes.any
};

export default ToggleCard;
