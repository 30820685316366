export const version = '3.5.1';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const settings = {
  isRTL: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false // toggle vertical navbar collapse
};

export default { version, navbarBreakPoint, settings };
