import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { FormikError } from './FormikError';
import classNames from 'classnames';

export const YesNoEmptyField = ({
  label,
  emptyLabel = 'Empty',
  yesLabel = 'Active',
  noLabel = 'Inactive',
  disabled = false,
  className = 'mb-2',
  ...props
}) => {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  return (
    <div className={className}>
      <div>
        {label && (
          <label htmlFor={field.name} className="text-capitalize">
            {label}
          </label>
        )}
      </div>
      <div className="btn-group" role="group" aria-label="Basic example">
        <button
          type="button"
          onClick={() => setValue(true)}
          className={classNames('btn btn-outline-secondary btn-sm', {
            active: value === true
          })}
          disabled={disabled}
        >
          {yesLabel}
        </button>
        <button
          onClick={() => setValue(false)}
          type="button"
          className={classNames('btn btn-outline-secondary btn-sm', {
            active: value === false
          })}
          disabled={disabled}
        >
          {noLabel}
        </button>
        <button
          onClick={() => setValue(undefined)}
          type="button"
          className={classNames('btn btn-outline-secondary btn-sm', {
            active: value === undefined
          })}
          disabled={disabled}
        >
          {emptyLabel}
        </button>
      </div>
      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

YesNoEmptyField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  emptyLabel: PropTypes.string,
  disabled: PropTypes.bool
};

export default YesNoEmptyField;
