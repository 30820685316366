import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'formik';
import { RequiredMarker } from './RequiredMarker';
import { FormikError } from './FormikError';
import { QuillInput } from './QuillInput';

export const QuillEditorField = ({ label, disabled, required = false, ...props }) => {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  return (
    <div className="mb-2">
      {label && (
        <label htmlFor={field.name} className="text-capitalize">
          {label} {required && <RequiredMarker />}
        </label>
      )}
      <QuillInput name={field?.name} disabled={disabled} value={value} setValue={setValue} />

      <FormikError name={field.name} meta={meta} />
    </div>
  );
};

QuillEditorField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
};
