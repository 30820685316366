import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  emptyLabel,
  shouldRemoveMargin = false
}) => {
  return (
    <SimpleBarReact style={{ paddingBottom: 15 }}>
      <div className={shouldRemoveMargin ? '' : 'table-responsive'}>
        <Table {...getTableProps(tableProps)}>
          <thead className={headerClassName}>
            <tr>
              {headers.map((column, index) => {
                if (!column?.isVisible) return null;
                return (
                  <th key={index} {...column.getHeaderProps(column.getSortByToggleProps(column.headerProps))}>
                    <div className="pe-5">
                      <span>{column.render('Header')}</span>
                      {column.canSort ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="sort desc" />
                          ) : (
                            <span className="sort asc" />
                          )
                        ) : (
                          <span className="sort" />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps(cell.column.cellProps)}>
                        <div className="pe-3">{cell.render('Cell')}</div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {page.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={headers?.length}>
                  {emptyLabel ? emptyLabel : 'No data found'}
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </div>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  emptyLabel: PropTypes.any,
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  shouldRemoveMargin: PropTypes.bool
};

export default AdvanceTable;
