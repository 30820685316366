/*eslint camelcase: ["error", {allow: ["ids_list","url_endpoint","since_x_hours","is_active",url_endpoint"user_id","first_name","last_name","phone_number","confirm_password","brand_list", "send_test_email"]}]*/
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import TextField from '../../../components/form/TextField';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import { SubmitFooter } from '../../../components/form/SubmitFooter';
import { useAxios } from '../../../hooks/useAxios';
import { useDocumentAction, useDocumentTitle } from '../../../hooks/usePageTitle';
import { LoadingView } from '../../../components/LoadingView';
import { SwitchField } from '../../../components/form/CheckboxField';
import { USER_PERMISSION_SETTING_EDIT } from '../../../services/constants';
import AppContext from '../../../context/Context';
import PositiveNumberField from '../../../components/form/PositiveNumberField';
import AsyncSelectField from '../../../components/form/SelectField';

export const EmailSettingCreateUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { profile } = useContext(AppContext);

  const { axios, abortRequest, isLoading, setIsLoading } = useAxios();
  const [emailSetting, setEmailSetting] = useState('');

  useDocumentTitle([['Email Alert', useDocumentAction()], emailSetting?.created_by]);

  const isReadOnly = id && !profile.permissions.includes(USER_PERMISSION_SETTING_EDIT);

  const loadEmailSetting = async () => {
    const { data } = await axios.get(`/email-setting/${id}/`);
    setEmailSetting(data);
  };
  const validate = Yup.object({
    name: Yup.string().required().label('Name'),
    since_x_hours: Yup.string().required().label('Since X Hours')
  });
  const loadData = async () => {
    setIsLoading(true);
    if (id) {
      await loadEmailSetting();
    }
    setIsLoading(false);
  };
  useEffect(() => {
    loadData().catch(console.error);
    return abortRequest;
  }, []);
  const initialValues = id
    ? { ...emailSetting }
    : {
        ['is_active']: true,
        ['since_x_hours']: 1,
        ['send_test_email']: false
      };
  return (
    <div className="mt-3">
      <Formik
        validateOnMount={true}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const payload = {
            ...values,
            send_test_email: values?.send_test_email
          };
          try {
            const { data } = await axios.post(id ? `email-setting/${id}/` : 'email-setting/', payload, {});
            toast.success(
              <>
                Email Alert <Link to={`/settings/email-setting/${data.id}/`}>{data.name}</Link> was{' '}
                {id ? 'updated' : 'created'} successfully
              </>
            );
            navigate(`/settings/email-setting/`);
          } catch ({ response }) {
            setErrors(response?.data);
            if (response?.data?.message) {
              toast.error(response?.data?.message, {
                theme: 'colored'
              });
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <>
              <Form
                className={classNames('', {
                  'd-none': isLoading
                })}
              >
                <Card className="mb-3">
                  <FalconCardHeader
                    endEl={
                      <div className="d-flex flex-wrap align-items-center">
                        <SwitchField
                          name="is_active"
                          label="Active"
                          disabled={isSubmitting || isReadOnly}
                          className="me-3"
                          required={false}
                        />
                      </div>
                    }
                    title={id ? 'Update Email Alert' : 'Create Email Alert'}
                  />{' '}
                  <Card.Body className="bg-light">
                    <div className="row">
                      <TextField
                        label="Name"
                        required={true}
                        name="name"
                        maxLength={70}
                        type="text"
                        disabled={isSubmitting || isReadOnly}
                      />
                    </div>
                    <div className="row">
                      <PositiveNumberField
                        label="Since X Hours"
                        required={true}
                        name="since_x_hours"
                        maxLength={70}
                        type="text"
                        disabled={isSubmitting || isReadOnly}
                      />
                    </div>
                    <div className={'row'}>
                      <div className="col-6 col-sm-6 col-lg-6 mt-3">
                        <AsyncSelectField
                          label="Portfolios"
                          name="portfolios"
                          required={false}
                          disabled={isSubmitting || isReadOnly}
                          urlEndpoint="/dropdown/portfolio/"
                          isMulti={true}
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 mt-3">
                        <AsyncSelectField
                          label="Brands"
                          name="brands"
                          required={false}
                          disabled={isSubmitting || isReadOnly}
                          urlEndpoint="/dropdown/brand/"
                          isMulti={true}
                        />
                      </div>
                    </div>
                    <div></div>
                  </Card.Body>
                </Card>
                {!isReadOnly && (
                  <SubmitFooter backUrl={'/settings/email-setting/'} isSubmitting={isSubmitting}>
                    <div className="ms-auto me-2 py-2">
                      <SwitchField
                        name="send_test_email"
                        label="Send Test Email"
                        disabled={isSubmitting || isReadOnly}
                        required={false}
                      />
                    </div>
                  </SubmitFooter>
                )}
              </Form>
              {isLoading && (
                <div className="py-3">
                  <LoadingView width={1} />
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default EmailSettingCreateUpdate;
