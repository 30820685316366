import { Button, Card } from 'react-bootstrap';
import LoadingButton from '../common/LoadingButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export const SubmitFooter = ({ backUrl, isSubmitting, disabled = false, onDelete, children }) => {
  const navigate = useNavigate();
  return (
    <Card className="mt-3 mb-3 animate__animated animate__fadeIn animate__delay-1">
      <Card.Body className="d-flex justify-content-between align-items-center flex-wrap">
        {backUrl ? (
          <>
            <div className="d-flex">
              <Button disabled={isSubmitting} variant="default" onClick={() => navigate(backUrl)} id="form-footer-back">
                Back
              </Button>
              {onDelete && (
                <Button
                  disabled={isSubmitting}
                  variant="danger"
                  className="ms-3"
                  onClick={() => onDelete()}
                  id="form-footer-delete"
                >
                  Delete
                </Button>
              )}
            </div>
          </>
        ) : (
          <div></div>
        )}
        {children ? children : null}
        <LoadingButton type="submit" disabled={disabled} isLoading={isSubmitting} className="form-footer-submit">
          Save
        </LoadingButton>
      </Card.Body>
    </Card>
  );
};

SubmitFooter.propTypes = {
  children: PropTypes.any,
  backUrl: PropTypes.string,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func
};
