import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AppContext from '../context/Context';

const RequireAdminType = () => {
  const {
    profile: { type }
  } = useContext(AppContext);

  const location = useLocation();

  return type === 'admin' ? <Outlet /> : <Navigate to="/errors/403/" state={{ from: location }} replace />;
};

export default RequireAdminType;
