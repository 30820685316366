import { Button, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import React from 'react';
import TextField from '../../components/form/TextField';
import { useAxios } from '../../hooks/useAxios';
import PropTypes from 'prop-types';

export const CustomEmailModal = ({ params, show, onHide, onSuccess }) => {
  const { axios } = useAxios();
  return (
    <Modal show={show} onHide={onHide} keyboard={false}>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        initialValues={{ note: '' }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const response = await axios.post('get-customer-email/', { ...values, ...params });
            onSuccess(response?.data);
          } catch ({ response }) {
            setErrors(response.data);
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <Modal.Body>
              <TextField name="note" type="textarea" label="Reason" disabled={isSubmitting} />
              <div>
                <small className="text-warning">
                  To see the email field you have to explain why you need this information
                </small>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="secondary" onClick={onHide} disabled={isSubmitting}>
                Close
              </Button>
              <Button
                className="ms-3"
                type="button"
                color="primary"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Show
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

CustomEmailModal.propTypes = {
  show: PropTypes.any,
  onHide: PropTypes.any,
  onSuccess: PropTypes.any,
  params: PropTypes.object
};
